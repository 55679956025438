import { AxiosResponse } from 'axios'
import { axiosApi, defaultItemPerPage } from './base.api'
import {
  ApiOrganization,
  OrganizationsPagination,
  Organization,
} from '../models/organizations.models'
import { PaginationParams } from '../models/commons.models'
import { PlatformReference } from '../models/platforms.models'

export namespace OrganizationsApi {
  export interface GetListParams extends PaginationParams {
    search?: string
    platform?: PlatformReference
  }
  export const getList = async (params?: GetListParams): Promise<OrganizationsPagination> => {
    const response: AxiosResponse<OrganizationsPagination> = await axiosApi.get(`/organizations`, {
      params: {
        disablePaginate: false,
        itemsPerPage: defaultItemPerPage,
        page: 0,
        ...params,
      },
    })
    return response.data
  }
  export const getCsv = async (params?: GetListParams): Promise<Blob> => {
    const response: AxiosResponse<Blob> = await axiosApi.get(`/organizations/csv`, {
      responseType: 'blob',
      params: {
        disablePaginate: false,
        itemsPerPage: defaultItemPerPage,
        page: 0,
        ...params,
      },
    })
    return response.data
  }

  export const getBySiret = async (
    siret: string,
    platform?: PlatformReference,
  ): Promise<Organization> => {
    const response: AxiosResponse<Organization> = await axiosApi.get(
      `/organizations/check/${siret}${platform ? `?platform=${platform}` : ''}`,
    )
    return response.data
  }
  export const create = async (createdOrganization: ApiOrganization): Promise<Organization> => {
    const response: AxiosResponse<Organization> = await axiosApi.post(
      `/organizations`,
      createdOrganization,
    )
    return response.data
  }
  export const getById = async (organizationId: string): Promise<Organization> => {
    const response: AxiosResponse<Organization> = await axiosApi.get(
      `/organizations/${organizationId}`,
    )
    return response.data
  }
  export const update = async (
    organizationId: string,
    updatedOrganization: Partial<ApiOrganization>,
  ): Promise<Organization> => {
    const response: AxiosResponse<Organization> = await axiosApi.patch(
      `/organizations/${organizationId}`,
      updatedOrganization,
    )
    return response.data
  }
  export const deleteById = async (organizationId: string): Promise<void> => {
    await axiosApi.delete(`/organizations/${organizationId}`)
  }
}
