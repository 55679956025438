import { Button, Grid, IconButton, Box, InputLabel, FormHelperText } from '@mui/material'
import { Delete as DeleteIcon, DragHandle as DragHandleIcon } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import { arrayMoveImmutable } from 'array-move'
import { ArrayProps, FormItem } from '../../../models/props.models'
import Constants from '../../../constants'
import ArrayItem from '../item/Array.item'
import { useMemo, useCallback, memo } from 'react'
import SortableList, { SortableItem, SortableKnob } from 'react-easy-sort'

const MemoRow = memo(
  ({
    value,
    items,
    index,
    errors,
    orderable,
    editRow,
    deleteRow,
  }: {
    value: any
    index: number
    orderable?: boolean
    items: FormItem[]
    errors?: Record<string, any>
    editRow: (index: number, value: any) => void
    deleteRow: (index: number) => void
  }) => {
    const bindedEditRow = useMemo(() => editRow.bind(null, index), [editRow, index])
    const binededDeleteRow = useMemo(() => deleteRow.bind(null, index), [deleteRow, index])

    return (
      <SortableItem>
        <Box
          display="flex"
          alignItems="center"
          sx={{
            border: `1px solid ${Constants.colors.menuBorder}`,
            padding: '10px',
            mb: '5px',
          }}>
          {orderable && (
            <SortableKnob>
              <IconButton sx={{ margin: '26px  0 0 5px' }}>
                <DragHandleIcon color="primary" />
              </IconButton>
            </SortableKnob>
          )}
          <Grid container spacing={2}>
            <ArrayItem value={value} items={items} errors={errors} setValue={bindedEditRow} />
          </Grid>

          <IconButton onClick={binededDeleteRow} sx={{ margin: '26px  0 0 5px' }}>
            <DeleteIcon color="primary" />
          </IconButton>
        </Box>
      </SortableItem>
    )
  },
)
const ArrayInput: React.FC<ArrayProps> = ({
  label,
  orderable,
  addLabel,
  required,
  error,
  onChange,
  value,
  items,
}) => {
  const { t } = useTranslation()
  const editRow = useCallback(
    (editedIndex: number, setRow: any) =>
      onChange?.(
        (value || []).map((row: any, index: number) => (index === editedIndex ? setRow(row) : row)),
      ),
    [onChange, value],
  )
  const addRow = useCallback(() => onChange?.([...(value || []), {}]), [onChange, value])
  const deleteRow = useCallback(
    (deletedIndex: number) =>
      onChange?.((value || []).filter((_, index: number) => deletedIndex !== index)),
    [onChange, value],
  )

  const hasError = Object.keys(error || {}).reduce((acc, key) => acc || !!error?.[key], false)

  const sortArray = useCallback(
    (oldIndex: number, newIndex: number) => {
      onChange?.(arrayMoveImmutable(value || [], oldIndex, newIndex))
    },
    [onChange, value],
  )

  return (
    <Box width="100%" display="flex" flexDirection="column">
      <Box mb="5px">
        {!!label && <InputLabel error={hasError}>{label + (required ? '*' : '')}</InputLabel>}
      </Box>

      <Box mb="5px" sx={{ userSelect: 'none;' }}>
        <SortableList onSortEnd={sortArray} allowDrag={orderable}>
          {(value || [])?.map((rowVal: any, index: number) => (
            <MemoRow
              key={index}
              index={index}
              orderable={orderable}
              value={rowVal}
              items={items}
              errors={error?.[index]}
              editRow={editRow}
              deleteRow={deleteRow}
            />
          ))}
        </SortableList>
      </Box>

      <Box mb="20px">
        <Button color="primary" onClick={addRow}>
          + {addLabel || t('global:actions.add')}
        </Button>
      </Box>
      {typeof error?.global === 'string' && <FormHelperText error>{error?.global}</FormHelperText>}
    </Box>
  )
}

export default memo(ArrayInput)
