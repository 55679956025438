import { Box, styled, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import Card from '../common/Card.common'

import {
  Business as BusinessIcon,
  LocationOn as LocationOnIcon,
  Apartment as ApartmentIcon,
  CalendarMonth as CalendarMonthIcon,
} from '@mui/icons-material'

import dayjs from '../../utils/dayjs.utils'
import Constants from '../../constants'
import {
  CatalogLight,
  CatalogStatus,
  getCatalogStatusColor,
  CatalogType,
} from '../../models/catalogs.models'
import { Actions } from '../../models/props.models'
import { getPlatform } from '../../models/platforms.models'
import { useMemo } from 'react'
import { sessionService } from '../../store/session'
import IconCatalog from './Icon.catalog'

const OneLineTypography = styled(Typography)({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  width: '100%',
})

const NameLabel = styled(OneLineTypography)({
  fontSize: '0.875rem',
  fontWeight: 600,
})
const CompanyInfoLabel = styled(OneLineTypography)(({ theme }) => ({
  fontSize: '0.75rem',
  fontWeight: 400,
  color: theme.palette.text.secondary,
}))
const OtherInfoLabel = styled(OneLineTypography)(({ theme }) => ({
  fontSize: '0.75rem',
  fontWeight: 400,
  color: theme.palette.text.secondary,
}))

interface CatalogCardProps {
  value: CatalogLight
  onClick?: () => void
  actions?: Actions
  onSelect?: () => void
  selected?: boolean
  isPublic?: boolean
  isAdmin?: boolean
}

const CatalogCard = (props: CatalogCardProps): JSX.Element => {
  const { value, onClick, actions, onSelect, selected, isAdmin, isPublic } = props

  const isNew =
    value.acceptedAt &&
    dayjs().diff(value.acceptedAt, 'day') < getPlatform(Constants.platform).isNewCatalogDelay

  const { t } = useTranslation()

  const showStatus = useMemo(() => {
    return sessionService.showStatus({ type: value.type, isPublic: !!isPublic })
  }, [value.type, isPublic])

  return (
    <Card
      ariaLabel="catalog-card"
      onClick={onClick}
      actions={actions}
      onSelect={onSelect}
      selected={selected}
      image={value.mainImageFile?.src || value.mainImageFile?.path}
      imageChildren={
        value.catalogIcon ? <IconCatalog catalogIcon={value.catalogIcon} /> : undefined
      }
      chips={
        isPublic && isNew
          ? [
              {
                label: t('global:actions.isNew'),
                classic: true,
                color: 'secondary',
              },
            ]
          : showStatus &&
            [CatalogStatus.draft, CatalogStatus.pending, CatalogStatus.accepted].includes(
              value.status,
            )
          ? [
              {
                label: t(`catalogs:status.${value.status}`),
                color: getCatalogStatusColor(value.status),
              },
            ]
          : undefined
      }>
      <>
        <NameLabel>{value.name}</NameLabel>
        {isAdmin && (
          <Box display="flex" alignItems="center" mt="6px">
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              height="15px"
              width="15px"
              mr="4px">
              <BusinessIcon
                style={{ paddingRight: '5px', color: Constants.colors.secondaryText }}
              />
            </Box>
            <CompanyInfoLabel>{value.organization.name}</CompanyInfoLabel>
          </Box>
        )}
        <Box display="flex" alignItems="center" mt="4px">
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            height="15px"
            width="15px"
            mr="4px">
            <LocationOnIcon
              style={{ paddingRight: '5px', color: Constants.colors.secondaryText }}
            />
          </Box>
          <OtherInfoLabel>
            {`${isPublic ? value.retrieval.location.city : value.location.city}, ${
              isPublic ? value.retrieval.location.postalCode : value.location.postalCode
            }`}
          </OtherInfoLabel>
        </Box>
        <Box display="flex" alignItems="center" mt="4px">
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            height="15px"
            width="15px"
            mr="4px">
            <ApartmentIcon style={{ paddingRight: '5px', color: Constants.colors.secondaryText }} />
          </Box>
          <OtherInfoLabel>
            {value.type === CatalogType.needs
              ? t(`catalogs:type.${CatalogType.needs}`)
              : value.type === CatalogType.storage
              ? t(`catalogs:type.${CatalogType.storage}`)
              : value.interventionTypes?.length
              ? value.interventionTypes
                  .map((interventionType) => t(`catalogs:interventionType.${interventionType}`))
                  .join(', ')
              : t(`catalogs:type.${value.type}`)}
          </OtherInfoLabel>
        </Box>
        <Box display="flex" alignItems="center" mt="4px">
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            height="15px"
            width="15px"
            mr="4px">
            <CalendarMonthIcon
              style={{ paddingRight: '5px', color: Constants.colors.secondaryText }}
            />
          </Box>
          <OtherInfoLabel>
            {t('global:format.fromTo', {
              from: new Date(value.retrieval.startDate || 'invalid'),
              to: new Date(value.retrieval.endDate || 'invalid'),
            })}
          </OtherInfoLabel>
        </Box>
      </>
    </Card>
  )
}

export default CatalogCard
