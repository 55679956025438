import { useEffect, useCallback, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { styled, Typography } from '@mui/material'
import { useOutletContext } from 'react-router-dom'
import { Capacitor } from '@capacitor/core'
import { Edit as EditIcon, Remove as RemoveIcon } from '@mui/icons-material'

import { FileUtils } from '../../../utils/files.utils'
import { catalogsService } from '../../../store/catalogs'
import useRoute from '../../../hooks/useRoute.hooks'
import useModal from '../../../hooks/useModal.hooks'
import useSnackbar from '../../../hooks/useSnackbar.hooks'

import { Route } from '../../../models/commons.models'
import { Catalog, Plan, ManagePlan } from '../../../models/catalogs.models'
import { Filter, ItemType, Actions, FilterItem } from '../../../models/props.models'

import List from '../../../components/common/List.common'
import Card from '../../../components/common/Card.common'
import ModalPlan from '../../../components/catalog/ModalPlan.catalog'
import Modal from '../../../components/layout/Modal.layout'

const NameLabel = styled(Typography)({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  width: '100%',
  fontSize: '0.875rem',
  fontWeight: 600,
})

const CatalogPlansTab = () => {
  const { catalog, canUpdate } = useOutletContext<{
    catalog: Catalog
    canUpdate?: boolean
  }>()
  const [modal, setModal] = useModal<string>()
  const [defaultValue, setDefaultValue] = useState<ManagePlan | Plan | undefined>(undefined)
  const inputRef = useRef<HTMLInputElement>(null)

  const { goTo } = useRoute()
  const { t } = useTranslation()
  const show = useSnackbar()

  useEffect(() => {
    if (modal && modal.includes('edit') && !defaultValue) {
      setDefaultValue(catalog.plans.find((plan) => modal.includes(plan._id)) as Plan)
    } else if (!modal && defaultValue) {
      setDefaultValue(undefined)
    }
  }, [catalog.plans, modal, defaultValue])

  const filter = useMemo<Filter>(() => {
    return {
      items: [
        {
          type: ItemType.search,
          key: 'search',
          grid: canUpdate ? { sm: 9, xs: 12 } : { xs: 12 },
          props: {
            placeholder: t('catalogs:pages.plansTabs.searchFilter'),
          },
        },
        ...(!canUpdate
          ? []
          : ([
              {
                type: ItemType.button,
                key: 'add',
                grid: { sm: 3, xs: 12 },
                props: {
                  fullWidth: true,
                  children: t('catalogs:actions.addPlan.label'),
                  onClick: async () => {
                    if (Capacitor.isNativePlatform()) {
                      const picture = await FileUtils.takePicture()
                      if (picture) {
                        setDefaultValue({ file: picture })
                        setModal('add')
                      }
                    } else {
                      inputRef.current?.click()
                    }
                  },
                },
              },
            ] as FilterItem[])),
      ],
    }
  }, [t, setModal, canUpdate])

  const deletePlan = useCallback(async () => {
    return await catalogsService.updateCatalog(catalog._id, {
      plans: catalog.plans.filter((plan) => !modal.includes(plan._id)),
    })
  }, [catalog, modal])

  const actions = useMemo(() => {
    return !canUpdate
      ? undefined
      : (plan: Plan): Actions => [
          {
            onClick: () => {
              setModal?.(`edit.${plan._id}`)
            },
            label: t('global:actions.edit'),
            icon: <EditIcon />,
          },
          {
            onClick: () => {
              setModal?.(`delete.${plan._id}`)
            },
            label: t('global:actions.delete'),
            icon: <RemoveIcon />,
          },
        ]
  }, [t, setModal, canUpdate])

  const getPlans = useCallback(
    async (filters: any) => {
      const filtered = catalog.plans.filter((plan: Plan) => {
        let match = true
        try {
          if (filters.search) {
            const regexp = new RegExp(filters.search, 'gi')
            match = match && regexp.test(plan.name)
          }
        } catch (err: any) {
          show(err)
        }
        return match
      })

      return {
        data: filtered,
        total: filtered.length,
        count: filtered.length,
      }
    },
    [catalog, show],
  )

  return (
    <>
      <input
        ref={inputRef}
        hidden
        onChange={(evt) => {
          const files = Array.from(evt.target.files || new FileList())

          if (files.length) {
            if (files.some((f) => f.size / (1024 * 1024) > 2.5)) {
              show(t('errors:fileSize', { size: 2.5 }), 'warning')
            } else {
              setDefaultValue({ file: files[0] })
              setModal('add')
            }

            evt.target.value = ''
          }
        }}
        accept="image/*"
        type="file"
      />
      <List
        disabledPagination
        getValues={getPlans}
        filter={filter}
        card={({ value: plan }) => (
          <Card
            ariaLabel="plan-card"
            onClick={goTo.bind(null, {
              route: Route.workspaceCatalogPlan,
              catalogId: catalog._id,
              planId: plan._id,
            })}
            actions={actions?.(plan)}
            image={plan.file?.src || plan.file?.path}>
            <NameLabel>{plan.name}</NameLabel>
          </Card>
        )}
      />
      {modal && !modal.includes('delete') && defaultValue && (
        <ModalPlan
          plan={defaultValue as ManagePlan}
          title={t(
            `catalogs:actions.${!(defaultValue as Plan)._id ? 'addPlan' : 'updatePlan'}.label`,
          )}
          submitLabel={t(`global:actions.${!(defaultValue as Plan)._id ? 'add' : 'save'}`)}
          onClose={() => setModal('')}
          onSubmit={async (managePlan: ManagePlan) => {
            if (modal.includes('add')) {
              return await catalogsService.updateCatalog(catalog._id, {
                plans: [managePlan, ...catalog.plans],
              })
            } else {
              return await catalogsService.updateCatalog(catalog._id, {
                plans: catalog.plans.map((plan) => (modal.includes(plan._id) ? managePlan : plan)),
              })
            }
          }}
          onSuccess={() => {
            show(
              t(
                `catalogs:actions.${
                  !(defaultValue as Plan)._id ? 'addPlan' : 'updatePlan'
                }.success`,
              ),
            )
          }}
        />
      )}
      {modal && modal.includes('delete') && (
        <Modal
          title={t(`catalogs:actions.deletePlan.label`)}
          description={t(`catalogs:actions.deletePlan.description`)}
          onClose={() => setModal('')}
          onConfirm={deletePlan}
          onSuccess={() => show(t(`catalogs:actions.deletePlan.success`))}
        />
      )}
    </>
  )
}
export default CatalogPlansTab
