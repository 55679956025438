import { useTranslation } from 'react-i18next'
import { useReducer, useEffect } from 'react'
import { InputAdornment, IconButton, Grid } from '@mui/material'
import { Delete as DeleteIcon } from '@mui/icons-material'

import { Room } from '../../../models/catalogs.models'
import { PlanUtils } from '../../../utils/plan.utils'
import NumberTextFieldComponent from '../../common/input/Number.input'
import TextFieldComponent from '../../common/input/Text.input'

type DebounceReducer = {
  timeout?: any
  room: Room
}
type DebounceReducerAction =
  | { type: 'edit'; update: Partial<Room>; timeout: any }
  | { type: 'init'; room: Room }
  | { type: 'saved' }

type RoomToolbarProps = {
  room: Room
  planScale: number
  canUpdate: boolean
  onDelete: () => void
  onEdit: (room: Room) => void
}
const RoomToolbar: React.FC<RoomToolbarProps> = ({
  room,
  canUpdate,
  planScale,
  onDelete,
  onEdit,
}): JSX.Element => {
  const { t } = useTranslation()
  const [debounce, dispatch] = useReducer(
    (debounceData: DebounceReducer, action: DebounceReducerAction): DebounceReducer => {
      if (debounceData.timeout) {
        clearTimeout(debounceData.timeout)
      }
      switch (action.type) {
        case 'init':
          return {
            room: action.room,
            timeout: undefined,
          }
        case 'saved':
          return {
            room: debounceData.room,
            timeout: undefined,
          }
        case 'edit':
          return {
            room: { ...debounceData.room, ...action.update },
            timeout: action.timeout,
          }
      }
    },
    {
      room,
      timeout: undefined,
    },
  )
  useEffect(() => {
    if (debounce.room._id !== room._id) {
      if (debounce.timeout) {
        onEdit(debounce.room)
      }

      dispatch({
        type: 'init',
        room,
      })
    } else if (debounce.room.name !== room.name && !debounce.timeout) {
      // case where room has been change outsied this component (undo/redo)
      dispatch({
        type: 'init',
        room,
      })
    }
  }, [onEdit, debounce, room])

  const inputHandler = (value: string) => {
    dispatch({
      type: 'edit',
      update: { name: value },
      timeout: setTimeout(() => {
        onEdit({
          ...debounce.room,
          name: value,
        })
        dispatch({ type: 'saved' })
      }, 500),
    })
  }
  const area = Number((PlanUtils.getArea(room.points) * planScale * planScale).toFixed(2))
  return (
    <Grid container spacing={1}>
      <Grid item xs={6}>
        <TextFieldComponent
          fullWidth
          readOnly={!canUpdate}
          label={t('catalogs:attributes.room.name')}
          placeholder={t('catalogs:attributes.room.name')}
          onChange={inputHandler}
          value={debounce.room.name}
        />
      </Grid>
      <Grid item xs={5}>
        <NumberTextFieldComponent
          fullWidth
          label={t('catalogs:attributes.room.area')}
          placeholder={t('catalogs:attributes.room.area')}
          readOnly
          endAdornment={
            <InputAdornment position="end">{t('materials:unitSymbol.m2')}</InputAdornment>
          }
          value={area}
        />
      </Grid>
      {canUpdate && (
        <Grid item xs={1}>
          <IconButton onClick={onDelete} size="small" sx={{ marginTop: '30px' }}>
            <DeleteIcon />
          </IconButton>
        </Grid>
      )}
    </Grid>
  )
}
export default RoomToolbar
