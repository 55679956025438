import { createPalette } from './palette.theme'
import { createAppBar } from './appBar.theme'
import { createButton } from './button.theme'
import { createCard } from './card.theme'
import { createChip } from './chip.theme'
import { createDialog } from './dialog.theme'
import { createDrawer } from './drawer.theme'
import { createInputs } from './inputs.theme'
import { createMenu } from './menu.theme'
import { createTabs } from './tabs.theme'
import { createTooltip } from './tooltip.theme'
import { createTypography } from './typography.theme'

export const createTheme = (colors: any, ui: any, globalCss: any): any => ({
  palette: createPalette(colors),
  typography: {
    fontFamily: 'Montserrat, sans-serif',
  },
  overrides: {
    MuiCssBaseline: {
      '@global': globalCss,
    },
    ...createAppBar(colors, ui),
    ...createButton(colors),
    ...createCard(colors),
    ...createChip(colors),
    ...createDialog(colors),
    ...createDrawer(colors, ui),
    ...createInputs(colors),
    ...createMenu(colors),
    ...createTabs(colors),
    ...createTooltip(colors),
    ...createTypography(colors),
  },
  props: {
    MuiButton: {
      disableElevation: true,
    },
  },
})
