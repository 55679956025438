import React, { useEffect, useRef } from 'react'
import { Capacitor } from '@capacitor/core'
import { Point } from '../models/commons.models'
import Constants from '../constants'

type useDoubleClickParams = {
  ref: React.MutableRefObject<HTMLElement | null>
  doubleClickLatency: number
  capture?: boolean
  onSingleClick?: (position: Point, target: HTMLElement | null) => void
  onDoubleClick?: (position: Point, target: HTMLElement | null) => void
}

const useDoubleClick = ({
  ref,
  doubleClickLatency = Constants.ui.doubleClickLatency,
  capture = false,
  onSingleClick,
  onDoubleClick,
}: useDoubleClickParams): void => {
  let touchPosition = useRef([0, 0])

  useEffect(() => {
    if (!onSingleClick && !onDoubleClick) {
      return
    }
    const clickRef = ref.current
    if (!clickRef) {
      return
    }
    let clickCount = 0

    const touchStart = (event: TouchEvent) => {
      touchPosition.current = [event.touches[0]?.pageX, event.touches[0]?.pageY]
    }

    const handleClick = (event: MouseEvent | TouchEvent) => {
      let position: Point
      if ((event as MouseEvent).clientX) {
        event = event as MouseEvent
        position = [event.clientX, event.clientY]
      } else {
        position = [touchPosition.current[0], touchPosition.current[1]]
      }

      if (!onDoubleClick) {
        if (onSingleClick) {
          onSingleClick(position, event.target as HTMLElement)
        }
        return
      }
      clickCount += 1

      setTimeout(() => {
        if (clickCount === 1 && onSingleClick) onSingleClick(position, event.target as HTMLElement)
        else if (clickCount >= 2 && onDoubleClick)
          onDoubleClick(position, event.target as HTMLElement)

        clickCount = 0
      }, doubleClickLatency)
    }

    if (Capacitor.isNativePlatform()) {
      clickRef.addEventListener('touchend', handleClick, { capture })
      clickRef.addEventListener('touchstart', touchStart, { capture })
    } else {
      clickRef.addEventListener('click', handleClick, { capture })
    }

    return () => {
      if (Capacitor.isNativePlatform()) {
        clickRef.removeEventListener('touchend', handleClick, { capture })
        clickRef.removeEventListener('touchstart', touchStart, { capture })
      } else {
        clickRef.removeEventListener('click', handleClick, { capture })
      }
    }
  }, [onSingleClick, onDoubleClick, doubleClickLatency, capture, ref])
}

export default useDoubleClick
