import { useState, useRef, useEffect, useCallback } from 'react'
import {
  Button,
  Card,
  Box,
  Breadcrumbs,
  debounce,
  Link,
  Typography,
  IconButton,
  List,
  ListItem,
  useMediaQuery,
  ListItemIcon,
  alpha,
  MenuItem,
  Menu,
} from '@mui/material'
import {
  Menu as MenuIcon,
  Edit as EditIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
  ContentCopy as ContentCopyIcon,
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
} from '@mui/icons-material'

import { useTheme } from '@mui/material/styles/index.js'
import { useTranslation } from 'react-i18next'

import Constants from '../../../constants'
import { Route, Point, Mode } from '../../../models/commons.models'
import { Material, MaterialQuantity } from '../../../models/materials.models'
import { ReccursiveCategory } from '../../../models/categories.models'

import { Plan } from '../../../models/catalogs.models'

import SearchComponent from '../../common/input/Search.input'
import Stack from '../../common/Stack.common'
import useGesture from '../../../hooks/useGesture.hooks'
import { RoutePath } from '../../../hooks/useRoute.hooks'
import { StringUtils } from '../../../utils/commons.utils'

type MaterialProps = {
  material: Material
  isSelected: boolean
  canUpdate: boolean
  onEdit: () => void
  onOpen: () => void
  onDuplicate: () => void
  onSelect: () => void
}

const MaterialComponent: React.FC<MaterialProps> = ({
  material,
  isSelected,
  canUpdate,
  onSelect,
  onEdit,
  onOpen,
  onDuplicate,
}): JSX.Element => {
  return (
    <ListItem
      id={`material.${material._id}`}
      onClick={onSelect}
      sx={{
        borderBottom: `1px solid ${Constants.colors.menuBorder}`,
        color: isSelected ? Constants.colors.primary : '',
      }}>
      <img
        id={`material.${material._id}.image`}
        src={material.mainImageFile.src || material.mainImageFile.path}
        draggable={false}
        height="40px"
        width="40px"
        style={{ marginRight: '10px', objectFit: 'cover' }}
        alt=""
      />
      {material.name}

      <Box sx={{ marginLeft: 'auto', display: 'flex' }}>
        {canUpdate ? (
          <>
            <IconButton
              onClick={(e: any) => {
                onEdit()
                e.stopPropagation()
              }}
              size="small">
              <EditIcon />
            </IconButton>
            <IconButton
              onClick={(e: any) => {
                onDuplicate()
                e.stopPropagation()
              }}
              size="small">
              <ContentCopyIcon />
            </IconButton>
          </>
        ) : (
          <IconButton
            onClick={(e: any) => {
              onOpen()
              e.stopPropagation()
            }}
            size="small">
            <EditIcon />
          </IconButton>
        )}
      </Box>
    </ListItem>
  )
}

type CategoryProps = {
  level: number
  reccursiveCategory: ReccursiveCategory
  hidden: boolean
}

type PlanAsideProps = {
  plans: Plan[]
  canUpdate: boolean
  catalogId: string
  planId: string
  planName: string
  catalogName: string
  currentMaterial?: Material
  materials: Material[]
  categories: ReccursiveCategory[]
  hiddenCategories: string[]
  toogleOpenAside: boolean
  goTo: (route: RoutePath) => void
  onSave: () => void
  select: (materialId?: string) => void
  onEdit: (material: Material) => void
  onOpen: (material: Material) => void
  onDuplicate: (material: Material) => void
  onDrag: (position: Point, material: Material) => void
  onDragEnd: () => void
  onDragCancel: () => void
  onToogle: (categoryId: string) => void
}

const PlanAside: React.FC<PlanAsideProps> = ({
  plans,
  catalogId,
  goTo,
  catalogName,
  planId,
  planName,
  currentMaterial,
  materials,
  categories,
  hiddenCategories,
  toogleOpenAside,
  canUpdate,
  select,
  onEdit,
  onOpen,
  onDuplicate,
  onDrag,
  onDragEnd,
  onDragCancel,
  onToogle,
  onSave,
}) => {
  const ref = useRef<HTMLDivElement>(null)
  const { t } = useTranslation()
  const [draggingId, setDraggingId] = useState<string>('')
  const [open, setOpen] = useState<Record<number, string>>({
    0: '',
    1: '',
    2: '',
  })
  const [asideOpened, setAsideOpened] = useState(false)
  const [search, setSearch] = useState('')
  const [filter, setFilter] = useState<'all' | 'plan'>('all')
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const theme = useTheme()
  const onlyXs = useMediaQuery(theme.breakpoints.only('xs'))

  useEffect(() => {
    setAsideOpened(true)
  }, [toogleOpenAside])

  useEffect(() => {
    if (currentMaterial) {
      setOpen({
        0: currentMaterial.primaryCategory._id,
        1: currentMaterial.secondaryCategory._id,
        2: currentMaterial.tertiaryCategory._id,
      })

      setTimeout(() => {
        let scrollDiv = ref.current?.parentNode as HTMLElement
        if (scrollDiv) {
          const materialDiv = document.getElementById(`material.${currentMaterial._id}`)
          if (materialDiv) {
            scrollDiv.scrollTo({ top: materialDiv.offsetTop })
          }
        }
      }, 100)
    }
  }, [currentMaterial])

  useGesture({
    ref: ref as unknown as React.MutableRefObject<HTMLElement>,
    doubleClickLatency: 0,
    onDrag: (delta, position, target, isTouch) => {
      let scrollDiv = ref.current?.parentNode as HTMLElement
      if (isTouch && scrollDiv && (ref.current?.clientWidth || 0) > position[0]) {
        if (scrollDiv.scrollHeight > scrollDiv.clientHeight) {
          scrollDiv.scrollBy({ top: -delta[1] })
        }
      } else {
        if (!canUpdate) {
          return
        }
        if (draggingId) {
          const material = materials.find((material) => material._id === draggingId)
          if (material) {
            onDrag(position, material)
          }
        } else {
          const [type, ...splittedId] = (target?.id || '').split(/\./g)
          const id = splittedId.join('.')
          if (type === 'material' && id) {
            const material = materials.find((material) => material._id === id)
            if (material) {
              setDraggingId(id)
              onDrag(position, materials.find((material) => material._id === id) as Material)
              setAsideOpened(false)
            }
          }
        }
      }
    },
    onDragEnd: (position: Point) => {
      if (draggingId) {
        if ((ref.current?.clientWidth || 0) > position[0]) {
          onDragCancel()
        } else {
          onDragEnd()
        }
        setDraggingId('')
      }
    },
    onSingleClick: (_, target) => {
      if (target?.id.includes('unselect')) {
        select(undefined)
      }
    },
  })

  let filteredMaterials =
    filter === 'plan'
      ? materials.filter((material: Material) =>
          material.quantities.find(
            (materialQuantity: MaterialQuantity) => materialQuantity.plan?._id === planId,
          ),
        )
      : materials
  filteredMaterials = search
    ? materials.filter(
        (mat: Material) =>
          StringUtils.normalizeIncludes(mat.name || '', search) ||
          StringUtils.normalizeIncludes(mat.reference || '', search),
      )
    : filteredMaterials

  const isHidden = useCallback(
    (categoryId: string) => !!hiddenCategories.find((id) => id === categoryId),
    [hiddenCategories],
  )
  const countMaterials = useCallback(
    (id: string): number =>
      filteredMaterials.filter((material: Material) => {
        return (
          material.primaryCategory?._id === id ||
          material.secondaryCategory?._id === id ||
          material.tertiaryCategory?._id === id
        )
      }).length,
    [filteredMaterials],
  )

  const openCategory = useCallback(
    (level: number, categoryId: string) => {
      select(undefined)
      setOpen((currentsId) => {
        if (currentsId[level] === categoryId) {
          return {
            ...currentsId,
            [level]: '',
            [level + 1]: '',
            [level + 2]: '',
          }
        } else {
          return {
            ...currentsId,
            [level]: categoryId,
            [level + 1]: '',
            [level + 2]: '',
          }
        }
      })
    },
    [select],
  )

  const CategoryComponent: React.FC<CategoryProps> = useCallback(
    ({ reccursiveCategory, level, hidden }) => {
      const categoryName = reccursiveCategory.category.name
      const categoryId = reccursiveCategory.category._id

      let materialCount = countMaterials(categoryId)
      if (materialCount === 0) {
        return <></>
      }

      const categoryMaterials = filteredMaterials.filter(
        (material) => material.tertiaryCategory?._id === categoryId,
      )

      const nextLevel: number | undefined = level === 2 ? undefined : level + 1

      const opened: any =
        open[level] === categoryId
          ? nextLevel
            ? reccursiveCategory.subCategories?.map((secondRecCat: ReccursiveCategory) => (
                <CategoryComponent
                  key={secondRecCat.category._id}
                  reccursiveCategory={secondRecCat}
                  level={nextLevel}
                  hidden={isHidden(secondRecCat.category._id)}
                />
              ))
            : categoryMaterials.map((material: Material) => (
                <MaterialComponent
                  key={material._id}
                  material={material}
                  isSelected={currentMaterial?._id === material._id}
                  canUpdate={canUpdate}
                  onOpen={() =>
                    onOpen(materials.find((mat) => mat._id === material._id) as Material)
                  }
                  onEdit={() =>
                    onEdit(materials.find((mat) => mat._id === material._id) as Material)
                  }
                  onDuplicate={() =>
                    onDuplicate(materials.find((mat) => mat._id === material._id) as Material)
                  }
                  onSelect={() => select(material._id)}
                />
              ))
          : null

      const padding = level === 2 ? '40px' : level === 1 ? '20px' : '0'
      return (
        <>
          <ListItem
            onClick={() => openCategory(level, categoryId)}
            sx={{ paddingLeft: padding, borderBottom: `1px solid ${Constants.colors.menuBorder}` }}>
            {level === 0 && (
              <ListItemIcon>
                <img src={Constants.getCategoryIcon(categoryName)} alt="" />
              </ListItemIcon>
            )}
            {t(`categories:name.${categoryName}` as any)} ({materialCount})
            <IconButton
              sx={{ marginLeft: 'auto' }}
              size="small"
              edge="end"
              onClick={(e: any) => {
                onToogle(categoryId)
                e.stopPropagation()
              }}>
              {hidden ? (
                <VisibilityOffIcon id={`toogle.${categoryId}`} />
              ) : (
                <VisibilityIcon id={`toogle.${categoryId}`} />
              )}
            </IconButton>
          </ListItem>
          {opened}
        </>
      )
    },
    [
      t,
      isHidden,
      openCategory,
      filteredMaterials,
      open,
      currentMaterial,
      countMaterials,
      onToogle,
      select,
      onEdit,
      onOpen,
      canUpdate,
      onDuplicate,
      materials,
    ],
  )

  let info = ''
  if (materials.length === 0) {
    info = t('catalogs:components.asidePlan.noCatalogMaterials')
  } else if (filteredMaterials.length === 0) {
    info = search
      ? t('catalogs:components.asidePlan.noResults')
      : t('catalogs:components.asidePlan.noPlanMaterials')
  }

  const closedAside = (
    <IconButton size="large" onClick={() => setAsideOpened(true)}>
      <MenuIcon color="primary" />
    </IconButton>
  )
  const openAside = (
    <Box id="unselect" minHeight="100vh" display="flex" flexDirection="column" padding="0 10px">
      <Box
        position="sticky"
        top="0"
        sx={{ backgroundColor: Constants.colors.white, zIndex: 1 }}
        paddingTop="25px">
        <Breadcrumbs aria-label="breadcrumb">
          {onlyXs && (
            <IconButton size="large" onClick={() => setAsideOpened(false)}>
              <MenuIcon color="primary" />
            </IconButton>
          )}
          <Link
            underline="hover"
            color="inherit"
            onClick={goTo.bind(null, { route: Route.workspace })}>
            {t('global:routes.workspace')}
          </Link>
          <Link
            underline="hover"
            color="inherit"
            onClick={goTo.bind(null, { route: Route.workspaceCatalog, catalogId })}>
            {catalogName}
          </Link>
          <Box>
            <Box
              display="flex"
              onClick={(evt: any) => {
                if (plans?.length > 1) {
                  setAnchorEl(evt.target)
                }
              }}>
              <Typography color="text.primary">{planName}</Typography>
              {plans?.length > 1 && <KeyboardArrowDownIcon />}
            </Box>

            <Menu
              onClick={(event) => event.stopPropagation()}
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={setAnchorEl.bind(null, null)}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              transformOrigin={{ vertical: 'top', horizontal: 'left' }}>
              {plans.map((plan, i) => (
                <MenuItem
                  key={i}
                  onClick={() => {
                    goTo({
                      route: Route.workspaceCatalogPlan,
                      catalogId: catalogId,
                      planId: plan._id,
                    })
                    setAnchorEl(null)
                  }}>
                  {plan.name}
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Breadcrumbs>

        <Card sx={{ marginY: '10px' }}>
          <Button
            size="large"
            color={filter === 'all' ? 'primary' : 'secondary'}
            variant="text"
            onClick={setFilter.bind(null, 'all')}>
            {t('catalogs:components.asidePlan.all')}
          </Button>
          <Button
            size="large"
            color={filter === 'plan' ? 'primary' : 'secondary'}
            variant="text"
            onClick={setFilter.bind(null, 'plan')}>
            {t('catalogs:components.asidePlan.plan')}
          </Button>
          <SearchComponent
            fullWidth
            onChange={debounce((value: any) => {
              setSearch(value)
            }, 400)}
            placeholder={t(`global:inputs.searchPlaceholder`)}
          />
        </Card>
      </Box>

      <Stack display="flex" flex={1} spacing={6}>
        {!search && (
          <List>
            {categories.map((cat) => (
              <CategoryComponent
                key={cat.category._id}
                reccursiveCategory={cat}
                level={0}
                hidden={isHidden(cat.category._id)}
              />
            ))}
          </List>
        )}
        {info && <Typography align="center">{info}</Typography>}
        {search &&
          filteredMaterials.map((material: Material) => (
            <MaterialComponent
              canUpdate={canUpdate}
              key={material._id}
              material={material}
              isSelected={currentMaterial?._id === material._id}
              onOpen={() => onOpen(materials.find((mat) => mat._id === material._id) as Material)}
              onEdit={() => onEdit(materials.find((mat) => mat._id === material._id) as Material)}
              onDuplicate={() =>
                onDuplicate(materials.find((mat) => mat._id === material._id) as Material)
              }
              onSelect={() => select(material._id)}
            />
          ))}
      </Stack>

      {Constants.mode !== Mode.app && canUpdate && (
        <Box
          position="sticky"
          bottom="0"
          paddingBottom="25px"
          sx={{ backgroundColor: Constants.colors.white, zIndex: 1 }}>
          <Button
            fullWidth
            variant="contained"
            size="small"
            sx={{ marginTop: 'auto' }}
            color="primary"
            onClick={onSave}>
            {t(`global:actions.save`)}
          </Button>
        </Box>
      )}
    </Box>
  )

  const containerStyle: React.CSSProperties =
    onlyXs && !asideOpened
      ? { position: 'fixed', top: '10px', left: '10px' }
      : onlyXs && asideOpened
      ? {
          position: 'fixed',
          height: '100vh',
          width: `100vw`,
          background: alpha(Constants.colors.black, 0.5),
          borderRight: `1px solid ${Constants.colors.menuBorder}`,
        }
      : {
          height: '100vh',
          minWidth: `${Constants.ui.planAsideSize}px`,
          borderRight: `1px solid ${Constants.colors.menuBorder}`,
        }

  return (
    <div
      style={{
        ...containerStyle,
        overflowY: 'auto',
        overflowX: 'hidden',
        zIndex: onlyXs && asideOpened ? 10 : 1,
      }}
      onClick={() => setAsideOpened(false)}>
      <div
        ref={ref}
        style={{
          touchAction: 'none',
          minHeight: '100%',
          background: 'white',
          width: onlyXs && asideOpened ? `${Constants.ui.planAsideSize}px` : '',
        }}
        onClick={(e: any) => e.stopPropagation()}>
        {onlyXs && !asideOpened && closedAside}
        {(!onlyXs || asideOpened) && openAside}
      </div>
    </div>
  )
}
export default PlanAside
