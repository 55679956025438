import { Checkbox, MenuItem, FormHelperText, Box, InputLabel } from '@mui/material'
import { KeyboardArrowDown as KeyboardArrowDownIcon } from '@mui/icons-material'
import { SelectOption, MultiSelectProps } from '../../../models/props.models'
import StyledSelectInput from './StyledSelect.input'

const MultiSelectComponent: React.FC<MultiSelectProps> = (props): JSX.Element => {
  const {
    onChange,
    disabled,
    multiSelectedLabel,
    placeholder,
    readOnly,
    size = 'normal',
    items,
    label,
    required,
    error,
    value = [],
  } = props
  return (
    <Box display="flex" flexDirection="column">
      {!!label && <InputLabel error={!!error}>{label + (required ? '*' : '')}</InputLabel>}
      <StyledSelectInput
        multiple
        color="primary"
        onChange={(evt) => onChange?.(evt.target.value)}
        variant="standard"
        disableUnderline
        displayEmpty
        fullWidth
        required={required}
        disabled={disabled}
        readOnly={readOnly}
        error={!!error}
        MenuProps={{
          anchorOrigin: { vertical: 'top', horizontal: 'center' },
          style: { marginTop: '50px' },
          transformOrigin: { vertical: 'top', horizontal: 'center' },
        }}
        SelectDisplayProps={{
          'aria-label': size === 'large' ? 'select-large' : undefined,
        }}
        IconComponent={KeyboardArrowDownIcon}
        renderValue={(selectedValues: unknown) => {
          const selected = selectedValues as SelectOption[]
          if (!selected.length || !items) return placeholder
          if (selected.length === 1) return items.find((it) => it.value === selected[0])?.label
          if (!!multiSelectedLabel) return multiSelectedLabel(value.length)
          return selected.map((s) => items.find((it) => it.value === s)?.label).join(', ')
        }}
        value={value || []}>
        {items?.map((item) => (
          <MenuItem key={`${item.label} ${item.value}`} value={item.value}>
            <Checkbox size="small" checked={value.includes(item.value)} color="primary" />
            {item.label}
          </MenuItem>
        ))}
      </StyledSelectInput>
      {typeof error === 'string' && <FormHelperText error>{error}</FormHelperText>}
    </Box>
  )
}

export default MultiSelectComponent
