import { createStore, withProps } from '@ngneat/elf'
import { MaterialModel } from '../../models/materialModels.models'
import { withRequestsStatus } from '@ngneat/elf-requests'

export const materialModelsStore = createStore(
  { name: 'materialModels' },
  withProps<{ list: MaterialModel[] }>({
    list: [],
  }),
  withRequestsStatus<'init'>(),
)
