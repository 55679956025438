import { Box } from '@mui/material'
import { CatalogIcon } from '../../models/catalogs.models'
import raedificare from '../../images/catalogIcons/raedificare.png'
import lifeWasteToBuild from '../../images/catalogIcons/lifeWasteToBuild.svg'

const CatalogIconComponent = (props: { catalogIcon: CatalogIcon }) => {
  if (props.catalogIcon === CatalogIcon.raedificare) {
    return (
      <Box
        sx={{
          position: 'absolute',
          top: '13px',
          right: '10px',
          height: '32px',
          width: '120px',
          backgroundColor: 'rgba(255, 255, 255, 0.4)',
          borderRadius: '5px',
        }}>
        <img
          width="100%"
          alt=""
          style={{
            filter: 'contrast(300%)',
          }}
          src={raedificare}
        />
      </Box>
    )
  } else if (props.catalogIcon === CatalogIcon.lifeWasteToBuild) {
    return (
      <Box
        sx={{
          position: 'absolute',
          top: '13px',
          right: '10px',
          height: '42px',
          width: '42px',
        }}>
        <img alt="" src={lifeWasteToBuild} />
      </Box>
    )
  }
  return <></>
}
export default CatalogIconComponent
