import { createStore, withProps } from '@ngneat/elf'
import { Material } from '../../models/materials.models'
import { withRequestsStatus } from '@ngneat/elf-requests'

export const materialsStore = createStore(
  { name: 'materials' },
  withProps<{
    list: Material[]
  }>({
    list: [],
  }),
  withRequestsStatus<'init'>(),
)
