import { alpha, Box, Chip, Button, styled, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { ArrowBack as ArrowBackIcon } from '@mui/icons-material'

import {
  Catalog,
  CatalogStatus,
  getCatalogStatusColor,
  getCatalogTypeColor,
  CatalogType,
  NetworkStatus,
  isResources,
  isNeeds,
} from '../../models/catalogs.models'

import { Actions } from '../../models/props.models'
import { Route } from '../../models/commons.models'
import useRoute from '../../hooks/useRoute.hooks'
import Constants from '../../constants'
import TabsComponent from '../common/Tabs.common'
import Stack from '../common/Stack.common'
import StyledChip from '../common/Chip.common'
import { sessionService } from '../../store/session'
import { useMemo } from 'react'
import IconCatalog from './Icon.catalog'

const Container = styled(Stack)<{ background: string; height: number }>(
  ({ theme, background, height }) => ({
    alignItems: 'center',
    backgroundImage: `
    linear-gradient(to left, ${alpha(theme.palette.black, 0.4)} 0%, ${alpha(
      theme.palette.black,
      0.4,
    )} 100%),
    url(${background})
  `,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    boxShadow: `0 2px 20px 0 ${alpha(theme.palette.black, 0.05)}`,
    height: `${height}px`,
    justifyContent: 'center',
    minHeight: `${height}px`,
    position: 'relative',
    width: '100%',
  }),
)
const GoBackButton = styled(Button)(({ theme }) => ({
  color: theme.palette.white,
  left: '20px',
  position: 'absolute',
  top: '18px',
}))
const CatalogName = styled(Typography)(({ theme }) => ({
  color: theme.palette.white,
  fontSize: '1.563rem',
  fontWeight: 700,
}))
const TabsContainer = styled(Box)(({ theme }) => ({
  backgroundColor: alpha(theme.palette.black, 0.25),
  backdropFilter: 'blur(3px)',
  borderRadius: '0 0 4px 4px',
}))

interface CatalogHeaderProps {
  catalog: Catalog
  isPublic?: boolean
  isAdmin?: boolean
  onAction: (action: 'goBack' | 'csv' | 'xls' | 'pdf') => void
}
const CatalogHeader: React.FC<CatalogHeaderProps> = ({ catalog, isAdmin, isPublic, onAction }) => {
  const { getPath } = useRoute()
  const { t } = useTranslation()

  const showStatus = useMemo(() => {
    return sessionService.showStatus({ type: catalog.type, isPublic: !!isPublic })
  }, [catalog.type, isPublic])

  const actions: Actions = isPublic
    ? []
    : ([
        { label: t('catalogs:actions.downloadCsv'), onClick: onAction.bind(null, 'csv') },
        ...(isResources(catalog.type)
          ? [
              { label: t('catalogs:actions.downloadPdf'), onClick: onAction.bind(null, 'pdf') },
              { label: t('catalogs:actions.downloadXls'), onClick: onAction.bind(null, 'xls') },
            ]
          : []),
      ] as Actions)

  let tabItems = [
    {
      label: t(`global:routes.workspaceCatalogInformations`),
      path: getPath({
        route: isPublic ? Route.publicCatalogInformations : Route.workspaceCatalogInformations,
        catalogId: catalog._id,
      }),
    },
    ...(catalog.type === CatalogType.deconstruction && !isPublic
      ? [
          {
            label: t(`global:routes.workspaceCatalogPlans`),
            path: getPath({ route: Route.workspaceCatalogPlans, catalogId: catalog._id }),
          },
        ]
      : []),
    ...(!isPublic ||
    !catalog.retrieval.endDate ||
    new Date().getTime() < new Date(catalog.retrieval.endDate).getTime()
      ? [
          {
            label: t(`global:routes.workspaceCatalogMaterials`),
            path: getPath({
              route: isPublic ? Route.publicCatalogMaterials : Route.workspaceCatalogMaterials,
              catalogId: catalog._id,
            }),
          },
        ]
      : []),
    ...(isResources(catalog.type) && !isPublic && catalog.networkStatus !== NetworkStatus.local
      ? [
          {
            label: t(`global:routes.workspaceCatalogOrders`),
            path: getPath({ route: Route.workspaceCatalogOrders, catalogId: catalog._id }),
          },
        ]
      : []),
    ...(isNeeds(catalog.type) && !isPublic
      ? [
          {
            label: t(`global:routes.workspaceCatalogMatching`),
            path: getPath({ route: Route.workspaceCatalogMatching, catalogId: catalog._id }),
          },
        ]
      : []),
    ...(isResources(catalog.type) && !isPublic
      ? [
          {
            label: t(`global:routes.workspaceCatalogReporting`),
            path: getPath({
              route:  Route.workspaceCatalogReporting,
              catalogId: catalog._id,
            }),
          },
        ]
      : []),
  ]

  if (isPublic) {
    // place materials before informations for public-catalogs
    tabItems = tabItems.reverse()
  }

  const tabs = (
    <>
      <TabsComponent
        lightMode={!isAdmin}
        actions={(actions as any[])?.length ? actions : undefined}
        items={tabItems}
      />
    </>
  )

  if (isAdmin) {
    return tabs
  }

  return (
    <>
      <Container
        background={catalog.mainImageFile.src || catalog.mainImageFile.path || ''}
        height={Constants.ui.catalogHeaderSize}>
        <GoBackButton
          startIcon={<ArrowBackIcon htmlColor={Constants.colors.white} />}
          onClick={onAction.bind(null, 'goBack')}>
          {t('catalogs:components.header.goBack')}
        </GoBackButton>
        {catalog.catalogIcon ? <IconCatalog catalogIcon={catalog.catalogIcon} /> : undefined}
        <CatalogName sx={{ marginTop: '30px', padding: '0 20px' }}>{catalog.name}</CatalogName>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          spacing="10px"
          mt="4px"
          mb="40px"
          flexWrap="wrap">
          {showStatus &&
            [CatalogStatus.draft, CatalogStatus.pending, CatalogStatus.accepted].includes(
              catalog.status,
            ) && (
              <StyledChip
                color={getCatalogStatusColor(catalog.status)}
                label={t(`catalogs:status.${catalog.status}`)}
                sx={{ marginBottom: '5px' }}
              />
            )}
          <Chip
            color={getCatalogTypeColor(catalog.type)}
            label={t(`catalogs:type.${catalog.type}`)}
            sx={{ marginBottom: '5px' }}
          />
          {catalog.interventionTypes?.map((i) => (
            <Chip
              key={i}
              label={t(`catalogs:interventionType.${i}`)}
              sx={{ marginBottom: '5px' }}
            />
          ))}
          <TabsContainer position="absolute" bottom="0" right="0" left="0" width="100%">
            {tabs}
          </TabsContainer>
        </Stack>
      </Container>
    </>
  )
}
export default CatalogHeader
