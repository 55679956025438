import { useState } from 'react'
import { ArrowRightAlt } from '@mui/icons-material'
import { alpha, Box, IconButton, styled, useMediaQuery } from '@mui/material'
import { useTheme } from '@mui/material/styles/index.js'

import Constants from '../../constants'
import NewsCard from './cardNews.session'
import { News, Route } from '../../models/commons.models'
import { getPlatform } from '../../models/platforms.models'
import useRoute from '../../hooks/useRoute.hooks'

const NewsContainer = styled(Box)({
  alignItems: 'flex-start',
  display: 'flex',
  position: 'relative',
  '& > div:not(:first-of-type)': {
    marginLeft: '30px',
  },
})

const ArrowButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: theme.palette.white,
  border: `2px solid #DFDFDF`,
  height: '50px',
  padding: '18px',
  position: 'absolute',
  top: '162.5px',
  width: '50px',
  '&:hover': {
    background: `
      linear-gradient(90deg, ${alpha(theme.palette.black, 0.05)} 0%, ${alpha(
      theme.palette.black,
      0.05,
    )} 100%),
      ${theme.palette.white}
    `,
  },
  '&[aria-label=arrow-left]': {
    left: 0,
    transform: 'translateX(-50%) translateY(-50%) rotate(180deg)',
  },
  '&[aria-label=arrow-right]': {
    right: 0,
    transform: 'translateX(50%) translateY(-50%)',
  },
  [theme.breakpoints.down('sm')]: {
    '&[aria-label=arrow-left]': {
      left: '8px',
    },
    '&[aria-label=arrow-right]': {
      right: '8px',
    },
  },
}))

const NewsComponent = () => {
  const { goTo } = useRoute()

  const [newsToDisplay, setNewsToDisplay] = useState(0)

  const theme = useTheme()
  const onlyXs = useMediaQuery(theme.breakpoints.only('xs'))
  const onlySM = useMediaQuery(theme.breakpoints.only('sm'))
  const news = getPlatform(Constants.platform).news as News[]

  let visibleNbr = 3
  if (onlyXs) visibleNbr = 1
  else if (onlySM) visibleNbr = 2

  let missingNews = 0
  if (newsToDisplay === news.length - 2 && !onlyXs && !onlySM) {
    missingNews = 1
  } else if (newsToDisplay === news.length - 1 && !onlyXs) {
    missingNews = onlySM ? 1 : 2
  }

  return (
    <NewsContainer>
      {news
        .slice(newsToDisplay, newsToDisplay + visibleNbr)
        .concat(news.slice(0, missingNews))
        .map((n, index) => (
          <Box flex={1 / visibleNbr} key={`new ${index + 1}`}>
            <NewsCard
              news={n}
              onClick={goTo.bind(null, {
                route: Route.news,
                newsId: news.findIndex((_n) => _n === n) + '',
              })}
            />
          </Box>
        ))}
      <ArrowButton
        aria-label="arrow-left"
        onClick={() => setNewsToDisplay((state) => (state === 0 ? news.length - 1 : state - 1))}>
        <ArrowRightAlt htmlColor={Constants.colors.home.arrow} />
      </ArrowButton>
      <ArrowButton
        aria-label="arrow-right"
        onClick={() => setNewsToDisplay((state) => (state === news.length - 1 ? 0 : state + 1))}>
        <ArrowRightAlt htmlColor={Constants.colors.home.arrow} />
      </ArrowButton>
    </NewsContainer>
  )
}

export default NewsComponent
