import { Button, styled } from '@mui/material'
const StyledButton = styled(Button)({
  fontSize: '0.875rem',
  fontWeight: 500,
  textDecoration: 'underline',
})
const LinkButton = (props: any) => {
  return <StyledButton color="primary" variant="text" {...props} />
}

export default LinkButton
