import { AxiosResponse } from 'axios'
import { axiosApi, defaultItemPerPage } from './base.api'
import {
  MaterialModelsPagination,
  MaterialModel,
  ApiMaterialModel,
} from '../models/materialModels.models'
import { PaginationParams } from '../models/commons.models'

import { LangEnum } from '../constants/langs'

export namespace MaterialModelsApi {
  export interface GetListParams extends PaginationParams {
    search?: string
    owned?: boolean
    lang?: LangEnum
  }
  export const getList = async (params?: GetListParams): Promise<MaterialModelsPagination> => {
    const response: AxiosResponse<MaterialModelsPagination> = await axiosApi.get(
      `/material-models`,
      {
        params: {
          disablePaginate: false,
          itemsPerPage: defaultItemPerPage,
          page: 0,
          ...params,
        },
      },
    )
    return response.data
  }
  export const create = async (createdMaterialModel: ApiMaterialModel): Promise<MaterialModel> => {
    const response: AxiosResponse<MaterialModel> = await axiosApi.post(
      `/material-models`,
      createdMaterialModel,
    )
    return response.data
  }
  export const getById = async (materialModelId: string): Promise<MaterialModel> => {
    const response: AxiosResponse<MaterialModel> = await axiosApi.get(
      `/material-models/${materialModelId}`,
    )
    return response.data
  }
  export const update = async (
    materialModelId: string,
    updatedMaterialModel: Partial<ApiMaterialModel>,
  ): Promise<MaterialModel> => {
    const response: AxiosResponse<MaterialModel> = await axiosApi.patch(
      `/material-models/${materialModelId}`,
      updatedMaterialModel,
    )
    return response.data
  }
  export const deleteById = async (materialModelId: string): Promise<void> => {
    await axiosApi.delete(`/material-models/${materialModelId}`)
  }
}
