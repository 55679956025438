import React from 'react'
import ReactDOM from 'react-dom/client'
import init from './utils/init.utils'
import App from './components/App.component'
import LayoutRoutes from './components/layout/Routes.layout'
import TmAdminConfig from './configs/tmAdmin.config'
import ApplicationConfig from './configs/application.config'
import AdminConfig from './configs/admin.config'
import SiteConfig from './configs/site.config'
import MipConfig from './configs/mip.config'
import TmConfig from './configs/tm.config'

import { Route } from 'react-router-dom'
import PageTmWhoAreWe from './pages/TmWhoAreWe.page'
import './utils/gtm.utils'

import '@fontsource/montserrat/300.css'
import '@fontsource/montserrat/400.css'
import '@fontsource/montserrat/500.css'
import '@fontsource/montserrat/700.css'

const isApplication = window.location.host.includes('app') || process.env.REACT_APP_IS_APPLICATION
const isAdmin = window.location.host.includes('admin')
const isTm =
  window.location.host.includes('tm') || window.location.host.includes('plateforme-lifewaste2build')
const isMip = window.location.host.includes('mip') || window.location.host.includes('madeinpast')
let additionals = []
try {
  const config = isApplication
    ? ApplicationConfig
    : isAdmin && isTm
    ? TmAdminConfig
    : isAdmin
    ? AdminConfig
    : isMip
    ? MipConfig
    : isTm
    ? TmConfig
    : SiteConfig

  additionals = (config as any)?.additionals ?? []

  init(config)
} catch (err) {
  console.log(err)
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <React.StrictMode>
    <App>
      <LayoutRoutes additionals={additionals}>
        {isTm && !isAdmin && <Route path="lw2b" element={<PageTmWhoAreWe />} />}
      </LayoutRoutes>
    </App>
  </React.StrictMode>,
)
