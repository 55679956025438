import { forwardRef, FormEvent, useState, useImperativeHandle, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Button, DialogActions, styled, Grid } from '@mui/material'
import { ChevronLeft as ChevronLeftIcon } from '@mui/icons-material'

import { YupUtils } from '../../utils/yup.utils'
import { FormItem } from '../../models/props.models'
import useSnackbar from '../../hooks/useSnackbar.hooks'

import Stack from './Stack.common'
import Loader from './Loader.common'
import ArrayItem from './item/Array.item'
import Stepper from './item/Stepper.item'
import Tabs from './Tabs.common'

const Container = styled(Stack, {
  shouldForwardProp: (prop) => prop !== 'scrollable',
})<{ scrollable?: boolean }>(({ scrollable }) => ({
  maxHeight: scrollable ? `calc(90vh - 150px)` : undefined,
  overflow: scrollable ? 'hidden auto' : undefined,
}))

export type FormRef = { loading: boolean }

export type FormProps = {
  value: Record<string, any>
  setValue: React.Dispatch<React.SetStateAction<any>>
  items?: FormItem[]
  tabs?: { title: string; items: FormItem[] }[]
  steps?: { title: string; items: FormItem[] }[]
  submitLabel?: string
  formatValue?: (value: any) => any
  onSubmit: (value: any) => any | Promise<any>
  onSuccess?: (formValue: any) => void | Promise<void>
  onDelete?: (value?: any) => void | Promise<void>
  onTransfer?: (value?: any) => void | Promise<void>
  onDuplicate?: (value?: any) => void | Promise<void>
  noPadding?: boolean
  scrollable?: boolean
  fullwidth?: boolean
}

const Form = forwardRef<FormRef, FormProps>(
  (
    {
      value,
      steps,
      tabs,
      items,
      submitLabel,
      onSubmit,
      formatValue,
      setValue,
      onSuccess,
      onDelete,
      onTransfer,
      onDuplicate,
      noPadding,
      scrollable,
      fullwidth,
    },
    ref,
  ) => {
    const { t } = useTranslation()
    const show = useSnackbar()
    const [loading, setLoading] = useState(false)
    useImperativeHandle(ref, () => ({
      loading,
    }))

    const [step, setStep] = useState(0)

    const handleOnDelete = async () => {
      setLoading(true)
      try {
        await onDelete?.()
      } catch (err: any) {
        show(err)
      }
      setLoading(false)
    }

    const handleSubmit = async (event: FormEvent<HTMLDivElement>) => {
      event.preventDefault()
      event.stopPropagation()
      if (steps && step < steps.length - 1) {
        setStep((state) => state + 1)
      } else {
        setLoading(true)
        const formatedValue = formatValue ? formatValue(value) : value
        try {
          const result = await onSubmit(formatedValue)
          await onSuccess?.(result)
        } catch (err: any) {
          show(err)
        }
        setLoading(false)
      }
    }

    const errors: Record<string, any> = useMemo(() => {
      const currentItems = items || steps?.[step].items || tabs?.[step].items || []
      return tabs
        ? YupUtils.getError(
            tabs.reduce(
              (acc, tab: { title: string; items: FormItem[] }) => acc.concat(tab.items),
              [] as FormItem[],
            ),
            value,
          )
        : YupUtils.getError(currentItems, value)
    }, [tabs, items, steps, step, value])

    return (
      <Stack
        display="flex"
        flexDirection="column"
        alignItems="center"
        p={noPadding ? '' : '15px'}
        mx="auto"
        component="form"
        onSubmit={handleSubmit}
        maxWidth={fullwidth ? 'none' : '800px'}
        width="100%"
        overflow="hidden"
        flexGrow="1">
        <Container
          scrollable={scrollable}
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          width="100%"
          pb="30px"
          px="5px"
          spacing={4}>
          {items && (
            <Grid container spacing={2}>
              <ArrayItem value={value} errors={errors} items={items} setValue={setValue} />
            </Grid>
          )}
          {steps && (
            <Stepper
              progress={((step + 1) / steps.length) * 100}
              title={steps[step].title}
              error={YupUtils.checkIfErrors(errors)}>
              <ArrayItem
                value={value}
                errors={errors}
                items={steps[step].items}
                setValue={setValue}
              />
            </Stepper>
          )}
          {tabs && (
            <Stack spacing={2} width="100%">
              <Tabs
                items={tabs.map((tab) => ({ label: tab.title }))}
                onChange={(value) => setStep(value)}
                value={step}
                hasError={(index) =>
                  YupUtils.checkIfErrors(YupUtils.getError(tabs[index].items, value))
                }
              />
              <Grid container spacing={2}>
                <ArrayItem
                  value={value}
                  errors={errors}
                  items={tabs[step].items}
                  setValue={setValue}
                />
              </Grid>
            </Stack>
          )}
        </Container>

        <DialogActions>
          {steps && step > 0 && (
            <Stack direction="row" spacing="20px">
              <Button
                variant="contained"
                size="large"
                color="primary"
                disabled={loading}
                fullWidth
                onClick={() => setStep((step) => step - 1)}>
                <ChevronLeftIcon fontSize="medium" />
              </Button>
            </Stack>
          )}
          {onDelete && (
            <Box maxWidth="270px">
              <Button
                variant="contained"
                size="large"
                color="error"
                fullWidth
                disabled={loading}
                onClick={handleOnDelete}>
                {t('global:actions.delete')}
                {loading && (
                  <Box position="absolute" right="5px" top="15px">
                    <Loader />
                  </Box>
                )}
              </Button>
            </Box>
          )}
          {onTransfer && (
            <Box maxWidth="270px">
              <Button
                variant="contained"
                size="large"
                color="primary"
                fullWidth
                disabled={loading}
                onClick={onTransfer}>
                {t('materials:actions.transfer.label')}
                {loading && (
                  <Box position="absolute" right="5px" top="15px">
                    <Loader />
                  </Box>
                )}
              </Button>
            </Box>
          )}
          {onDuplicate && (
            <Box maxWidth='270px'>
              <Button
                variant='contained'
                size='large'
                color='primary'
                fullWidth
                disabled={loading}
                onClick={onDuplicate}
              >
                {t('global:actions.duplicate')}
                {loading && (
                  <Box position='absolute' right='5px' top='15px'>
                    <Loader />
                  </Box>
                )}
              </Button>
            </Box>
          )}
          <Box width={!onDelete ? '270px' : 'auto'} maxWidth='270px'>
            <Button
              variant="contained"
              size="large"
              color="primary"
              fullWidth
              type="submit"
              disabled={YupUtils.checkIfErrors(errors) || loading}>
              {steps && step < steps.length - 1 && t('global:actions.next')}
              {steps && step === steps.length - 1 && (submitLabel || t('global:actions.finish'))}
              {(items || tabs) && (submitLabel || t('global:actions.save'))}
              {loading && (
                <Box position="absolute" right="5px" top="15px">
                  <Loader />
                </Box>
              )}
            </Button>
          </Box>
        </DialogActions>
      </Stack>
    )
  },
)
export default Form
