import { useState, useEffect } from 'react'
import { InputAdornment, Box } from '@mui/material'
import {
  RotateLeft as RotateLeftIcon,
  OpenInFull as OpenInFullIcon,
  QuestionMark as QuestionMarkIcon,
} from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import useView from '../../hooks/useView.hooks'
import Constants from '../../constants'
import { ManagePlan } from '../../models/catalogs.models'
import { Point } from '../../models/commons.models'
import { FormItem, ItemType } from '../../models/props.models'
import { FileDetails } from '../../models/files.models'
import ModalForm, { ModalFormProps } from '../common/ModalForm.common'
import Tooltip from '../common/Tooltip.common'

import Segment from './plan/Segment.plan'
import NumberInput from '../common/input/Number.input'
import { PlanUtils } from '../../utils/plan.utils'

interface ModalFormCatalogProps
  extends Omit<ModalFormProps, 'value' | 'items' | 'steps' | 'setValue'> {
  plan?: ManagePlan
}
const ModalFormCatalog: React.FC<ModalFormCatalogProps> = (props) => {
  const { t } = useTranslation()
  const { plan, ...formProps } = props
  const [value, setValue] = useState<ManagePlan>({ angle: 0, scale: 0.01, ...plan } as ManagePlan)

  const [state, setState] = useState('init')
  const [start, setStart] = useState<Point>()
  const [end, setEnd] = useState<Point>()
  const [length, setLength] = useState(1)
  const updateState = (newState: string) => {
    setStart(undefined)
    setEnd(undefined)
    setState((currentState) => (currentState === newState ? 'idle' : newState))
  }

  const { viewbox, viewCenter, viewAngle, setView, View, cornerSize, wallSize } = useView({
    onDragEnd: () => {
      if (state === 'rotate') {
        setState('recomputeAngle')
      }
    },
    onDrag: (_, position, targetId) => {
      if (targetId === 'configuration.end') {
        setEnd(position)
        return false
      } else if (targetId === 'configuration.start') {
        setStart(position)
        return false
      }
      return true
    },
    onSingleClick: async (position) => {
      if (state !== 'idle') {
        if (!start) {
          setStart(position)
        } else if (!end) {
          setEnd(position)
          //  this is ugly ...
          //  it would be better to set the view on the useEffect that change the value from start & end
          //  but rotating the view realtime is not working
          if (state === 'rotate') {
            setTimeout(() => {
              setState('recomputeAngle')
            }, 100)
          }
        }
      }
    },
  })

  useEffect(() => {
    if (state === 'init' || state === 'recomputeAngle') {
      const angleValue = Number(state === 'recomputeAngle' ? value.angle : plan?.angle || 0)
      if (!Number.isNaN(angleValue) && angleValue !== viewAngle) {
        setView({
          center: PlanUtils.rotatePoint(viewCenter, angleValue - viewAngle),
          angle: angleValue,
        })
      }
      setState(state === 'recomputeAngle' ? 'rotate' : 'idle')
    }
  }, [state, setView, viewCenter, viewAngle, value, plan])

  useEffect(() => {
    if (start && end) {
      if (state === 'rotate') {
        const angle = PlanUtils.getAngle([start, end])
        setValue((val) => ({ ...val, angle: -angle }))
      } else {
        const dist = PlanUtils.getDistance([start, end])
        if (dist > 0 && !Number.isNaN(Number(length))) {
          setValue((val) => ({ ...val, scale: Number(length) / dist }))
        }
      }
    }
  }, [state, start, end, length])
  const toolbarInputHandler = (value: any) => {
    const dist = start && end ? PlanUtils.getDistance([start, end]) : 0
    setLength(value)
    if (dist > 0 && !Number.isNaN(Number(value))) {
      setValue((val) => ({ ...val, scale: Number(value) / dist }))
    }
  }

  const toolbar =
    start && end && state === 'resize' ? (
      <NumberInput
        fullWidth
        label={t('catalogs:components.modalPlan.length')}
        placeholder={t('catalogs:components.modalPlan.length')}
        onChange={toolbarInputHandler}
        endAdornment={
          <InputAdornment position="end">{t('materials:dimensionUnit.m')}</InputAdornment>
        }
        value={length}
      />
    ) : null

  const items: FormItem[] = [
    {
      type: ItemType.group,
      key: 'view',
      grid: { xs: 12, sm: 8 },
      items: [
        {
          type: ItemType.custom,
          key: 'file',
          custom: (
            <View
              image={plan?.file as File | FileDetails}
              viewbox={viewbox}
              viewAngle={viewAngle}
              toolbar={toolbar}>
              {start && end && (
                <Segment
                  id="configuration"
                  line={[start, end]}
                  cornerSize={cornerSize}
                  wallSize={wallSize}
                />
              )}
            </View>
          ),
        },
      ],
    },
    {
      type: ItemType.group,
      key: 'inputs',
      grid: { xs: 12, sm: 4 },
      items: [
        {
          type: ItemType.text,
          key: 'name',
          required: true,
          props: {
            label: t('catalogs:attributes.plans.name'),
            placeholder: t('catalogs:attributes.plans.name'),
          },
        },
        {
          type: ItemType.number,
          key: 'angle',
          required: true,
          props: {
            readOnly: true,
            label: t('catalogs:attributes.plans.angle'),
            placeholder: t('catalogs:attributes.plans.angle'),
            onClick: updateState.bind(null, 'rotate'),
            endAdornment: (
              <InputAdornment position="end">
                <RotateLeftIcon
                  sx={{
                    fill: `${
                      state === 'rotate' || state === 'recomputeAngle'
                        ? Constants.colors.primary
                        : Constants.colors.secondaryText
                    } !important`,
                  }}
                />
                <Box onClick={(e: any) => e.stopPropagation()}>
                  <Tooltip title={t('catalogs:components.modalPlan.angleExplication')}>
                    <QuestionMarkIcon
                      sx={{ fill: `${Constants.colors.secondaryText} ! important` }}
                    />
                  </Tooltip>
                </Box>
              </InputAdornment>
            ),
          },
        },
        {
          type: ItemType.number,
          key: 'scale',
          required: true,
          props: {
            readOnly: true,
            label: t('catalogs:attributes.plans.scale'),
            placeholder: t('catalogs:attributes.plans.scale'),
            onClick: updateState.bind(null, 'resize'),
            endAdornment: (
              <InputAdornment position="end">
                <OpenInFullIcon
                  sx={{
                    fill: `${
                      state === 'resize' ? Constants.colors.primary : Constants.colors.secondaryText
                    } !important`,
                  }}
                />

                <Box onClick={(e: any) => e.stopPropagation()}>
                  <Tooltip title={t('catalogs:components.modalPlan.scaleExplication')}>
                    <QuestionMarkIcon
                      sx={{ fill: `${Constants.colors.secondaryText} ! important` }}
                    />
                  </Tooltip>
                </Box>
              </InputAdornment>
            ),
          },
        },
      ],
    },
  ]

  return <ModalForm {...formProps} value={value} setValue={setValue} items={items} maxWidth="md" />
}
export default ModalFormCatalog
