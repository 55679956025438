import React, { useRef } from 'react'
import {
  Box,
  Button,
  Grid,
  styled,
  Typography,
  IconButton,
  CardActionArea,
  Paper,
  alpha,
  Card,
  InputLabel,
} from '@mui/material'
import { InsertDriveFile as InsertDriveFileIcon } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import useSnackbar from '../../../hooks/useSnackbar.hooks'
import { FileUtils } from '../../../utils/files.utils'
import { DocumentsProps } from '../../../models/props.models'
import { FileDetails } from '../../../models/files.models'
import { ManagePlan, Plan } from '../../../models/catalogs.models'
import Constants from '../../../constants'
import Stack from '../Stack.common'
import { Capacitor } from '@capacitor/core'

const CardContainer = styled(Stack)<{ backgroundimage?: string }>(({ backgroundimage, theme }) => {
  const backgroundColor = backgroundimage
    ? alpha(theme.palette.black, 0.5)
    : alpha(theme.palette.primary.main, 0.1)

  return {
    alignItems: 'center',
    backgroundImage: backgroundimage
      ? `
      linear-gradient(to right, ${backgroundColor} 0%, ${backgroundColor} 100%),
      url(${backgroundimage})
    `
      : `linear-gradient(to right, ${backgroundColor} 0%, ${backgroundColor} 100%)`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    position: 'relative',
    width: '100%',
  }
})
const FileLabel = styled(Typography)(() => ({
  fontSize: '0.625rem',
  fontWeight: 400,
  maxWidth: '60px',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
}))
const FileTitle = styled(Typography)(({ theme }) => ({
  fontSize: '1rem',
  fontWeight: 500,
  width: '100%',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  marginTop: 'auto',
  paddingLeft: '5px',
  backgroundColor: alpha(theme.palette.white, 0.5),
}))
interface DocumentCardProps {
  backgroundImage?: string
  title?: string
  filename?: string
  onClick?: () => void
  actionIcon?: JSX.Element
  onAction?: () => void
}
const DocumentCard = (props: DocumentCardProps) => {
  const { backgroundImage, filename, actionIcon, onClick, onAction, title } = props

  return (
    <Box position="relative">
      <Card
        aria-label="file-card"
        component={onClick ? CardActionArea : Paper}
        onClick={(evt: any) => {
          evt.stopPropagation()
          onClick?.()
        }}>
        <CardContainer backgroundimage={backgroundImage} spacing={1}>
          {!backgroundImage && <InsertDriveFileIcon />}
          {!!filename && !title && <FileLabel>{filename}</FileLabel>}
          {!!title && <FileTitle>{title}</FileTitle>}
        </CardContainer>
        {title}
      </Card>
      {onAction && (
        <Box position="absolute" top="4px" right="4px">
          <IconButton
            color="primary"
            onClick={(evt) => {
              evt.stopPropagation()
              onAction()
            }}
            size="small">
            {actionIcon}
          </IconButton>
        </Box>
      )}
    </Box>
  )
}

const DocumentsContainer = styled(Stack, {
  shouldForwardProp: (prop) => prop !== 'required',
})<{ required: boolean }>(({ required, theme }) => ({
  border: `1px solid ${
    required ? alpha(theme.palette.primary.main, 0.5) : theme.palette.menuBorder
  }`,
  borderRadius: '4px',
  minHeight: '130px',
  padding: '10px',
  width: '100%',
}))

const DocumentsInput: React.FC<DocumentsProps> = (props): JSX.Element => {
  const {
    type,
    multiple,
    hideValues,
    error,
    required,
    disableClick,
    value,
    documentTitle,
    fileTitle,
    label,
    maxFileSize,
    readOnly,
    actionIcon,
    onSelectFiles,
    onDocumentClick,
    onActionDocument,
    onActionFile,
  } = { multiple: true, ...props }
  const documents: FileDetails[] | undefined =
    type === 'plan'
      ? (props.documents as Plan[])?.map((plan: Plan) => plan.file)
      : (props.documents as FileDetails[])
  const accept = type === 'file' ? 'application/pdf' : 'image/*'

  const { t } = useTranslation()
  const show = useSnackbar()

  const inputRef = useRef<HTMLInputElement>(null)
  const inputHandler = async () => {
    if (Capacitor.isNativePlatform() && type !== 'file') {
      const picture = await FileUtils.takePicture()
      if (picture) {
        onSelectFiles?.([picture])
      }
    } else {
      inputRef.current?.click()
    }
  }

  return (
    <Stack width="100%">
      <input
        ref={inputRef}
        multiple={multiple}
        hidden
        onChange={(evt) => {
          const files = Array.from(evt.target.files || new FileList())

          if (files.length) {
            if (maxFileSize && files.some((f) => f.size / (1024 * 1024) > maxFileSize)) {
              show(t('errors:fileSize', { size: maxFileSize }), 'warning')
            }
            onSelectFiles?.(
              files.filter((f) => !maxFileSize || f.size / (1024 * 1024) <= maxFileSize),
            )

            evt.target.value = ''
          }
        }}
        accept={accept}
        type="file"
      />
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        {!!label && (
          <InputLabel error={!!error}>
            {label + (required ? '*' : '')}
            {!!maxFileSize && (
              <span style={{ color: Constants.colors.primary, marginLeft: '3px' }}>
                ({t('global:inputs.maxSize', { size: maxFileSize })})
              </span>
            )}
          </InputLabel>
        )}
        {!readOnly && (
          <Button variant="text" color="primary" onClick={inputHandler}>
            {t('global:actions.add')}
          </Button>
        )}
      </Stack>
      <DocumentsContainer required={!!required}>
        <Box>
          <Grid container spacing={2}>
            {documents?.map((d, documentIndex) => (
              <Grid item key={d._id}>
                <DocumentCard
                  backgroundImage={d.mimeType.includes('image/') ? d.src || d.path : undefined}
                  onClick={
                    disableClick
                      ? undefined
                      : () => {
                          if (onDocumentClick) {
                            onDocumentClick(d._id)
                          } else if (d.path) {
                            FileUtils.openFile(d)
                          }
                        }
                  }
                  actionIcon={actionIcon}
                  onAction={
                    onActionDocument && !readOnly ? () => onActionDocument(d._id) : undefined
                  }
                  filename={d.mimeType.includes('image/') ? undefined : d.path.split(/\\|\//).pop()}
                  title={documentTitle ? documentTitle(documentIndex) : ''}
                />
              </Grid>
            ))}
            {!hideValues &&
              (value || [])?.map((val: File | ManagePlan, index) => {
                const file: File = type === 'plan' ? (val as ManagePlan).file : (val as File)
                return (
                  <Grid item key={index}>
                    <DocumentCard
                      backgroundImage={
                        file.type?.includes('image/') ? URL.createObjectURL(file) : undefined
                      }
                      actionIcon={actionIcon}
                      onAction={onActionFile && !readOnly ? () => onActionFile(index) : undefined}
                      filename={file.type?.includes('image/') ? undefined : file.name}
                      title={fileTitle ? fileTitle(index) : ''}
                    />
                  </Grid>
                )
              })}
          </Grid>
        </Box>
      </DocumentsContainer>
    </Stack>
  )
}

export default React.memo(DocumentsInput)
