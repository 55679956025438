import { Box, InputLabel, FormHelperText } from '@mui/material'
import { TextProps } from '../../../models/props.models'
import StyledInput from './Styled.input'

const TextFieldComponent: React.FC<TextProps> = (props): JSX.Element => {
  const {
    ariaLabel,
    disabled,
    endAdornment,
    error,
    onClick,
    onChange,
    onKeyPress,
    inputRef,
    label,
    minRows,
    multiline,
    pattern,
    placeholder,
    readOnly,
    required,
    rows,
    type,
    value,
    autocomplete,
    maxWidth,
  } = props
  return (
    <Box display="flex" flexDirection="column" width="100%" onClick={onClick} maxWidth={maxWidth}>
      {!!label && <InputLabel error={!!error}>{label + (required ? '*' : '')}</InputLabel>}
      <StyledInput
        aria-label={ariaLabel}
        variant="outlined"
        fullWidth
        multiline={multiline}
        minRows={minRows}
        rows={rows}
        inputRef={inputRef}
        required={required}
        type={type}
        disabled={disabled}
        placeholder={!readOnly ? placeholder : undefined}
        value={value}
        autoComplete={autocomplete}
        onChange={(evt) => {
          let value = evt.target.value

          if (pattern && value && !new RegExp(pattern).test(value.toString())) {
            evt.target.value = value.slice(0, value.length - 1)
            return
          }

          onChange?.(value)
        }}
        onKeyPress={onKeyPress}
        focused={readOnly ? false : undefined}
        InputProps={{ readOnly, endAdornment }}
        inputProps={{ pattern, name: autocomplete }}
        error={!!error}
      />
      {typeof error === 'string' && <FormHelperText error>{error}</FormHelperText>}
    </Box>
  )
}

export default TextFieldComponent
