import sessionsTranslations from './sessions.en.json'
import catalogsTranslations from './catalogs.en.json'
import categoriesTranslations from './categories.en.json'
import organizationsTranslations from './organizations.en.json'
import errorsTranslations from './errors.en.json'
import globalTranslations from './global.en.json'
import materialModelsTranslations from './materialModels.en.json'
import materialsTranslations from './materials.en.json'
import ordersTranslations from './orders.en.json'
import usersTranslations from './users.en.json'
import requestsTranslations from './requests.en.json'
import reportsTranslations from './reports.en.json'

const EN = {
  catalogs: catalogsTranslations,
  categories: categoriesTranslations,
  organizations: organizationsTranslations,
  errors: errorsTranslations,
  global: globalTranslations,
  materialModels: materialModelsTranslations,
  materials: materialsTranslations,
  orders: ordersTranslations,
  users: usersTranslations,
  requests: requestsTranslations,
  sessions: sessionsTranslations,
  reports: reportsTranslations,
}

export default EN
