import { Pagination } from './commons.models'
import { Organization } from './organizations.models'
import { PlatformReference } from './platforms.models'
import Constants from '../constants'
import { LangEnum } from '../constants/langs'

export enum UserStatus {
  pending = 'pending',
  accepted = 'accepted',
  denied = 'denied',
  deleted = 'deleted',
}

export enum UserType {
  admin = 'admin',
  platformAdmin = 'platformAdmin',
  user = 'user',
}

export const getUserTypeColor = (route: string) => {
  switch (route) {
    case UserType.admin:
      return Constants.colors.roles.admin
    case UserType.platformAdmin:
      return Constants.colors.roles.platformAdmin
    case UserType.user:
      return Constants.colors.roles.user
    default:
      return Constants.colors.black
  }
}
export const getUserStatusColor = (status: UserStatus) => {
  switch (status) {
    case UserStatus.pending:
      return Constants.colors.warning
    case UserStatus.accepted:
      return Constants.colors.success
    case UserStatus.denied:
      return Constants.colors.error
    default:
      return Constants.colors.black
  }
}

export enum Profession {
  designer = 'designer',
  projectOwner = 'projectOwner',
  company = 'company',
  diagnostician = 'diagnostician',
  other = 'other',
}

export interface UsersPagination extends Pagination {
  data: User[]
}

export interface User {
  _id: string
  type: UserType
  platformOwned?: PlatformReference
  firstname: string
  lastname: string
  email: string
  phoneNumber?: string
  professions?: Profession[]
  favorites?: string[]
  address?: string
  status: UserStatus
  organization: Organization
  receiveNewsEmails: boolean
  receiveMatchingEmails: boolean
  lang: LangEnum
  createdAt: Date
}

export interface ApiUser extends Omit<User, 'organization'> {
  organization: string
}
