import constants from '../../constants'
import { Mode } from '../../models/commons.models'
import { NetworkUtils } from '../../utils/networks.utils'
import { sessionService } from '../session'
import { ReportsApi } from '../../api/reports.api'
import { Reports } from '../../models/reports.model'
import { FileUtils } from '../../utils/files.utils'

export class ReportsService {
  getXlsx = async (filters: ReportsApi.Filters): Promise<void> => {
    await NetworkUtils.checkConnected()
    const data = await ReportsApi.getXlsx({
      ...filters,
    })
    await FileUtils.downloadFile(`reports.xlsx`, data)
  }
  getReports = async (filters: ReportsApi.Filters): Promise<Reports> => {
    await NetworkUtils.checkConnected()
    return await ReportsApi.getReports({
      ...filters,
      ...(constants.mode === Mode.admin && { platform: sessionService.getAdminPlatform() }),
    })
  }
}

export const reportsService = new ReportsService()
