import { useMemo, forwardRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useTheme } from '@mui/material/styles/index.js'
import { useMediaQuery } from '@mui/material'
import { ColumnItem } from '../../models/props.models'
import List, { ListRef, ListProps } from '../common/List.common'
import MaterialModelCard from './Card.materialModel'

export interface MaterialModelListProps
  extends Omit<ListProps, 'card' | 'columns' | 'getCoordinates'> {}
const MaterialModelList = forwardRef<ListRef, MaterialModelListProps>((props, ref) => {
  const theme = useTheme()
  const onlyXs = useMediaQuery(theme.breakpoints.only('xs'))

  const { t } = useTranslation()
  const columns = useMemo<ColumnItem[]>(
    () => [
      {
        label: t('materialModels:attributes.name'),
        key: 'name',
      },
      {
        label: t('materialModels:attributes.organization'),
        key: 'organization.name',
      },
      {
        label: t('materialModels:attributes.primaryCategory'),
        key: 'primaryCategory.name',
        formatValue: (name: string) => t(`categories:name.${name}` as any),
      },
      {
        label: t('materialModels:attributes.secondaryCategory'),
        key: 'secondaryCategory.name',
        formatValue: (name: string) => t(`categories:name.${name}` as any),
      },
      {
        label: t('materialModels:attributes.tertiaryCategory'),
        key: 'tertiaryCategory.name',
        formatValue: (name: string) => t(`categories:name.${name}` as any),
      },
    ],
    [t],
  )

  return (
    <List
      ref={ref}
      {...props}
      columns={!onlyXs ? columns : undefined}
      card={onlyXs ? MaterialModelCard : undefined}
    />
  )
})
export default MaterialModelList
