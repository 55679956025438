import { createStore, withProps } from '@ngneat/elf'
import { Currency } from '../../models/commons.models'
import { PlatformReference } from '../../models/platforms.models'
import { SessionInterface, CountryParams } from '../../models/sessions.models'
import { withRequestsStatus, initializeAsPending } from '@ngneat/elf-requests'

export const sessionStore = createStore(
  { name: 'session' },
  withProps<
    SessionInterface & {
      countryParams: CountryParams
      filters: Record<string, any>
      loading: boolean
      adminPlatform: PlatformReference
    }
  >({
    adminPlatform: PlatformReference.rae,
    loading: false,
    accessToken: undefined,
    user: undefined,
    countryParams: {
      address: 'France',
      countryCode: 'FR',
      date: 'DD/MM/YY',
      currency: Currency.EUR,
      useImperialUnits: false,
      coordinates: [2.43896484375, 46.5286346952716],
      siret: { format: '^\\d{0,14}$', validation: '^\\d{14}$' },
      phoneNumber: { format: '^\\d{0,10}$', validation: '^\\d{10}$' },
      tax: 0.2,
    },
    filters: {},
  }),
  withRequestsStatus(initializeAsPending('init')),
)
