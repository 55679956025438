import { useRef, useMemo, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { ListRef } from '../../components/common/List.common'

import OrganizationList from '../../components/organization/List.organization'
import ModalFormOrganization from '../../components/organization/ModalForm.organization'
import { Organization } from '../../models/organizations.models'
import { Filter, ItemType } from '../../models/props.models'
import { Route, Mode } from '../../models/commons.models'
import { organizationsService } from '../../store/organizations'

import useRoute from '../../hooks/useRoute.hooks'
import useModal from '../../hooks/useModal.hooks'
import useSnackbar from '../../hooks/useSnackbar.hooks'
import useCheckRoute from '../../hooks/useCheckRoute.hooks'

import { AdminHeaderTitle } from '../../components/layout/AdminHeader.layout'
import LoaderOverlay from '../../components/layout/LoaderOverlay.layout'

const OrganizationListPage = () => {
  useCheckRoute('OrganizationList', [Mode.admin])

  const { t } = useTranslation()
  const listRef = useRef<ListRef | null>(null)
  const { goTo } = useRoute()
  const [loading, setLoading] = useState<boolean>(false)
  const show = useSnackbar()

  const [modal, setModal] = useModal<'add'>()

  const exportCsv = useCallback(async () => {
    setLoading(true)
    try {
      await organizationsService.getCsv(listRef.current?.filter ?? {})
    } catch (err: any) {
      show(err)
    }
    setLoading(false)
  }, [show])

  const filter = useMemo<Filter>(
    () => ({
      items: [
        {
          type: ItemType.search,
          key: 'search',
          grid: { sm: 6, xs: 12 },
          props: {
            placeholder: t('organizations:pages.list.searchFilter'),
          },
        },
        {
          type: ItemType.button,
          key: 'export',
          grid: { sm: 3, xs: 6 },
          props: {
            fullWidth: true,
            onClick: exportCsv,
            children: t('global:actions.export'),
          },
        },
        {
          type: ItemType.button,
          key: 'add',
          grid: { sm: 3, xs: 6 },
          props: {
            fullWidth: true,
            children: t('organizations:actions.add.label'),
            onClick: () => setModal('add'),
          },
        },
      ],
    }),
    [t, setModal, exportCsv],
  )
  return (
    <>
      <AdminHeaderTitle title={t('global:routes.organizations')} />
      {loading && <LoaderOverlay />}
      <OrganizationList
        ref={listRef}
        filter={filter}
        onValueClick={(organization: Organization) =>
          goTo({ route: Route.organization, organizationId: organization._id })
        }
        getValues={organizationsService.getOrganizations}
      />
      {modal === 'add' && (
        <ModalFormOrganization
          title={t('organizations:actions.add.label')}
          onSubmit={organizationsService.createOrganization}
          onClose={() => setModal('')}
          onSuccess={() => {
            show(t('organizations:actions.add.success'))
            listRef.current?.updateValues()
          }}
        />
      )}
    </>
  )
}
export default OrganizationListPage
