import { enableElfProdMode } from '@ngneat/elf'
import Constants, { initOptions } from '../constants'
import { init as initI18n } from './i18n.utils'
import { StatusBar, Style } from '@capacitor/status-bar'
import { Capacitor } from '@capacitor/core'
import { setPlatform } from '../api/base.api'
import { setAppVersion } from '../api/base.api'
import { Mode } from '../models/commons.models'

if (process.env.REACT_APP_ENV === 'production') {
  enableElfProdMode()
}

export default function init(options: initOptions) {
  if (Capacitor.isNativePlatform()) {
    StatusBar.setStyle({ style: Style.Light })
    StatusBar.setBackgroundColor({ color: '#ffffff' })
  }
  Constants.init(options)
  setPlatform(Constants.platform)
  if (Constants.mode === Mode.app && options.appVersion) {
    setAppVersion(options.appVersion)
  }
  initI18n(Constants.langs)
}
