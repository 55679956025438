import { alpha } from '@mui/material'

export const createDrawer = (colors: any, ui: any) => ({
  MuiDrawer: {
    root: {
      backgroundColor: alpha(colors.black, 0.8),
    },
    paperAnchorLeft: {
      backgroundColor: colors.black,
      padding: '60px 40px',
      width: `${ui.leftBarSize}px`,
    },
  },
})

export default createDrawer
