import { Point } from '../../../models/commons.models'
import Constants from '../../../constants'

type PointProps = {
  position: Point
  size: number
  id?: string
}
const PointComponent: React.FC<PointProps> = ({ position, size, id }): JSX.Element => {
  return (
    <circle id={id} cx={position[0]} cy={position[1]} r={size} fill={Constants.colors.primary} />
  )
}
export default PointComponent
