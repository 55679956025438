import { alpha, Card, CardActionArea, Paper, styled, Typography, IconButton } from '@mui/material'
import React, { memo } from 'react'
import { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Add as AddIcon,
  Edit as EditIcon,
  ArrowBack as ArrowBackIcon,
  ArrowForward as ArrowForwardIcon,
} from '@mui/icons-material'

import useSnackbar from '../../../hooks/useSnackbar.hooks'
import { FileUtils } from '../../../utils/files.utils'
import { ImageProps } from '../../../models/props.models'
import Constants from '../../../constants'
import { Capacitor } from '@capacitor/core'

import Stack from '../Stack.common'
import ImageWithBackgroundComponent from '../ImageWithBackground.common'

export const CardContainer = styled(Stack, {
  shouldForwardProp: (prop) => prop !== 'required',
})<{ required?: boolean }>(({ required, theme }) => ({
  alignItems: 'center',
  backgroundColor: required
    ? alpha(theme.palette.primary.main, 0.1)
    : theme.palette.textFieldBackground,
  display: 'flex',
  height: '100%',
  justifyContent: 'center',
  position: 'relative',
  width: '100%',
}))

const UpdateLabel = styled(Typography)(({ theme }) => ({
  color: theme.palette.white,
  fontSize: '0.875rem',
  fontWeight: 500,
}))
const AddLabel = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontSize: '0.875rem',
  fontWeight: 500,
}))
const DescriptionLabel = styled(Typography)({
  fontSize: '0.75rem',
  fontWeight: 400,
  textAlign: 'center',
})
const MaxSizeLabel = styled(Typography)<{ color?: string }>(({ color, theme }) => ({
  color: color === 'primary' ? theme.palette.primary.main : theme.palette.white,
  fontSize: '.75rem',
  fontWeight: 300,
}))

const ImageInput = memo(
  (props: ImageProps): JSX.Element => {
    const {
      next,
      previous,
      description,
      onChange,
      label,
      maxFileSize,
      value,
      fileDetails,
      readOnly,
      required,
    } = props

    const { t } = useTranslation()
    const show = useSnackbar()
    const inputRef = useRef<HTMLInputElement>(null)
    let backgroundImage = value ? URL.createObjectURL(value) : fileDetails?.src || fileDetails?.path
    const inputHandler = async () => {
      if (!readOnly) {
        if (Capacitor.isNativePlatform()) {
          const picture = await FileUtils.takePicture()
          if (picture) {
            onChange?.(picture)
          }
        } else {
          inputRef.current?.click()
        }
      } else if (fileDetails) {
        FileUtils.openFile(fileDetails)
      }
    }

    return (
      <Stack width="100%">
        <input
          ref={inputRef}
          hidden
          onChange={(evt) => {
            const files = Array.from(evt.target.files || new FileList())

            if (files.length) {
              if (maxFileSize && files.some((f) => f.size / (1024 * 1024) > maxFileSize)) {
                show(t('errors:fileSize', { size: maxFileSize }), 'warning')
              } else {
                onChange?.(files[0])
              }

              evt.target.value = ''
            }
          }}
          disabled={!onChange}
          accept="image/*"
          type="file"
        />
        <Card
          aria-label="select-picture-card"
          component={readOnly ? Paper : CardActionArea}
          elevation={0}
          onClick={inputHandler}>
          <CardContainer
            required={required}
            direction={fileDetails ? 'row' : undefined}
            spacing={0.5}>
            <ImageWithBackgroundComponent blackFilter={!readOnly} image={backgroundImage} />
            {!readOnly && (
              <Stack position="absolute" alignItems="center" justifyContent="center">
                {backgroundImage ? (
                  <>
                    <EditIcon style={{ color: Constants.colors.white }} />
                    <UpdateLabel>{t('global:actions.edit')}</UpdateLabel>
                  </>
                ) : (
                  <>
                    <AddIcon color="primary" />
                    <AddLabel>
                      {label}
                      {required ? '*' : ''}
                    </AddLabel>
                    <DescriptionLabel color="primary">{description}</DescriptionLabel>
                  </>
                )}
                {maxFileSize && (
                  <MaxSizeLabel color={backgroundImage ? 'white' : 'primary'}>{`(${t(
                    'global:inputs.maxSize',
                    {
                      size: maxFileSize,
                    },
                  )})`}</MaxSizeLabel>
                )}
              </Stack>
            )}
            {next && (
              <IconButton
                onClick={(evt: any) => {
                  evt.stopPropagation()
                  next()
                }}
                sx={{ position: 'absolute', right: '5px' }}>
                <ArrowForwardIcon color="primary" />
              </IconButton>
            )}
            {previous && (
              <IconButton
                onClick={(evt: any) => {
                  evt.stopPropagation()
                  previous()
                }}
                sx={{ position: 'absolute', left: '5px' }}>
                <ArrowBackIcon color="primary" />
              </IconButton>
            )}
          </CardContainer>
        </Card>
      </Stack>
    )
  },
  (prevProps, nextProps) => {
    return (
      JSON.stringify(prevProps) === JSON.stringify(nextProps) &&
      prevProps.value?.name === nextProps.value?.name
    )
  },
)

export default React.memo(ImageInput)
