import FR from './fr'
import FRCA from './frCa'
import EN from './en'

export enum LangEnum {
  FR = 'fr',
  EN = 'en',
  FR_CA = 'fr-CA',
}

export const DEFAULT_LANGS = {
  [LangEnum.FR]: FR,
  [LangEnum.EN]: EN,
  [LangEnum.FR_CA]: FRCA,
}
export type Lang = typeof FR
export type Langs = typeof DEFAULT_LANGS
