import React, {useState, useMemo} from "react";
import {useTranslation} from "react-i18next";
import ModalForm, {ModalFormProps} from "../common/ModalForm.common";
import {FormItem, ItemType} from "../../models/props.models";

interface ModalSaveFilterProps extends Omit<ModalFormProps, "value" | "items" | "steps" | "setValue" | "title" | "submitLabel"> {}
const ModalSaveFilter: React.FC<ModalSaveFilterProps> = (props) => {
  const {t} = useTranslation();
  const [value, setValue] = useState<{name: string}>({name: ""});

  const items: FormItem[] = useMemo(
    () => [
      {
        type: ItemType.text,
        key: "name",
        required: true,
        props: {
          label: t("reports:messages.savedFilterName"),
          placeholder: t("reports:messages.savedFilterName"),
        },
      },
    ],
    [t]
  );
  return (
    <ModalForm
      {...props}
      value={value}
      setValue={setValue}
      items={items}
      title={t("reports:messages.savedFilterConfirmTitle")}
      submitLabel={t("global:actions.confirm")}
    />
  );
};

export default ModalSaveFilter;
