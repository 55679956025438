import { useState, useMemo, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { RequestType, ApiRequest } from '../../models/requests.models'
import { User, UserStatus } from '../../models/users.models'
import { Organization } from '../../models/organizations.models'
import { ItemType, FormItem } from '../../models/props.models'
import { organizationsService } from '../../store/organizations'
import { sessionQuery } from '../../store/session'
import { usersService } from '../../store/users'
import ModalForm, { ModalFormProps } from '../common/ModalForm.common'
import { useObservable } from '@ngneat/react-rxjs'
import LoaderOverlay from '../layout/LoaderOverlay.layout'
import { YupUtils } from '../../utils/yup.utils'

interface ModalFormRequestProps
  extends Omit<ModalFormProps, 'value' | 'items' | 'steps' | 'setValue'> {
  catalog: string
  organization: string
}

const ModalFormRequest: React.FC<ModalFormRequestProps> = (props) => {
  const { t } = useTranslation()
  const { catalog, organization, ...formProps } = props
  const [user] = useObservable(sessionQuery.user)
  const [receiver, setReceiver] = useState<Organization>()
  const [receiverUsers, setReceiverUsers] = useState<User[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [value, setValue] = useState<ApiRequest>({
    type: RequestType.catalogTransfer,
    catalog: catalog,
    senderUser: user?.organization._id === organization ? user?._id : undefined,
  })

  useEffect(() => {
    if (receiver) {
      const getUsers = async () => {
        try {
          setLoading(true)
          const { data } = await usersService.getUsers({
            organization: receiver._id,
            status: [UserStatus.accepted],
          })
          setReceiverUsers(data)

          setValue((val: any) => ({
            ...val,
            receiver: data.length > 0 ? receiver._id : undefined,
            receiverUser: data.length === 1 ? data[0]._id : undefined,
            customReceiver:
              data.length === 0
                ? {
                    organizationId: receiver._id,
                    organization: receiver.name,
                    lang: user?.lang,
                  }
                : undefined,
          }))
        } catch (err) {}
        setLoading(false)
      }
      getUsers()
    } else {
      setReceiverUsers([])
    }
  }, [receiver, user?.lang])

  const items: FormItem[] = useMemo(
    () =>
      [
        {
          type: ItemType.asyncSelect,
          key: 'receiver',
          grid: { xs: 12, sm: 6 },
          rules: [
            (value: ApiRequest) =>
              value.receiver || value.customReceiver?.organization
                ? undefined
                : t('errors:required'),
          ],
          onChange: (selectValue: any) => {
            setReceiver(selectValue?._id ? selectValue : undefined)
            setValue((val: any) => ({
              ...val,
              receiverUser: selectValue?._id ? val?.receiverUser : undefined,
              receiver: selectValue?._id ? selectValue?._id : undefined,
              customReceiver: selectValue?.isNew
                ? {
                    organization: selectValue?.value,
                    lang: user?.lang,
                  }
                : undefined,
            }))
          },
          formatValue: (_: any) => {
            return value?.customReceiver
              ? {
                  label: value.customReceiver?.organization,
                  value: value.customReceiver?.organizationId ?? value.customReceiver?.organization,
                }
              : value.receiver && receiver
              ? { label: receiver.name ?? '', value: receiver._id ?? '' }
              : undefined
          },
          props: {
            allowNew: true,
            label: t('requests:attributes.receiver'),
            placeholder: t('requests:attributes.receiver'),
            minimumSearchLength: 3,
            getOptions: (search?: string) => organizationsService.getOrganizationOptions(search),
            dataValue: true,
          },
        },
        ...(receiverUsers.length === 0 && value.customReceiver
          ? [
              {
                type: ItemType.group,
                key: 'customReceiver',
                items: [
                  {
                    type: ItemType.text,
                    key: 'customReceiver.firstname',
                    required: true,
                    grid: { xs: 12, sm: 6 },
                    props: {
                      label: t('users:attributes.firstname'),
                      placeholder: t('users:attributes.firstname'),
                    },
                  },
                  {
                    type: ItemType.text,
                    key: 'customReceiver.lastname',
                    required: true,
                    grid: { xs: 12, sm: 6 },
                    props: {
                      label: t('users:attributes.lastname'),
                      placeholder: t('users:attributes.lastname'),
                    },
                  },

                  {
                    type: ItemType.text,
                    key: 'customReceiver.email',
                    required: true,
                    rules: [YupUtils.FieldValidationType.email],
                    grid: { xs: 12, sm: 6 },
                    props: {
                      label: t('users:attributes.email'),
                      placeholder: t('users:attributes.email'),
                      type: 'email',
                    },
                  },
                ],
                props: {
                  title: t('requests:attributes.customReceiver.title'),
                  description: t('requests:attributes.customReceiver.description'),
                },
              },
            ]
          : []),
        ...(receiverUsers.length > 1
          ? [
              {
                type: ItemType.select,
                key: 'receiverUser',
                grid: { xs: 12, sm: 6 },
                required: true,
                props: {
                  label: t('requests:attributes.receiverUser'),
                  placeholder: t('requests:attributes.receiverUser'),
                  items: receiverUsers.map((user: User) => ({
                    label: user.email,
                    value: user._id,
                  })),
                },
              },
            ]
          : []),
      ] as FormItem[],
    [t, receiver, value.customReceiver, value.receiver, receiverUsers, user?.lang],
  )

  return (
    <>
      {loading && <LoaderOverlay />}
      <ModalForm {...formProps} value={value} setValue={setValue} items={items} maxWidth="md" />
    </>
  )
}
export default ModalFormRequest
