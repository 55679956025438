import { styled, Tab, Tabs, Box, useMediaQuery, Button } from '@mui/material'
import { useTheme } from '@mui/material/styles/index.js'
import { useLocation } from 'react-router-dom'

import { useState, useEffect, forwardRef } from 'react'
import { NavLink } from 'react-router-dom'
import { Actions as ActionsInterface } from '../../models/props.models'
import Constants from '../../constants'
import Actions from './Actions.common'

const StyledTabs = styled(Tabs, {
  shouldForwardProp: (prop) => prop !== 'lightmode',
})<{ lightmode?: boolean }>(({ lightmode }) =>
  lightmode
    ? {
        '& > div:first-of-type': {
          border: 'none',
          '&::before': {
            backgroundColor: 'transparent',
          },
        },
      }
    : {},
)

interface TabItem {
  label: string
  path?: string
  group?: string
  secondary?: boolean
}

interface TabsComponentProps {
  lightMode?: boolean
  items: TabItem[]
  onChange?: (index: number) => void
  value?: number
  actions?: ActionsInterface
  hasError?: (index: number) => boolean
}

const TabsComponent: React.FC<TabsComponentProps> = ({
  actions,
  lightMode,
  items,
  onChange,
  value,
  hasError,
}): JSX.Element => {
  const location = useLocation()
  const hasGroup = !!items[0]?.group
  const isRoute = !!items[0]?.path
  const isAt = isRoute ? items.findIndex((item) => item.path === location.pathname) : -1
  const [current, setCurrent] = useState<number>(isAt >= 0 ? isAt : 0)

  useEffect(() => {
    const isAt = isRoute ? items.findIndex((item) => item.path === location.pathname) : -1

    setCurrent(isAt !== -1 ? isAt : value || 0)
  }, [value, location, items, isRoute])

  useEffect(() => {
    onChange?.(current)
  }, [onChange, current])

  const theme = useTheme()
  const onlyXs = useMediaQuery(theme.breakpoints.only('xs'))

  return (
    <Box sx={{ position: 'relative', width: '100%', paddingX: onlyXs ? '0' : '30px' }}>
      <StyledTabs
        lightmode={lightMode}
        value={current}
        TabIndicatorProps={{
          style: {
            backgroundColor: items[current]?.secondary ? Constants.colors.secondary : undefined,
          },
        }}
        onChange={(_, newIndex) => {
          setCurrent(newIndex)
        }}>
        {items.map((item: TabItem, index: number) => (
          <Tab
            aria-label={
              item.secondary && lightMode
                ? 'light-mode-secondary'
                : item.secondary
                ? 'secondary'
                : lightMode
                ? 'light-mode-secondary'
                : undefined
            }
            sx={{ color: hasError?.(index) ? Constants.colors.error : 'inherit' }}
            key={index}
            value={index}
            label={item.label}
            component={forwardRef<any, any>((props, ref) => {
              const group = items[index]?.group
              const showGroup = group && group !== items[index - 1]?.group
              const baseComponent = props.to ? (
                <NavLink {...props} ref={ref} />
              ) : (
                <Button {...props} ref={ref} />
              )
              return hasGroup ? (
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="flex-end"
                  sx={{
                    borderLeft:
                      showGroup && index !== 0 ? `1px solid ${Constants.colors.blockBorder}` : '',
                  }}>
                  <Box
                    display="flex"
                    alignItems="flex-end"
                    justifyContent="flex-start"
                    padding="12px 0 0 16px"
                    sx={{
                      fontWeight: 500,
                      color: item.secondary ? Constants.colors.secondary : Constants.colors.primary,
                    }}>
                    {showGroup && group}
                  </Box>
                  {baseComponent}
                </Box>
              ) : (
                baseComponent
              )
            })}
            {...(isRoute ? { to: item.path } : {})}
            wrapped
          />
        ))}
      </StyledTabs>
      {actions && (
        <Box position="absolute" right={onlyXs ? '0' : '15px'} top="4px">
          <Actions items={actions} />
        </Box>
      )}
    </Box>
  )
}

export default TabsComponent
