import DetailsComponent from '../common/Details.common'
import { User, Profession } from '../../models/users.models'
import { Actions, ItemType, DetailItem } from '../../models/props.models'
import { useTranslation } from 'react-i18next'

type InformationUserProps = {
  title: string
  user: User
  additionals?: DetailItem[]
  onClick?: () => void
  actions?: Actions
}
const InformationUser: React.FC<InformationUserProps> = ({
  title,
  user,
  additionals,
  actions,
  onClick,
}) => {
  const { t } = useTranslation()

  return (
    <DetailsComponent
      title={title}
      value={user}
      actions={actions}
      onClick={onClick}
      items={[
        { label: t('users:attributes.lastname'), key: 'lastname' },
        { label: t('users:attributes.firstname'), key: 'firstname' },
        { label: t('users:attributes.email'), key: 'email' },
        {
          label: t('users:attributes.phoneNumber'),
          key: 'phoneNumber',
          formatValue: (phoneNumber: string) => phoneNumber || '',
        },
        {
          label: t('users:attributes.address'),
          key: 'address',
          formatValue: (address: string) => address || '',
        },
        {
          label: t('users:attributes.professions'),
          key: 'professions',
          formatValue: (professions: Profession[]) =>
            professions?.length
              ? professions.map((profression) => t(`users:professions.${profression}`)).join('\n')
              : '',
        },
        {
          label: t('users:attributes.receiveNewsEmails'),
          type: ItemType.checkbox,
          key: 'receiveNewsEmails',
          props: {
            disabled: true,
          },
        },
        {
          label: t('users:attributes.receiveMatchingEmails'),
          type: ItemType.checkbox,
          key: 'receiveMatchingEmails',
          props: {
            disabled: true,
          },
        },

        ...(additionals || []),
      ]}
    />
  )
}
export default InformationUser
