import { useState } from 'react'
import { Menu as MenuIcon, Edit as EditIcon } from '@mui/icons-material'
import { MenuItem, Menu, styled, Typography, IconButton, Box } from '@mui/material'
import Stack from './Stack.common'
import { Action as ActionInterface, Actions as ActionsInterface } from '../../models/props.models'

const OptionLabel = styled(Typography)<{ color?: string }>(({ color, theme }) => ({
  fontSize: '0.75rem',
  color: color
    ? (theme.palette as any)[color]?.main || (theme.palette as any)[color] || color
    : 'inherit',
  fontWeight: 500,
}))

interface ActionsProps {
  items: ActionsInterface
  customButton?: JSX.Element
}

const Actions: React.FC<ActionsProps> = ({ items, customButton }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const isMenu = (items as ActionInterface[]).length > 0
  const base: ActionInterface = (
    isMenu
      ? {
          icon: <MenuIcon color="primary" />,
          onClick: (event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget),
        }
      : { icon: <EditIcon />, color: 'primary', ...items }
  ) as ActionInterface

  return (
    <>
      <Box
        onClick={(event) => {
          event.stopPropagation()
          base.onClick(event)
        }}>
        {customButton ? customButton : base.custom ? base.custom : <IconButton color={base.color}>{base.icon}</IconButton>}
      </Box>
      {isMenu && (
        <Menu
          onClick={(event) => event.stopPropagation()}
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={setAnchorEl.bind(null, null)}
          anchorOrigin={{ vertical: 'center', horizontal: 'center' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}>
          {(items as ActionInterface[]).map((item, i) => (
            <MenuItem
              key={i}
              onClick={(evt) => {
                evt.stopPropagation()
                item.onClick(evt)
                setAnchorEl(null)
              }}>
              {item.custom ? (
                item.custom
              ) : (
                <Stack direction="row" alignItems="center" spacing={1}>
                  {item.icon}
                  <OptionLabel color={item.color}> {item.label}</OptionLabel>
                </Stack>
              )}
            </MenuItem>
          ))}
        </Menu>
      )}
    </>
  )
}

export default Actions
