import { memo } from 'react'
import { alpha, Box, styled } from '@mui/material'

const Container = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'image' && prop !== 'filter',
})<{
  image?: string
  filter?: boolean
}>(({ theme, image, filter }) => ({
  height: '100%',
  left: 0,
  position: 'absolute',
  top: 0,
  width: '100%',
  '& > img[aria-label=background]': {
    filter: 'blur(3.5px)',
    height: '100%',
    left: 0,
    objectFit: 'cover',
    objectPosition: 'center',
    position: 'absolute',
    top: 0,
    WebkitFilter: 'blur(3.5px)',
    width: '100%',
  },
  '& > div[aria-label=image]': {
    backgroundColor: theme.palette.black,
    backgroundImage:
      !!image && !!filter
        ? `
      linear-gradient(to left, ${alpha(theme.palette.black, 0.5)} 0%, ${alpha(
            theme.palette.black,
            0.5,
          )} 100%),
      url(${image})
    `
        : `url(${image})`,
    backgroundPosition: 'center',
    // backgroundSize: 'contain',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    height: '100%',
    position: 'absolute',
    width: '100%',
    '& > img': {
      height: '100%',
      objectFit: 'contain',
      objectPosition: 'center',
      width: '100%',
    },
  },
  '& > img[aria-label=placeholder]': {
    height: '100%',
    objectFit: 'cover',
    objectPosition: 'center',
    width: '100%',
  },
}))

interface ImageWithBackgroundComponentProps {
  blackFilter?: boolean
  image?: string
  placeholder?: string
}

const ImageWithBackgroundComponent = (props: ImageWithBackgroundComponentProps): JSX.Element => {
  const { blackFilter, image, placeholder } = props

  return (
    <Container filter={blackFilter} image={image}>
      {!!image && (
        <>
          <Box aria-label="image" />
        </>
      )}
      {!image && !!placeholder && (
        <img aria-label="placeholder" alt="placeholder" src={placeholder} />
      )}
    </Container>
  )
}

export default memo(ImageWithBackgroundComponent)
