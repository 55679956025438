import { AxiosResponse } from 'axios'
import { axiosApi } from './base.api'

import { PlatformReference } from '../models/platforms.models'
import { Reports } from '../models/reports.model'
import { BuildingType } from '../models/catalogs.models'

export namespace ReportsApi {
  export interface Filters {
    platform?: PlatformReference
    catalogOwners?: string[]
    catalogs?: string[]
    buildingTypes?: BuildingType[]
    tertiaryCategories?: string[]
    years?: number[]
  }

  export const getReports = async (filters?: Filters): Promise<Reports> => {
    const response: AxiosResponse<Reports> = await axiosApi.get(`/reports`, {
      params: filters,
    })
    return response.data
  };

  export const getXlsx = async (filters?: Filters): Promise<Blob> => {
    const response: AxiosResponse<Blob> = await axiosApi.get(`/reports/xlsx`, {
      responseType: "blob",
      params: filters,
    })
    return response.data
  };
}
