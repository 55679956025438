import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import RequestList, { RequestListProps } from '../request/List.request'
import { ListRef } from '../common/List.common'
import useModal from '../../hooks/useModal.hooks'
import useSnackbar from '../../hooks/useSnackbar.hooks'
import { useTranslation } from 'react-i18next'

import { Filter, ItemType, Actions } from '../../models/props.models'

import {
  Request,
  RequestStatus,
  RequestType,
  RequestsPagination,
  hasReceiverRights,
  hasSenderRights,
} from '../../models/requests.models'
import { sessionQuery } from '../../store/session'
import { requestsService } from '../../store/requests'
import { createOptionsFromEnum } from '../../utils/i18n.utils'
import LoaderOverlay from '../layout/LoaderOverlay.layout'
import { useObservable } from '@ngneat/react-rxjs'
import Modal from '../layout/Modal.layout'
import RequestDetailsModal from '../request/ModalDetails.request'
import ModalDeny from '../request/ModalDeny.request'

interface PageRequestListProps
  extends Omit<
    RequestListProps,
    | 'filter'
    | 'getValues'
    | 'onValueClick'
    | 'valueActions'
    | 'isValueSelected'
    | 'onValueSelect'
    | 'onFilterChange'
  > {}
const PageRequestList: React.FC<PageRequestListProps> = (props) => {
  const { received, sent, ...listProps } = props
  const listRef = useRef<ListRef | null>(null)
  const show = useSnackbar()
  const { t } = useTranslation()
  const [modal, setModal] = useModal<string>()
  const [validationModal, setValidationModal] = useState<string>()
  const [loading, setLoading] = useState<boolean>(false)
  const [openedRequest, setOpenedRequest] = useState<Request>()
  const [user] = useObservable(sessionQuery.user)

  useEffect(() => {
    const getModal = async () => {
      if (modal || validationModal) {
        setLoading(true)
        try {
          setOpenedRequest(
            await requestsService.getRequestById(
              (modal
                ? modal
                : validationModal
                    ?.replace(`${RequestStatus.accepted}.`, '')
                    ?.replace(`${RequestStatus.denied}.`, '')
                    ?.replace(`${RequestStatus.canceled}.`, '')) as string,
            ),
          )
        } catch (err: any) {
          show(err)
        }
        setLoading(false)
      } else {
        setOpenedRequest(undefined)
      }
    }
    getModal()
  }, [show, modal, validationModal])

  const getRequests = useCallback(
    async (filters: any): Promise<RequestsPagination> => {
      return await requestsService.getRequests({ ...filters, received, sent })
    },
    [received, sent],
  )

  const filter = useMemo<Filter>(
    () => ({
      items: [
        {
          type: ItemType.multiSelect,
          key: 'types',
          grid: { lg: 2, sm: 6, xs: 12 },
          props: {
            placeholder: t('requests:components.list.typeFilter'),
            multiSelectedLabel: (count: number) => t('global:inputs.selectedOptions', { count }),
            items: createOptionsFromEnum(RequestType, 'requests:type'),
          },
        },
        {
          type: ItemType.multiSelect,
          key: 'status',
          grid: { lg: 2, sm: 6, xs: 12 },
          props: {
            placeholder: t('requests:components.list.statusFilter'),
            multiSelectedLabel: (count: number) => t('global:inputs.selectedOptions', { count }),
            items: createOptionsFromEnum(RequestStatus, 'requests:status'),
          },
        },
      ],
    }),
    [t],
  )

  const requestActions = useCallback(
    (request: Request): Actions | undefined => {
      const senderRights = hasSenderRights(user, request)
      const receiverRights = hasReceiverRights(user, request)

      const actions =
        request.status !== RequestStatus.pending
          ? []
          : [
              ...(!!request.receiver && receiverRights
                ? [
                    {
                      label: t('requests:actions.accepted.label'),
                      onClick: () => setValidationModal(`${RequestStatus.accepted}.${request._id}`),
                    },
                  ]
                : []),
              ...(receiverRights
                ? [
                    {
                      label: t('requests:actions.denied.label'),
                      onClick: () => setValidationModal(`${RequestStatus.denied}.${request._id}`),
                    },
                  ]
                : []),
              ...(request.type !== RequestType.newUser && senderRights
                ? [
                    {
                      label: t('requests:actions.canceled.label'),
                      onClick: () => setValidationModal(`${RequestStatus.canceled}.${request._id}`),
                    },
                  ]
                : []),
            ]
      return actions.length > 0 ? actions : undefined
    },
    [t, user],
  )

  return (
    <>
      {loading && <LoaderOverlay />}
      <RequestList
        {...listProps}
        received={received}
        sent={sent}
        ref={listRef}
        getValues={getRequests}
        onValueClick={(request: Request) => setModal(request._id)}
        filter={filter}
        valueActions={requestActions}
      />

      {modal && openedRequest && (
        <RequestDetailsModal
          request={openedRequest}
          received={received}
          hasSenderRights={hasSenderRights.bind(null, user)}
          hasReceiverRights={hasReceiverRights.bind(null, user)}
          sent={sent}
          onUpdate={(status: RequestStatus) => setValidationModal(`${status}.${modal}`)}
          onClose={() => setModal('')}
        />
      )}
      {validationModal &&
        openedRequest &&
        (() => {
          const status = validationModal.split('.')[0] as RequestStatus
          return status === RequestStatus.denied ? (
            <ModalDeny
              description={t(`requests:actions.denied.description.${openedRequest.type}`)}
              onSuccess={() => {
                show(t(`requests:actions.denied.success`))
                listRef?.current?.updateValues()
                setValidationModal('')
              }}
              onClose={() => setValidationModal('')}
              onSubmit={async (data: any) => {
                await requestsService.updateRequest(openedRequest._id, {
                  status,
                  deniedFor: data.deniedFor,
                })
              }}
            />
          ) : (
            <Modal
              description={t(`requests:actions.${status}.description.${openedRequest.type}`)}
              title={t(`requests:actions.${status}.label`)}
              onSuccess={() => {
                show(t(`requests:actions.${status}.success`))
                listRef?.current?.updateValues()
                setValidationModal('')
              }}
              onClose={() => setValidationModal('')}
              onConfirm={async () => {
                await requestsService.updateRequest(openedRequest._id, { status })
              }}
            />
          )
        })()}
    </>
  )
}
export default PageRequestList
