import { useMemo, forwardRef } from 'react'
import { useTranslation } from 'react-i18next'

import { getUserStatusColor, User, UserType } from '../../models/users.models'
import { ColumnItem, ItemType } from '../../models/props.models'
import List, { ListProps, ListRef } from '../common/List.common'
import UserCard from './Card.user'
import { sessionService } from '../../store/session'

export interface UserListProps extends Omit<ListProps, 'card' | 'columns' | 'getCoordinates'> {}
const UserList = forwardRef<ListRef, UserListProps>((props, ref) => {
  const { t } = useTranslation()

  const showStatus = useMemo(() => {
    return sessionService.showStatus({ type: 'user' })
  }, [])

  const columns = useMemo<ColumnItem[]>(
    () => [
      ...(showStatus
        ? [
            (user: User | undefined) => ({
              label: t('users:attributes.status'),
              key: 'status',
              type: ItemType.chip,
              props: {
                full: true,
                color: user ? getUserStatusColor(user.status) : '',
                label: user ? t(`users:status.${user.status}`) : '',
              },
            }),
          ]
        : []),
      {
        label: t('users:attributes.organization'),
        key: 'organization.name',
      },
      { label: t('users:attributes.lastname'), key: 'lastname' },
      { label: t('users:attributes.firstname'), key: 'firstname' },
      { label: t('users:attributes.email'), key: 'email' },
      {
        label: t('users:attributes.phoneNumber'),
        key: 'phoneNumber',
      },
      {
        label: t('users:attributes.type'),
        key: 'type',
        formatValue: (type: UserType) => (type ? t(`users:types.${type}`) : ''),
      },
      {
        label: t('users:attributes.createdAt'),
        key: 'createdAt',
        formatValue: (createdAt: string) =>
          createdAt
            ? t('global:format.date', {
                date: new Date(createdAt),
              })
            : '-',
      },
    ],
    [t, showStatus],
  )

  return <List ref={ref} {...props} columns={columns} card={UserCard} />
})
export default UserList
