import { useTranslation } from 'react-i18next'
import { styled, Typography } from '@mui/material'
import Stack from '../../common/Stack.common'

const Title = styled(Typography)((props) => ({
  fontSize: '1.875rem',
  fontWeight: 800,
  marginBottom: '90px',
  lineHeight: 1.25,
  textAlign: 'center',
  [props.theme.breakpoints.down('sm')]: {
    marginBottom: '40px',
  },
}))

const SectionTitle = styled(Typography)((props) => ({
  color: props.theme.palette.primary.main,
  fontSize: '1.375rem',
  fontWeight: 800,
  lineHeight: 1.25,
}))

const SectionTextContainer = styled(Typography)({
  fontSize: '0.938rem',
  fontWeight: 500,
  whiteSpace: 'pre-line',
})

const SectionTextContainerStrong = styled('span')({ fontWeight: 700, wordBreak: 'break-word' })

const TmRgpd = () => {
  const { t: tI18n } = useTranslation()
  // No check since the trad only exist on tmConfig
  const t = (string: any) => {
    return tI18n(string as any)
  }

  return (
    <Stack alignItems="center" py="70px" position="relative">
      <Title>{t('rgpd:title')}</Title>
      <Stack spacing="50px" width="100%" maxWidth="900px">
        <Stack spacing="15px">
          <SectionTitle>{t('rgpd:paragraph1.title')}</SectionTitle>
          <SectionTextContainer>
            {t('rgpd:paragraph1.description.part1')}
            <SectionTextContainerStrong>
              {t('rgpd:paragraph1.description.part2')}
            </SectionTextContainerStrong>
            {t('rgpd:paragraph1.description.part3')}
            <SectionTextContainerStrong>
              {t('rgpd:paragraph1.description.part4')}
            </SectionTextContainerStrong>
            {t('rgpd:paragraph1.description.part5')}
          </SectionTextContainer>
        </Stack>
      </Stack>
    </Stack>
  )
}

export default TmRgpd
