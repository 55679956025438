import { useTranslation } from 'react-i18next'
import { useMemo, useState } from 'react'

import useSnackbar from '../../hooks/useSnackbar.hooks'

import ModalForm, { ModalFormProps } from '../common/ModalForm.common'
import { YupUtils } from '../../utils/yup.utils'
import { createOptionsFromEnum } from '../../utils/i18n.utils'
import { ContactUsData, ContactSubject } from '../../models/sessions.models'
import { User } from '../../models/users.models'
import { FormItem, ItemType } from '../../models/props.models'
import { sessionService } from '../../store/session'

interface ModalFormContactUsProps
  extends Omit<ModalFormProps, 'value' | 'items' | 'steps' | 'setValue' | 'title'> {
  user?: User
}
const ModalFormContactUs: React.FC<ModalFormContactUsProps> = (props) => {
  const { t } = useTranslation()
  const show = useSnackbar()

  const phoneNumber = useMemo(
    () =>
      sessionService.getCountryParam('phoneNumber') as
        | { format: string; validation: string }
        | undefined,
    [],
  )

  const [value, setValue] = useState<ContactUsData>({
    ...(props.user
      ? {
          lastname: props.user.lastname,
          firstname: props.user.firstname,
          companyName: props.user.organization.name,
          phoneNumber: props.user.phoneNumber,
          email: props.user.email,
        }
      : {}),
    subject: ContactSubject.information,
  } as ContactUsData)

  const items: FormItem[] = useMemo(
    () => [
      {
        type: ItemType.text,
        key: 'lastname',
        required: true,
        grid: { xs: 12, sm: 6 },
        props: {
          label: t('sessions:components.modalFormContactUs.lastname'),
          placeholder: t('sessions:components.modalFormContactUs.lastname'),
          autocomplete: 'family-name',
        },
      },
      {
        type: ItemType.text,
        key: 'firstname',
        required: true,
        grid: { xs: 12, sm: 6 },
        props: {
          autocomplete: 'given-name',
          label: t('sessions:components.modalFormContactUs.firstname'),
          placeholder: t('sessions:components.modalFormContactUs.firstname'),
        },
      },
      {
        type: ItemType.text,
        key: 'companyName',
        required: true,
        grid: { xs: 12, sm: 6 },
        props: {
          label: t('sessions:components.modalFormContactUs.companyName'),
          placeholder: t('sessions:components.modalFormContactUs.companyName'),
        },
      },
      {
        type: ItemType.text,
        key: 'phoneNumber',
        grid: { xs: 12, sm: 6 },
        rules: [
          phoneNumber
            ? { rule: YupUtils.FieldValidationType.regexp, params: phoneNumber.validation }
            : () => '',
        ],
        props: {
          pattern: phoneNumber?.format,
          label: t('sessions:components.modalFormContactUs.phoneNumber'),
          placeholder: t('sessions:components.modalFormContactUs.phoneNumber'),
          autocomplete: 'tel',
        },
      },
      {
        type: ItemType.text,
        key: 'email',
        grid: { xs: 12, sm: 6 },
        required: true,
        rules: [YupUtils.FieldValidationType.email],
        props: {
          label: t('sessions:components.modalFormContactUs.email'),
          placeholder: t('sessions:components.modalFormContactUs.email'),
          type: 'email',
          autocomplete: 'email',
        },
      },
      {
        type: ItemType.select,
        key: 'subject',
        grid: { xs: 12, sm: 6 },
        required: true,
        props: {
          label: t('sessions:components.modalFormContactUs.subject'),
          placeholder: t('global:inputs.selectPlaceholder'),
          items: createOptionsFromEnum(ContactSubject, 'sessions:subjects'),
        },
      },
      {
        type: ItemType.text,
        key: 'message',
        required: true,
        props: {
          minRows: 4,
          multiline: true,
          label: t('sessions:components.modalFormContactUs.message'),
          placeholder: t('sessions:components.modalFormContactUs.message'),
        },
      },
    ],
    [t, phoneNumber],
  )

  return (
    <ModalForm
      {...props}
      maxWidth="md"
      title={t('sessions:components.modalFormContactUs.title')}
      description={t('sessions:components.modalFormContactUs.description')}
      submitLabel={t('global:actions.send')}
      onSuccess={() => {
        show(t('sessions:components.modalFormContactUs.success'))
      }}
      scrollable={false}
      value={value}
      setValue={setValue}
      items={items}
    />
  )
}
export default ModalFormContactUs
