import { alpha } from '@mui/material'
export const createMenu = (colors: any) => ({
  MuiMenu: {
    paper: {
      backgroundColor: 'white',
      borderRadius: '4px',
      boxShadow: `0 0 10px 0 ${alpha(colors.black, 0.1)}`,
      padding: 0,
      margin: 0,
    },
    list: {
      maxHeight: '80vh',
      padding: 0,
    },
  },
  MuiMenuItem: {
    root: {
      alignItems: 'center',
      borderBottom: `1px solid ${colors.menuBorder}`,
      whiteSpace: 'normal',
      wordBreak: 'break-word !important',
      display: 'flex',
      flex: 1,
      fontSize: '0.75rem',
      fontWeight: 500,
      height: 45,
      padding: '0 15px',
      '&[aria-label=primary]': {
        color: colors.primary,
      },
      '&[aria-label=secondary]': {
        color: colors.secondary,
      },
      '&:last-of-type': {
        borderColor: 'transparent',
      },
      '& > .MuiCheckbox-root': {
        marginRight: 10,
        padding: '0 0 2px',
        '&:hover': {
          backgroundColor: 'transparent',
        },
      },
      '&.Mui-selected': {
        fontWeight: 600,
      },
    },
  },
})
