import { forwardRef, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { Material, MaterialType, TermsOfSale } from '../../models/materials.models'
import { ColumnItem } from '../../models/props.models'
import { Catalog } from '../../models/catalogs.models'
import List, { ListProps, ListRef } from '../common/List.common'
import MaterialCard from './Card.material'
import { StringUtils } from '../../utils/commons.utils'

export interface MaterialListProps extends Omit<ListProps, 'card' | 'columns' | 'getCoordinates'> {
  type: MaterialType
  canUpdate?: boolean
  isAdmin?: boolean
  isPublic?: boolean
  isCatalogPage?: boolean
  addToCart?: (material: Material, quantity: number) => void
}

const MaterialList = forwardRef<ListRef, MaterialListProps>((props, ref) => {
  const { type, canUpdate, isAdmin, isPublic, isCatalogPage, addToCart, ...listProps } = props
  const { t } = useTranslation()

  const columns = useMemo<ColumnItem[]>(
    () => [
      {
        label: t('materials:attributes.name'),
        key: 'name',
      },
      (material: Material | undefined) => ({
        label: t('materials:attributes.categories'),
        key: 'categories',
        formatValue: (_: any) => {
          return !material
            ? ''
            : `
            ${t(`categories:name.${material.primaryCategory.name}` as any)} /
            ${t(`categories:name.${material.secondaryCategory.name}` as any)} /
            ${t(`categories:name.${material.tertiaryCategory.name}` as any)}
          `
        },
      }),
      (material: Material | undefined) => ({
        label: t('materials:attributes.organization'),
        key: 'organization',
        formatValue: (_: any) => `${(material?.catalog as Catalog)?.organization?.name || ''}`,
      }),
      {
        label: t('materials:attributes.catalog'),
        key: 'catalog',
        formatValue: (catalog: Catalog) => `${catalog?.name || ''}`,
      },
      ...(type === MaterialType.need
        ? [
            (material: Material | undefined) => ({
              label: t('materials:attributes.desiredQty'),
              key: 'initialQty',
              formatValue: (quantity: number) =>
                quantity >= 0 && material?.unit
                  ? `${StringUtils.formatNumber(quantity)} ${t(
                      `materials:unitSymbol.${material.unit}`,
                    )}`
                  : '',
            }),
          ]
        : [
            (material: Material | undefined) => ({
              label: t('materials:attributes.quantities.current'),
              key: 'currentQty',
              formatValue: (quantity: number) =>
                quantity >= 0 && material?.unit
                  ? `${StringUtils.formatNumber(quantity)} ${t(
                      `materials:unitSymbol.${material.unit}`,
                    )}`
                  : '',
            }),
            (material: Material | undefined) => ({
              label: t('materials:attributes.price'),
              key: 'price',
              formatValue: (_: number) => {
                return material?.visible === false
                  ? t('materials:termsOfSale.invisible')
                  : material?.termsOfSale === TermsOfSale.sale
                  ? t('materials:details.price', {
                      price: material.price,
                      currency: t(`global:currency.${material.currency}`),
                      unit: t(`materials:unitSymbol.${material.unit}`),
                    })
                  : material?.termsOfSale === TermsOfSale.donation
                  ? t('materials:details.donation')
                  : t('materials:details.priceNotDefined')
              },
            }),
          ]),
      (material: Material | undefined) => ({
        label:
          type === MaterialType.need
            ? t('materials:attributes.needsEndDate')
            : t('materials:attributes.retrieval.startDate'),
        key: 'date',
        formatValue: (_: number) =>
          type === MaterialType.need
            ? t('global:format.date', {
                date: new Date(material?.retrieval?.endDate || 'invalid date'),
              })
            : material?.visible === false
            ? ''
            : `${t('global:format.date', {
                date: new Date(material?.retrieval?.startDate || 'invalid date'),
              })} - ${t('global:format.date', {
                date: new Date(material?.retrieval?.endDate || 'invalid date'),
              })}`,
      }),
    ],
    [t, type],
  )
  const card = useCallback(
    (props: any) => (
      <MaterialCard
        {...props}
        isCatalogPage={isCatalogPage}
        isAdmin={isAdmin}
        isPublic={isPublic}
        addToCart={addToCart?.bind(null, props.value)}
      />
    ),
    [isAdmin, isPublic, isCatalogPage, addToCart],
  )

  return (
    <>
      <List
        {...listProps}
        ref={ref}
        columns={isAdmin && !isCatalogPage ? columns : undefined}
        card={card}
      />
    </>
  )
})
export default MaterialList
