import React, {useState, useMemo} from "react";
import {useTranslation} from "react-i18next";
import ModalForm, {ModalFormProps} from "../common/ModalForm.common";
import {FormItem, ItemType} from "../../models/props.models";

interface ModalDenyProps extends Omit<ModalFormProps, "value" | "items" | "steps" | "setValue" | "title" | "submitLabel"> {}
const ModalHoursDedicatedToReuse: React.FC<ModalDenyProps> = (props) => {
  const {t} = useTranslation();
  const [value, setValue] = useState<{hoursDedicatedToReuse: number}>({hoursDedicatedToReuse: 0});

  const items: FormItem[] = useMemo(
    () => [
      {
        type: ItemType.number,
        key: "hoursDedicatedToReuse",
        required: true,
        props: {
          label: t("reports:messages.hoursDedicatedToReuse"),
          placeholder: t("reports:messages.hoursDedicatedToReuse"),
        },
      },
    ],
    [t]
  );
  return (
    <ModalForm
      {...props}
      value={value}
      setValue={setValue}
      items={items}
      title={t("reports:messages.hoursDedicatedToReuseConfirmTitle")}
      submitLabel={t("global:actions.confirm")}
    />
  );
};

export default ModalHoursDedicatedToReuse;
