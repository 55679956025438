import { createStore, withProps } from '@ngneat/elf'
import { ApiProduct } from '../../models/orders.models'
import { withRequestsStatus } from '@ngneat/elf-requests'

export const cartsStore = createStore(
  { name: 'cart' },
  withProps<{ cart: ApiProduct[] }>({
    cart: [],
  }),
  withRequestsStatus<'init'>(),
)
