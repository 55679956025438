import {
  OrganizationsPagination,
  Organization,
  ManageOrganization,
} from '../../models/organizations.models'
import { FileDetails } from '../../models/files.models'
import { SelectOption } from '../../models/props.models'
import { Mode } from '../../models/commons.models'
import { NetworkUtils } from '../../utils/networks.utils'
import { logIfDev } from '../../utils/commons.utils'
import { FileUtils } from '../../utils/files.utils'
import { FilesApi } from '../../api/files.api'
import { OrganizationsApi } from '../../api/organizations.api'
import Constants from '../../constants'
import { sessionService } from '../session'

export class OrganizationsService {
  getOrganizations = async (
    filters: OrganizationsApi.GetListParams,
  ): Promise<OrganizationsPagination> => {
    await NetworkUtils.checkConnected()
    return await OrganizationsApi.getList({
      ...filters,
      ...(Constants.mode === Mode.admin && { platform: sessionService.getAdminPlatform() }),
    })
  }

  getCsv = async (filters: OrganizationsApi.GetListParams): Promise<void> => {
    await NetworkUtils.checkConnected()
    const data = await OrganizationsApi.getCsv({
      ...filters,
      ...(Constants.mode === Mode.admin && { platform: sessionService.getAdminPlatform() }),
    })
    await FileUtils.downloadFile(`organisme.csv`, data)
  }

  getOrganizationOptions = async (search?: string): Promise<SelectOption[]> => {
    try {
      await NetworkUtils.checkConnected()

      const organizations = await this.getOrganizations({
        search,
      })

      return organizations.data.map((e) => ({ label: e.name, value: e._id, data: e }))
    } catch (err: any) {
      logIfDev(err)
      return []
    }
  }

  getOrganizationBySiret = async (siret: string): Promise<Organization | null> => {
    try {
      await NetworkUtils.checkConnected()
      return await OrganizationsApi.getBySiret(
        siret,
        Constants.mode === Mode.admin ? sessionService.getAdminPlatform() : undefined,
      )
    } catch (err: any) {
      logIfDev(err)
      return null
    }
  }

  createOrganization = async (organization: ManageOrganization): Promise<Organization> => {
    await NetworkUtils.checkConnected()

    let kbis = await FilesApi.create(organization.kbis as File)
    return OrganizationsApi.create({
      ...organization,
      platform:
        Constants.mode === Mode.admin ? sessionService.getAdminPlatform() : Constants.platform,
      kbis: kbis?._id || undefined,
    })
  }

  getOrganizationById = async (organizationId: string): Promise<Organization> => {
    await NetworkUtils.checkConnected()
    return await OrganizationsApi.getById(organizationId)
  }

  downloadKbis = async (file: FileDetails): Promise<void> => {
    await NetworkUtils.checkConnected()
    const response = await fetch(file.path)
    const data = await response.blob()
    await FileUtils.downloadFile(file.path.split(/\\|\//).pop() as string, data)
  }

  updateOrganization = async (
    organizationId: string,
    organization: ManageOrganization,
  ): Promise<Organization> => {
    await NetworkUtils.checkConnected()
    let newKbis
    if (!(organization.kbis as FileDetails)?._id) {
      newKbis = await FilesApi.create(organization.kbis as File)
    }
    return await OrganizationsApi.update(organizationId, {
      ...organization,
      kbis: newKbis?._id || (organization.kbis as FileDetails)?._id,
    })
  }

  deleteOrganization = async (organizationId: string): Promise<void> => {
    await NetworkUtils.checkConnected()
    return await OrganizationsApi.deleteById(organizationId)
  }
}

export const organizationsService = new OrganizationsService()
