import { Capacitor } from '@capacitor/core'
import { FileOpener } from '@capacitor-community/file-opener'
import { Filesystem, Directory, Encoding } from '@capacitor/filesystem'
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera'
import { Device } from '@capacitor/device'
import Constants from '../constants'
import I18nUtils from './i18n.utils'
import { FileDetails } from '../models/files.models'
import { logIfDev } from './commons.utils'

export namespace FileUtils {
  const downloadDirectory = Directory.Documents
  const dataDirectory = Directory.Library

  let _fileDirectory: Directory | undefined = undefined
  const getFileDirectory = async () => {
    if (_fileDirectory) {
      return _fileDirectory
    }
    const info = await Device.getInfo()
    _fileDirectory =
      info.androidSDKVersion && info.androidSDKVersion < 30
        ? Directory.ExternalStorage
        : Directory.Documents
    return _fileDirectory
  }

  export const takePicture = async (): Promise<File | undefined> => {
    const photo = await Camera.getPhoto({
      resultType: CameraResultType.Base64,
      source: CameraSource.Prompt,
      quality: 50,
      promptLabelHeader: I18nUtils.t('global:cameraPrompt.title'),
      promptLabelCancel: I18nUtils.t('global:cameraPrompt.cancel'),
      promptLabelPhoto: I18nUtils.t('global:cameraPrompt.file'),
      promptLabelPicture: I18nUtils.t('global:cameraPrompt.picture'),
    })
    if (photo && photo.base64String) {
      const byteString = window.atob(photo.base64String)
      const arrayBuffer = new ArrayBuffer(byteString.length)
      const int8Array = new Uint8Array(arrayBuffer)
      for (let i = 0; i < byteString.length; i++) {
        int8Array[i] = byteString.charCodeAt(i)
      }

      let type = `image/${photo.format}`
      return new File(
        [new Blob([int8Array], { type })],
        `'photo.${new Date().getTime()}.${photo.format}`,
        {
          type,
        },
      )
    }
    return
  }
  const tryCreateFileDirectory = async (directory: Directory, filename: string) => {
    const directories = filename.split('/')
    let directoryPath = ''
    for (let i = 0; i < directories.length - 1; i++) {
      const { files } = await Filesystem.readdir({
        path: directoryPath,
        directory,
      })
      if (!files.find((file) => file.name === directories[i])) {
        try {
          await Filesystem.mkdir({
            path: `${directoryPath}/${directories[i]}`,
            directory,
          })
        } catch (err) {}
      }
      directoryPath += `/${directories[i]}`
    }
  }
  export const renameFile = async (from: string, to: string): Promise<void> => {
    await tryCreateFileDirectory(await getFileDirectory(), to)

    await Filesystem.rename({
      from,
      to,
      directory: await getFileDirectory(),
      toDirectory: await getFileDirectory(),
    })
  }
  export const deleteFile = async (path: string): Promise<void> => {
    await Filesystem.deleteFile({
      path,
      directory: await getFileDirectory(),
    })
  }
  export const deleteFilesFolder = async (path: string): Promise<void> => {
    await Filesystem.rmdir({
      path,
      directory: await getFileDirectory(),
      recursive: true,
    })
  }
  export const saveFile = async (blob: Blob, filename: string): Promise<void> => {
    await tryCreateFileDirectory(await getFileDirectory(), filename)
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.onerror = reject
      reader.onload = async () => {
        if (reader.result) {
          await Filesystem.writeFile({
            path: filename,
            data: (reader.result as string).split(';base64,')[1],
            directory: await getFileDirectory(),
          })
          resolve()
        }
        reject(new Error('no data'))
      }

      reader.readAsDataURL(blob)
    })
  }
  export const saveLocalFile = async (
    file: File,
    filename: string,
    addIdInName = true,
  ): Promise<FileDetails> => {
    return new Promise((resolve, reject) => {
      const _id = `local.${Math.random().toString().split('.')[1]}`
      const originalSplit = file.name.split(/\./)
      const fileWithExt = `${filename}${addIdInName ? `.${_id}` : ''}.${
        originalSplit[originalSplit.length - 1]
      }`
      const reader = new FileReader()
      reader.onerror = reject
      reader.onload = async () => {
        await tryCreateFileDirectory(await getFileDirectory(), fileWithExt)

        if (typeof reader.result === 'string') {
          await Filesystem.writeFile({
            path: fileWithExt,
            data: (reader.result as string).split(';base64,')[1],
            directory: await getFileDirectory(),
          })

          resolve({
            _id,
            path: fileWithExt,
            mimeType: file.type,
          })
        } else {
          reject(new Error('method did not return a string'))
        }
      }
      reader.readAsDataURL(file)
    })
  }
  export const getUsersDir = async () => {
    const { files } = await Filesystem.readdir({
      path: '',
      directory: dataDirectory,
    })
    return files
      .filter((file: any) => file.type === 'directory' && !!file.name)
      .map((file: any) => file.name)
  }
  export const readJSON = async (filename: string) => {
    const contents = await Filesystem.readFile({
      path: `${filename}.json`,
      encoding: Encoding.UTF8,
      directory: dataDirectory,
    })
    return JSON.parse(contents.data as string)
  }
  export const writeJSON = async (filename: string, data: any) => {
    await tryCreateFileDirectory(dataDirectory, filename)

    await Filesystem.writeFile({
      path: `${filename}.json`,
      data: JSON.stringify(data),
      encoding: Encoding.UTF8,
      directory: dataDirectory,
    })
  }
  export const deleteJSON = async (filename: string) => {
    await Filesystem.deleteFile({
      path: `${filename}.json`,
      directory: dataDirectory,
    })
  }
  export const importFile = async (
    fileDetails: FileDetails,
    filename: string,
    orignial = false,
  ): Promise<FileDetails> => {
    let path = orignial && fileDetails.originalPath ? fileDetails.originalPath : fileDetails.path
    let split = path.split(/\./g)
    let name = `${filename}.${split[split.length - 1]}`

    try {
      const response = await fetch(path)
      const file = await response.blob()
      await saveFile(file, name)
    } catch (error) {
      logIfDev(error)
    }

    return {
      ...fileDetails,
      _id: `sync.${fileDetails._id}`,
      path: name,
      originalPath: fileDetails.originalPath,
    }
  }
  export const readFile = async (file: FileDetails): Promise<Blob> => {
    const readFile = await Filesystem.readFile({
      path: file.path || '',
      directory: await getFileDirectory(),
    })
    const byteString = window.atob(readFile.data as string)
    const arrayBuffer = new ArrayBuffer(byteString.length)
    const int8Array = new Uint8Array(arrayBuffer)
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i)
    }
    return new Blob([int8Array], { type: file.mimeType })
  }
  export const downloadFile = (filename: string, data: Blob): Promise<void> | void => {
    if (!Constants.getIsLocal() || !Capacitor.isNativePlatform()) {
      const url = window.URL.createObjectURL(data)
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', filename)
      link.click()
    } else {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onerror = reject
        reader.onload = async () => {
          if (reader.result) {
            await Filesystem.writeFile({
              path: filename,
              data: (reader.result as string).split(';base64,')[1],
              directory: downloadDirectory,
            })
            const { uri } = await Filesystem.getUri({
              path: filename,
              directory: downloadDirectory,
            })
            FileOpener.open({
              filePath: uri,
            })

            resolve()
          }
          reject(new Error('no data'))
        }

        reader.readAsDataURL(data)
      })
    }
  }
  export const openFile = async (file: FileDetails) => {
    if (file.path) {
      if (!Constants.getIsLocal()) {
        window.open(file.originalPath || file.path, '_blank')
      } else {
        if (file.originalPath) {
          if (Capacitor.isNativePlatform()) {
            let uri
            try {
              // file already downlad
              ;({ uri } = await Filesystem.getUri({
                path: file.path,
                directory: await getFileDirectory(),
              }))
            } catch (err) {
              //downlad
              const response = await fetch(file.originalPath)
              const originalFile = await response.blob()
              const orignalSplit = file.originalPath.split('/')
              const originalPath = `original.${orignalSplit[orignalSplit.length - 1]}`
              await saveFile(originalFile, originalPath)
              ;({ uri } = await Filesystem.getUri({
                path: file.path,
                directory: await getFileDirectory(),
              }))
            }

            FileOpener.open({
              filePath: uri,
            })
          } else {
            window.open(file.originalPath, '_blank')
          }
        }

        if (Capacitor.isNativePlatform()) {
          const { uri } = await Filesystem.getUri({
            path: file.path,
            directory: await getFileDirectory(),
          })
          FileOpener.open({
            filePath: uri,
          })
        } else {
          const blob = await readFile(file)
          window.open(URL.createObjectURL(blob), '_blank')
        }
      }
    }
  }
  export const loadImage = async (image: File | FileDetails, original = false): Promise<string> => {
    if (!Constants.getIsLocal() && original && (image as FileDetails).originalPath) {
      // for ios& android, you must call importFile with orginal=true to directly download original image (use to get catalog.plans.file)
      return (image as FileDetails).originalPath as string
    }

    if ((image as FileDetails).src) {
      return (image as FileDetails).src as string
    }

    if (image.hasOwnProperty('_id')) {
      let fileDetails = image as FileDetails
      if (
        Constants.getIsLocal() &&
        fileDetails.path &&
        (fileDetails._id.includes('sync') || fileDetails._id.includes('local'))
      ) {
        if (Capacitor.isNativePlatform()) {
          let { uri } = await Filesystem.getUri({
            path: fileDetails.path,
            directory: await getFileDirectory(),
          })
          return Capacitor.convertFileSrc(uri)
        } else {
          let readFile = await Filesystem.readFile({
            path: fileDetails.path,
            directory: await getFileDirectory(),
          })
          return `data:${fileDetails.mimeType};base64,${readFile.data}`
        }
      }

      return fileDetails.path
    }
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.onerror = reject
      reader.onload = () => {
        if (typeof reader.result === 'string') {
          resolve(reader.result)
        } else {
          reject('method did not return a string')
        }
      }
      reader.readAsDataURL(image as File)
    })
  }
  export const replaceIds = async (fileDetail: FileDetails, fromIds: string[], toIds: string[]) => {
    if (fileDetail.path) {
      let newUrl = fileDetail.path
      for (let i = 0; i < fromIds.length; i++) {
        newUrl = newUrl.replace(fromIds[i], toIds[i])
      }
      if (newUrl !== fileDetail.path) {
        await renameFile(fileDetail.path, newUrl)
        fileDetail.path = newUrl
      }
    }
  }
  export const nameToPath = (name: string) => {
    return name.replace(/[^a-zA-Z0-9]/g, '')
  }
}
