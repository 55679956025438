import { materialsStore } from './materials.store'
import { select } from '@ngneat/elf'
import { selectIsRequestPending } from '@ngneat/elf-requests'

export class MaterialsQuery {
  store = materialsStore

  loading = this.store.pipe(selectIsRequestPending('init'))
  materials = this.store.pipe(select((state) => state.list))

  getMaterials = () => {
    return this.store.getValue().list
  }
}

export const materialsQuery = new MaterialsQuery()
