import TagManager from 'react-gtm-module'
try {
  if (process.env.REACT_APP_GTM_ID) {
    const tagManagerArgs = {
      gtmId: process.env.REACT_APP_GTM_ID || '',
      events: {
        downloadCertificate: 'Download certificate',
      },
    }

    TagManager.initialize(tagManagerArgs)
  }
} catch (e) {
  console.log('GTM error', e)
}
// https://github.com/alinemorelli/react-gtm/issues/28
export const sendEvent = (eventName: string, ...args: string[]) => {
  try {
    if (process.env.REACT_APP_GTM_ID) {
      TagManager.dataLayer({
        dataLayer: {
          event: eventName,
          ...args,
        },
      })
    }
  } catch (e) {
    console.log('GTM sendEvent error', e)
  }
}
