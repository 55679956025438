import { Grid, useMediaQuery } from '@mui/material'
import { useTheme } from '@mui/material/styles/index.js'

import Stack from '../common/Stack.common'

type DoubleProps = {
  first: JSX.Element
  second: JSX.Element
  reverse?: boolean
}
const Double: React.FC<DoubleProps> = ({ first, second, reverse }) => {
  const theme = useTheme()
  const onlyXs = useMediaQuery(theme.breakpoints.only('xs'))
  const downMd = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <Stack flex={1} px={onlyXs ? '0' : '15px'} py="15px" className="scrollable">
      <Grid container spacing={3} justifyContent="center">
        <Grid item xs={11} sm={11} md={6}>
          {first}
        </Grid>
        <Grid item xs={11} sm={11} md={6} order={downMd && reverse ? -1 : 1}>
          {second}
        </Grid>
      </Grid>
    </Stack>
  )
}
export default Double
