import { Catalog, isNeeds } from '../../../models/catalogs.models'
import { MaterialType } from '../../../models/materials.models'
import { useOutletContext } from 'react-router-dom'
import PageMaterialList from '../../../components/page/MaterialList.page'
import useSnackbar from '../../../hooks/useSnackbar.hooks'
import { useObservable } from '@ngneat/react-rxjs'
import { materialsService } from '../../../store/materials'
import { sessionQuery } from '../../../store/session'
import { useEffect } from 'react'

const PublicMaterialList = () => {
  const { catalog, isPublic } = useOutletContext<{
    catalog: Catalog
    isPublic: boolean
  }>()

  const show = useSnackbar()
  const [user] = useObservable(sessionQuery.user)

  useEffect(() => {
    const getMaterials = async () => {
      try {
        await materialsService.init(
          catalog._id,
          isNeeds(catalog.type) ? MaterialType.need : MaterialType.resource,
          isPublic,
          undefined,
        )
      } catch (err: any) {
        show(err)
      }
    }
    // even with public needs, they are not loaded whenunlooged
    if (user && isPublic && isNeeds(catalog.type)) {
      getMaterials()
    }
  }, [user, show, catalog, isPublic])

  return (
    <PageMaterialList
      isPublic
      type={isNeeds(catalog.type) ? MaterialType.need : MaterialType.resource}
      catalog={catalog}
    />
  )
}
export default PublicMaterialList
