import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CatalogType, Plan, Catalog, ManageCatalog } from '../../models/catalogs.models'
import { ItemType, DetailItem } from '../../models/props.models'
import { FileDetails } from '../../models/files.models'
import DetailsComponent from '../common/Details.common'
import { Delete as DeleteIcon } from '@mui/icons-material'
import MapComponent from '../common/Map.common'
import Details from '../common/Details.common'
import { Box, Typography } from '@mui/material'

interface InformationDocumentCatalogProps {
  catalog: Catalog
  canUpdate?: boolean
  isPublic?: boolean
  onUpdate?: (update: ManageCatalog) => void
}
const InformationDocumentCatalog: React.FC<InformationDocumentCatalogProps> = ({
  catalog,
  canUpdate,
  isPublic,
  onUpdate,
}) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0)
  const pictures = [catalog.mainImageFile, ...catalog.imageFiles]
  const { t } = useTranslation()

  const mainImageItems: DetailItem[] = [
    {
      type: ItemType.image,
      key: 'mainImageFileFile',
      props: {
        fileDetails: isPublic ? pictures[currentImageIndex] : catalog.mainImageFile,
        readOnly: !canUpdate,
        maxFileSize: 2.5,
        onChange: (file: File) => onUpdate?.({ mainImageFileFile: file }),
        next:
          isPublic && pictures.length > 1
            ? () => {
                setCurrentImageIndex((state) => (state < pictures.length - 1 ? state + 1 : 0))
              }
            : undefined,
        previous:
          isPublic && pictures.length > 1
            ? () => {
                setCurrentImageIndex((state) => (state > 0 ? state - 1 : pictures.length - 1))
              }
            : undefined,
      },
    },
  ]

  const documentsItems: DetailItem[] = [
    ...(catalog.type === CatalogType.needs || catalog.type === CatalogType.storage
      ? []
      : catalog.type === CatalogType.construction
      ? [
          {
            type: ItemType.documents,
            key: 'plans',
            props: {
              label: t('catalogs:attributes.plans.title'),
              type: 'plan',
              documents: catalog.plans ?? [],
              readOnly: !canUpdate,
              hideValues: true,
              maxFileSize: 2.5,
              onSelectFiles: (files: File[]) =>
                onUpdate?.({
                  plans: [
                    ...files.map((file) => ({ file, name: file.name, scale: 1, angle: 0 })),
                    ...catalog.plans,
                  ],
                }),
              actionIcon: <DeleteIcon />,
              onActionDocument: (documentId: string) =>
                onUpdate?.({
                  plans: catalog.plans.filter((plan: Plan) => plan.file._id !== documentId),
                }),
            },
          },
        ]
      : [
          {
            type: ItemType.documents,
            key: 'diagnosticFilesFile',
            props: {
              label: t('catalogs:attributes.diagnostics'),
              type: 'file',
              documents: catalog.diagnosticFiles ?? [],
              readOnly: !canUpdate,
              maxFileSize: 2.5,
              onSelectFiles: (documents: File[]) =>
                onUpdate?.({
                  diagnosticFilesFile: documents,
                  diagnosticFiles: catalog.diagnosticFiles,
                }),
              actionIcon: <DeleteIcon />,
              onActionDocument: (documentId: string) =>
                onUpdate?.({
                  diagnosticFiles: catalog.diagnosticFiles.filter(
                    (diagnosticFile: FileDetails) => diagnosticFile._id !== documentId,
                  ),
                }),
            },
          },
        ]),
    {
      type: ItemType.documents,
      key: 'imageFilesFile',
      props: {
        label: t('catalogs:attributes.images'),
        type: 'image',
        documents: catalog.imageFiles ?? [],
        readOnly: !canUpdate,
        maxFileSize: 2.5,
        onSelectFiles: (documents) =>
          onUpdate?.({
            imageFilesFile: documents,
            imageFiles: catalog.imageFiles,
          }),
        actionIcon: <DeleteIcon />,
        onActionDocument: (documentId: string) =>
          onUpdate?.({
            imageFiles: catalog.imageFiles.filter(
              (imageFile: FileDetails) => imageFile._id !== documentId,
            ),
          }),
      },
    },
    {
      type: ItemType.documents,
      key: 'otherFilesFile',
      props: {
        label: t('catalogs:attributes.otherFiles'),
        type: 'file',
        documents: catalog.otherFiles ?? [],
        readOnly: !canUpdate,
        maxFileSize: 2.5,
        onSelectFiles: (documents) =>
          onUpdate?.({
            otherFilesFile: documents,
            otherFiles: catalog.otherFiles,
          }),
        actionIcon: <DeleteIcon />,
        onActionDocument: (documentId: string) =>
          onUpdate?.({
            otherFiles: catalog.otherFiles.filter(
              (otherFile: FileDetails) => otherFile._id !== documentId,
            ),
          }),
      },
    },
  ]
  return (
    <>
      <DetailsComponent
        items={mainImageItems}
        value={catalog}
        title={t('catalogs:attributes.mainImage')}
      />
      {!isPublic && (
        <Box mt="15px">
          <DetailsComponent
            title={t('catalogs:components.informationDocument.documents')}
            value={catalog}
            items={documentsItems}
          />
        </Box>
      )}
      <Box mt="15px">
        <Details title={t('catalogs:components.informationDocument.map')}>
          <Box width="100%">
            <Typography mb="5px" color="primary" textAlign="start">
              {isPublic ? catalog.retrieval.location.city : catalog.location.city}
              {isPublic && catalog.retrieval.location.postalCode
                ? `, ${catalog.retrieval.location.postalCode}`
                : ''}
              {!isPublic && catalog.location.postalCode ? `, ${catalog.location.postalCode}` : ''}
            </Typography>
            <MapComponent
              height="300px"
              disableControls
              hideAutocomplete
              zoom={11}
              center={isPublic ? catalog.retrieval.location.position : catalog.location.position}
              markers={[
                {
                  id: catalog._id,
                  lat: isPublic
                    ? catalog.retrieval.location.position[0]
                    : catalog.location.position[0],
                  lng: isPublic
                    ? catalog.retrieval.location.position[1]
                    : catalog.location.position[1],
                },
              ]}
            />
          </Box>
        </Details>
      </Box>
    </>
  )
}
export default InformationDocumentCatalog
