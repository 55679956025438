import { alpha } from '@mui/material'

export const createCard = (colors: any) => ({
  MuiCard: {
    root: {
      backgroundColor: colors.white,
      border: `1px solid ${colors.lightBorder}`,
      borderRadius: '4px',
      boxShadow: `0 2px 20px 0 ${alpha(colors.black, 0.05)}`,
      '&[aria-label=product-card]': {
        alignItems: 'center',
        display: 'flex',
        position: 'relative',
        flexDirection: 'column',
        width: '500px',
        minWidth: '100%',
        // height: '220px',
        '.card-image': {
          height: '0',
        },
      },
      '&[aria-label=material-model-card]': {
        alignItems: 'center',
        display: 'flex',
        position: 'relative',
        flexDirection: 'column',
        width: '400px',
        // height: '220px',
        '.card-image': {
          height: '0',
        },
      },
      '&[aria-label=order-card]': {
        alignItems: 'center',
        display: 'flex',
        position: 'relative',
        flexDirection: 'column',
        width: '400px',
        // height: '220px',
        '.card-image': {
          height: '0',
        },
      },
      '&[aria-label=user-card]': {
        alignItems: 'center',
        display: 'flex',
        position: 'relative',
        flexDirection: 'column',
        width: '170px',
        height: '220px',
        '.card-image': {
          height: '70px',
        },
      },
      '&[aria-label=organization-card]': {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        padding: '16px 5px',
        width: '170px',
        height: '200px',
        '.card-image': {
          height: '70px',
        },
      },
      '&[aria-label=plan-card]': {
        alignItems: 'flex-start',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        width: '300px',
      },
      '&[aria-label=catalog-card]': {
        alignItems: 'flex-start',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        width: '300px',
      },
      '&[aria-label=resource-card]': {
        alignItems: 'flex-start',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        position: 'relative',
        width: '305px',
      },
      '&[aria-label=buy-resource-card]': {
        alignItems: 'flex-start',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        position: 'relative',
        width: '305px',
      },
      '&[aria-label=need-card]': {
        alignItems: 'flex-start',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        position: 'relative',
        width: '305px',
      },
      '&[aria-label=file-card]': {
        height: '110px',
        width: '110px',
      },
      '&[aria-label=select-picture-card]': {
        borderRadius: '4px',
        height: '300px',
        width: '100%',
      },
    },
  },
})
