import { DEFAULT_COLORS } from '../constants/colors.constants'
import { Mode } from '../models/commons.models'

// http://admin:3000/
const backofficeConfig = {
  mode: Mode.admin,
  theme: {
    overrides: {
      MuiToolbar: {
        root: {
          color: '#ffffff',
          backgroundColor: '#000000',
          '& .MuiTablePagination-selectIcon': {
            color: DEFAULT_COLORS.primary,
          },
          '& .MuiTablePagination-actions .MuiButtonBase-root': {
            color: DEFAULT_COLORS.primary,
            '&.Mui-disabled': {
              color: DEFAULT_COLORS.white,
            },
          },
        },
      },
      MuiTypography: {
        h1: { fontSize: '1rem', fontWeight: 600 },
      },
    },
  },
  langs: {
    fr: {
      global: {
        routes: {
          requests: 'Demandes',
          workspace: 'Projets',
        },
      },
    },
  },
}

export default backofficeConfig
