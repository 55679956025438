import { Discount, DiscountType, Product, computeProductPrice } from '../../models/orders.models'
import { ItemType, Actions, DetailItem } from '../../models/props.models'
import { TermsOfSale } from '../../models/materials.models'
import Card from '../common/Card.common'
import DetailsComponent from '../common/Details.common'
import { useTranslation } from 'react-i18next'
import { Box, styled, Typography, IconButton } from '@mui/material'
import {
  Edit as EditIcon,
  Add as AddIcon,
  Info as InfoIcon,
  Delete as DeleteIcon,
} from '@mui/icons-material'
import Stack from '../common/Stack.common'
import { createOptionsFromEnum } from '../../utils/i18n.utils'
import { useMemo } from 'react'

const ProductTitle = styled(Typography)({
  fontSize: '.875rem',
  fontWeight: 600,
  marginBottom: '4px',
})
const ProductSubtitle = styled(Typography)({
  fontSize: '.6875rem',
  fontWeight: 300,
  letterSpacing: -0.14,
})
const EndAdornmentLabel = styled(Typography)({ fontSize: '0.875rem', fontWeight: 500 })

const TooltipContent = styled(Box)({
  fontSize: '.75rem',
})
const OneLineTypography = styled(Typography)({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
})
const RowDetailStyled = styled(Stack)({
  borderBottom: 'solid 1px #DFDFDF',
  padding: '14px 0',
  width: '100%',
  marginRight: 'auto',
})
const RowsTitle = styled(OneLineTypography)({ fontSize: '.75rem', fontWeight: 700 })
const RowDetail = (props: any) => (
  <RowDetailStyled
    {...props}
    direction="row"
    alignItems="center"
    justifyContent="space-between"
    spacing={1.5}
  />
)
const RowTextLeft = styled(Typography)(({ theme }) => ({
  fontSize: '0.75rem',
  fontWeight: 400,
  color: theme.palette.blockLabel,
}))
const RowTextRight = styled(Typography)({
  fontSize: '0.875rem',
  fontWeight: 400,
  textAlign: 'right',
})
const RowDetailNoBorder = styled(Stack)({
  padding: '14px 0',
  width: '100%',
})
const RowTextSeveralLines = styled(Typography)({ fontSize: '0.875rem', fontWeight: 400 })
const StyledInfoIcon = styled(InfoIcon)(({ theme }) => ({ color: theme.palette.primary.main }))

interface ProductCardProps {
  value: Product
  onClick?: () => void
  actions?: Actions
  onSelect?: () => void
  selected?: boolean
  onProductChange?: (product: Product) => void
  onEditDiscount?: (materialId: string) => void
  canUpdate?: boolean
  finishStep?: boolean
  acceptClientStep?: boolean
}
const ProductCard = ({
  value,
  onClick,
  actions,
  selected,
  onSelect,
  onProductChange,
  onEditDiscount,
  canUpdate,
  finishStep,
  acceptClientStep,
}: ProductCardProps): JSX.Element => {
  const { t } = useTranslation()

  const minQuantity = useMemo(
    () =>
      finishStep || acceptClientStep
        ? 0
        : value?.material.minQuantity || value?.material.sellByQuantityOf || 1,
    [acceptClientStep, finishStep, value?.material.minQuantity, value?.material.sellByQuantityOf],
  )
  const termsOfSale = useMemo(
    () => value.termsOfSale ?? value.material.termsOfSale,
    [value?.termsOfSale, value?.material.termsOfSale],
  )
  const unitPrice = useMemo(
    () =>
      (value.termsOfSale === TermsOfSale.sale
        ? value.price
        : !value.termsOfSale && value.material.termsOfSale === TermsOfSale.sale
        ? value.material.price
        : 0) ?? 0,
    [value?.termsOfSale, value?.material.termsOfSale, value?.price, value?.material.price],
  )

  const fromDefault = useMemo(
    () => value.material?.retrieval?.fromDefault !== false,
    [value.material?.retrieval?.fromDefault],
  )
  const hasRetrieval = useMemo(
    () => !!value.material.conditioning || !!value.material.state || !fromDefault,
    [value.material.conditioning, value.material.state, fromDefault],
  )
  return (
    <Card
      ariaLabel="product-card"
      actions={actions}
      onClick={onClick}
      onSelect={onSelect}
      selected={selected}>
      <DetailsComponent
        noElevation
        title={
          <Box display="flex" flexDirection="row">
            <img
              src={value.material.mainImageFile?.src || value.material.mainImageFile?.path}
              alt="artcile"
              width="42px"
              height="42px"
              style={{ objectFit: 'cover' }}
            />
            <Box display="flex" flexDirection="column" marginLeft="5px">
              <ProductTitle>{value.material.name}</ProductTitle>
              <ProductSubtitle>{value.material.reference}</ProductSubtitle>
            </Box>
            {canUpdate && (
              <Box marginLeft="auto">
                <IconButton
                  onClick={(e: any) => {
                    e.stopPropagation()
                    onProductChange?.({
                      ...value,
                      quantity: 0,
                    })
                  }}>
                  <DeleteIcon color="primary" />
                </IconButton>
              </Box>
            )}
          </Box>
        }
        value={value}
        items={[
          ...((canUpdate
            ? [
                {
                  label: t('orders:attributes.product.quantity'),
                  type: ItemType.number,
                  key: 'quantity',
                  error: value
                    ? value.quantity < minQuantity
                      ? t('orders:components.tableProducts.tooFew')
                      : value.quantity > value.material.currentQty
                      ? t('orders:components.tableProducts.tooMuch')
                      : null
                    : null,
                  props: {
                    maxWidth: '250px',
                    onClick: (e: any) => {
                      e.stopPropagation()
                    },
                    step: value.material.sellByQuantityOf,
                    min: minQuantity,
                    max: value.material.currentQty,
                    onChange: (q?: number) =>
                      onProductChange?.({
                        ...value,
                        quantity: q ?? 0,
                      }),
                    showControl: true,
                    endAdornment: (
                      <EndAdornmentLabel>
                        {t(`materials:unitSymbol.${value.material.unit}`)}
                      </EndAdornmentLabel>
                    ),
                  },
                },
              ]
            : [
                {
                  label: t('orders:attributes.product.quantity'),
                  key: 'quantity',
                  formatValue: (quantity: number) =>
                    quantity
                      ? `${quantity}${t(`materials:unitSymbol.${value.material.unit}`)}`
                      : '-',
                },
              ]) as DetailItem[]),
          ...(!finishStep
            ? [
                {
                  label: t('orders:attributes.product.unitWeight'),
                  key: 'unitWeight',
                  formatValue: () => {
                    if (!value.material.unitWeight) {
                      return '-'
                    }
                    return +value.material.unitWeight < 1000
                      ? t('materials:attributes.weight.kg', { value: value.material.unitWeight })
                      : t('materials:attributes.weight.tonne', {
                          value: (+value.material.unitWeight / 1000).toFixed(2),
                        })
                  },
                },
              ]
            : []),
          ...((canUpdate && finishStep
            ? [
                {
                  label: t('materials:attributes.sell.termsOfSale'),
                  type: ItemType.select,
                  key: 'termsOfSale',
                  error:
                    value?.termsOfSale === TermsOfSale.notDefined ? t('errors:required') : null,
                  props: {
                    placeholder: t('global:inputs.selectPlaceholder'),
                    items: createOptionsFromEnum(TermsOfSale, 'materials:termsOfSale'),
                    onChange: (termsOfSale?: TermsOfSale) =>
                      onProductChange?.({
                        ...value,
                        termsOfSale,
                      }),
                  },
                },
                ...(termsOfSale === TermsOfSale.sale
                  ? [
                      {
                        label: t('orders:attributes.product.unitPrice'),
                        key: 'unitPrice',
                        type: ItemType.number,
                        formatValue: () => {
                          return unitPrice
                        },
                        error: unitPrice <= 0 ? t('errors:positive') : undefined,
                        props: {
                          onClick: (e: any) => {
                            e.stopPropagation()
                          },
                          min: 0,
                          onChange: (p?: number) =>
                            onProductChange?.({
                              ...value,
                              price: p ?? 0,
                            }),
                          showControl: true,
                          endAdornment: (
                            <EndAdornmentLabel>
                              {t(`global:currency.${value.material.currency}`)}
                            </EndAdornmentLabel>
                          ),
                        },
                      },
                      {
                        label: t('orders:attributes.discount.label'),
                        key: 'discount',
                        type: ItemType.number,
                        formatValue: (discount: Discount) => {
                          return discount?.value
                        },
                        props: {
                          readOnly: true,
                          endAdornment: (
                            <EndAdornmentLabel>
                              {value?.discount?.type === DiscountType.amount && (
                                <Box display="flex">
                                  {t(`global:currency.${value.material.currency}`)}
                                  <EditIcon fontSize="small" sx={{ marginLeft: '5px' }} />
                                </Box>
                              )}
                              {value?.discount?.type === DiscountType.percentage && (
                                <Box display="flex">
                                  {t('global:format.percent', { value: '' })}
                                  <EditIcon fontSize="small" sx={{ marginLeft: '5px' }} />
                                </Box>
                              )}
                              {!value?.discount?.type && <AddIcon fontSize="small" />}
                            </EndAdornmentLabel>
                          ),
                          onClick: (e: any) => {
                            onEditDiscount?.(value.material._id)
                            e.stopPropagation()
                          },
                        },
                      },
                    ]
                  : []),
              ]
            : [
                {
                  label: t('orders:attributes.product.unitPrice'),
                  key: 'unitPrice',
                  formatValue: () => {
                    if (termsOfSale !== TermsOfSale.sale) {
                      return t(`materials:termsOfSale.${termsOfSale}`)
                    }
                    return `${unitPrice}${t(`global:currency.${value.material.currency}`)}`
                  },
                },
              ]) as DetailItem[]),
          ...((value?.discount && value.termsOfSale === TermsOfSale.sale
            ? [
                {
                  label: t('orders:attributes.product.totalHT'),
                  key: 'totalHT',
                  type: ItemType.custom,
                  custom: (
                    <Box display="flex" flexDirection="column">
                      <b>
                        {computeProductPrice(value).toFixed(2)}
                        {t(`global:currency.${value.material.currency}`)}
                      </b>
                      <span style={{ textDecoration: 'line-through', marginLeft: '5px' }}>
                        {(unitPrice * value.quantity).toFixed(2)}
                        {t(`global:currency.${value.material.currency}`)}
                      </span>
                    </Box>
                  ),
                },
              ]
            : [
                {
                  label: t('orders:attributes.product.totalHT'),
                  key: 'totalHT',
                  formatValue: () => {
                    if (termsOfSale !== TermsOfSale.sale || !unitPrice) {
                      return ''
                    }
                    return `${computeProductPrice(value)} ${t(
                      `global:currency.${value.material.currency}`,
                    )}`
                  },
                },
              ]) as DetailItem[]),
          ...(hasRetrieval && !finishStep
            ? [
                {
                  label: t('orders:attributes.product.retrieval.label'),
                  key: 'retrieval',
                  type: ItemType.tooltip,
                  props: {
                    title: hasRetrieval && (
                      <TooltipContent>
                        <RowsTitle>{t('orders:attributes.product.retrieval.title')}</RowsTitle>
                        {!fromDefault && !!value.material.retrieval?.retrievalModality && (
                          <RowDetail
                            direction="row"
                            alignItems="center"
                            justifyContent="space-between"
                            spacing={1.5}>
                            <RowTextLeft>
                              {t('orders:attributes.product.retrieval.modality')}
                            </RowTextLeft>
                            <RowTextRight>
                              {t(
                                `global:retrievalModality.${value.material.retrieval.retrievalModality}`,
                              )}
                            </RowTextRight>
                          </RowDetail>
                        )}
                        {!!value.material.state && (
                          <RowDetail
                            direction="row"
                            alignItems="center"
                            justifyContent="space-between"
                            spacing={1.5}>
                            <RowTextLeft>
                              {t('orders:attributes.product.retrieval.state')}
                            </RowTextLeft>
                            <RowTextRight>
                              {t(`materials:state.${value.material.state}`)}
                            </RowTextRight>
                          </RowDetail>
                        )}
                        {value.material.conditioning && (
                          <RowDetail
                            alignItems="flex-start"
                            justifyContent="flex-start"
                            spacing={1.5}>
                            <RowTextLeft>
                              {t('orders:attributes.product.retrieval.conditioning')}
                            </RowTextLeft>
                            <RowTextSeveralLines>{value.material.conditioning}</RowTextSeveralLines>
                          </RowDetail>
                        )}
                        {!fromDefault &&
                          !!value.material.retrieval?.startDate &&
                          !!value.material.retrieval?.endDate && (
                            <RowDetailNoBorder
                              direction="row"
                              alignItems="center"
                              justifyContent="space-between"
                              spacing={1.5}>
                              <RowTextLeft>
                                {t('orders:attributes.product.retrieval.startDate')}
                              </RowTextLeft>
                              <RowTextRight>
                                {`${t('global:format.date', {
                                  date: new Date(
                                    value.material.retrieval.startDate || 'invalid date',
                                  ),
                                })} - ${t('global:format.date', {
                                  date: new Date(
                                    value.material.retrieval.endDate || 'invalid date',
                                  ),
                                })}`}
                              </RowTextRight>
                            </RowDetailNoBorder>
                          )}
                      </TooltipContent>
                    ),
                    children: <>{hasRetrieval && <StyledInfoIcon />}</>,
                  },
                },
              ]
            : []),
        ]}
      />
    </Card>
  )
}

export default ProductCard
