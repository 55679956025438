import React, { useMemo, useState } from 'react'
import useSnackbar from '../../hooks/useSnackbar.hooks'
import { useTranslation } from 'react-i18next'
import { ItemType, FormItem } from '../../models/props.models'
import { YupUtils } from '../../utils/yup.utils'
import { sessionService } from '../../store/session'
import ModalForm from '../common/ModalForm.common'

interface ForgotPasswordModalProps {
  onClose: () => void
}
const ForgotPasswordModal: React.FC<ForgotPasswordModalProps> = ({ onClose }): JSX.Element => {
  const show = useSnackbar()
  const { t } = useTranslation()

  const [value, setValue] = useState<{ email: string }>({ email: '' })
  const onSuccess = () => {
    show(t('sessions:actions.forgotPasswordSuccess'))
  }
  const items: FormItem[] = useMemo(
    () => [
      {
        type: ItemType.text,
        key: 'email',
        required: true,
        rules: [YupUtils.FieldValidationType.email],
        props: {
          label: t('users:attributes.email'),
          placeholder: t('users:attributes.email'),
          type: 'email',
          autocomplete: 'email',
        },
      },
    ],
    [t],
  )

  return (
    <ModalForm
      value={value}
      setValue={setValue}
      title={t('sessions:components.modalForgotPassword.title')}
      onClose={onClose}
      onSuccess={onSuccess}
      onSubmit={sessionService.forgotPassword.bind(null, value.email)}
      submitLabel={t('global:actions.gotIt')}
      items={items}
    />
  )
}

export default ForgotPasswordModal
