import { useTranslation } from 'react-i18next'
import { Mode } from '../../models/commons.models'
import useCheckRoute from '../../hooks/useCheckRoute.hooks'
import { AdminHeaderTitle } from '../../components/layout/AdminHeader.layout'
import PageUserList from '../../components/page/UserList.page'

const UserListPage = () => {
  useCheckRoute('UserList', [Mode.admin])
  const { t } = useTranslation()

  return (
    <>
      <AdminHeaderTitle title={t('global:routes.users')} />
      <PageUserList />
    </>
  )
}
export default UserListPage
