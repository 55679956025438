import {
  Checkbox,
  Box,
  InputLabel,
  FormControlLabel,
  Typography,
  styled,
  FormHelperText,
} from '@mui/material'
import { SelectOption, RadioProps } from '../../../models/props.models'

const CheckboxLabel = styled(Typography)({ fontSize: '0.875rem', fontWeight: 500 })

const Radio: React.FC<RadioProps> = ({
  label,
  required,
  error,
  disabled,
  items,
  readOnly,
  onChange,
  multiple,
  value,
  split,
  column,
}) => {
  const update = (itemValue: string, checked: boolean) => {
    if (readOnly) {
      return
    }
    if (multiple) {
      onChange?.(
        checked
          ? (value || []).concat(itemValue)
          : (value || []).filter((v: string) => itemValue !== v),
      )
    } else {
      onChange?.(checked ? itemValue : undefined)
    }
  }
  const isChecked = (itemValue: string): boolean => {
    if (multiple) {
      return value?.includes(itemValue) ?? false
    } else {
      return itemValue === value
    }
  }

  const RadioValue: React.FC<{ item: SelectOption }> = ({ item }) => {
    return (
      <Box mb="5px">
        <FormControlLabel
          control={<Checkbox color="primary" size="small" />}
          labelPlacement="end"
          disabled={disabled}
          onChange={(_, checked) => update(item.value, checked)}
          checked={isChecked(item.value)}
          label={
            <Box ml="6px">
              <CheckboxLabel>{item.label}</CheckboxLabel>
            </Box>
          }
        />
      </Box>
    )
  }

  return (
    <Box width="100%" display="flex" flexDirection="column">
      <Box mb="5px">
        {!!label && <InputLabel error={!!error}>{label + (required ? '*' : '')}</InputLabel>}
      </Box>

      <Box width="100%" display="flex" alignItems="flex-start" pl="10px">
        {split ? (
          <>
            <Box display="flex" flexDirection="column" alignItems="flex-start" mr="40px">
              {items &&
                items.map((item: SelectOption, index) =>
                  index % 2 === 1 ? null : <RadioValue key={item.value} item={item} />,
                )}
            </Box>

            <Box display="flex" flexDirection="column" alignItems="flex-start">
              {items &&
                items.map((item: SelectOption, index) =>
                  index % 2 === 0 ? null : <RadioValue key={item.value} item={item} />,
                )}
            </Box>
          </>
        ) : (
          <Box
            display="flex"
            flexDirection={column ? 'column' : 'row'}
            alignItems={column ? 'flex-start' : 'center'}
            width="100%"
            justifyContent="space-between"
            flexWrap="wrap">
            {items &&
              items.map((item: SelectOption) => <RadioValue key={item.value} item={item} />)}
          </Box>
        )}
      </Box>
      {typeof error === 'string' && <FormHelperText error>{error}</FormHelperText>}
    </Box>
  )
}
export default Radio
