import Constants from '../constants'

import { Material, TermsOfSale } from './materials.models'
import { Pagination } from './commons.models'
import { User } from './users.models'
import { CatalogLight } from './catalogs.models'
import { FileDetails } from './files.models'

export enum CartEvent {
  CART_CHANGE = 'cart-change',
}
export enum OrderStatus {
  pricePending = 'pricePending',
  pending = 'pending',
  clientPending = 'clientPending',
  clientAccepted = 'clientAccepted',
  accepted = 'accepted',
  finished = 'finished',
  denied = 'denied',
  canceled = 'canceled',
  // depositPaid = 'depositPaid',
  // waitingPayment = 'waitingPayment',
}
export enum DiscountType {
  amount = 'amount',
  percentage = 'percentage',
}

export const computeProductPrice = (product: Product): number => {
  const productPrice =
    (product.termsOfSale === TermsOfSale.sale
      ? product.price
      : !product.termsOfSale && product.material.termsOfSale === TermsOfSale.sale
      ? product.material.price
      : 0) ?? 0

  let price = productPrice * product.quantity
  if (product.discount) {
    const discountAmount =
      product.discount?.type === DiscountType.amount
        ? product.discount?.value ?? 0
        : product.discount?.type === DiscountType.percentage && product.discount?.value
        ? price * (product.discount?.value / 100)
        : 0
    price = Math.max(price - discountAmount, 0)
  }
  return price
}

export const computeOrderBeforeDiscount = (order: Order): number => {
  return order.products.reduce((res, p) => {
    return res + computeProductPrice(p)
  }, 0)
}
export const computeOrderTotal = (order: Order): number => {
  let total = computeOrderBeforeDiscount(order)
  if (order.discount) {
    const discountAmount =
      order.discount?.type === DiscountType.amount
        ? order.discount?.value ?? 0
        : order.discount?.type === DiscountType.percentage && order.discount?.value
        ? total * (order.discount?.value / 100)
        : 0
    total = Math.max(total - discountAmount, 0)
  }
  return total ?? 0
}

export const getOrderStatusColor = (status: OrderStatus) => {
  switch (status) {
    case OrderStatus.pricePending:
      return Constants.colors.warning
    case OrderStatus.pending:
      return Constants.colors.primary
    case OrderStatus.clientAccepted:
      return Constants.colors.info
    case OrderStatus.clientPending:
      return Constants.colors.info
    case OrderStatus.accepted:
      return Constants.colors.success
    case OrderStatus.finished:
      return Constants.colors.purple
    case OrderStatus.canceled:
      return Constants.colors.secondary
    case OrderStatus.denied:
      return Constants.colors.error
    default:
      return Constants.colors.black
  }
}

export interface Discount {
  value: number
  type: DiscountType
}

export interface OrdersPagination extends Pagination {
  data: Order[]
}

export interface Product {
  material: Material
  quantity: number
  price?: number
  termsOfSale?: TermsOfSale
  discount?: Discount
}

export interface ApiProduct {
  material: string
  quantity: number
}
export interface ApiFinishedProduct extends ApiProduct {
  price: number
  termsOfSale: TermsOfSale
  discount?: Discount
}
export interface Order {
  _id: string
  tax?: number
  client: User
  status: OrderStatus
  priceModified: boolean
  orderNumber: number
  catalog: CatalogLight
  products: Product[]
  createdAt: Date
  deniedFor?: string
  discount?: Discount
  finishedComment?: string
  removalOrder?: FileDetails
}
export interface ApiUpdateOrder {
  status: OrderStatus
  deniedFor?: string
  discount?: Discount
  finishedComment?: string
  removalOrder?: string | File
  products?: ApiFinishedProduct[]
}
export interface ApiOrder {
  client: string
  products: ApiProduct[]
}
