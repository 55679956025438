// @ts-ignore
import * as geometric from 'geometric/index.js'
import { Point } from '../models/commons.models'
import { Room } from '../models/catalogs.models'
import { LocatedMaterial } from '../models/materials.models'

export namespace PlanUtils {
  export const convertMtoFt = (value: number) => {
    return value * 3.28084
  }

  export const convertMtoFt2 = (value: number) => {
    return convertMtoFt(convertMtoFt(value))
  }

  export const rotatePoint = (point: Point, angle: number): Point => {
    return geometric.pointRotate(point, angle, [0, 0])
  }
  export const rotateLine = (line: [Point, Point], angle: number): [Point, Point] => {
    return geometric.lineRotate(line, angle, [0, 0])
  }
  export const getAngle = (line: [Point, Point]): number => {
    let angle = geometric.lineAngle(line)
    if (angle > 180) {
      angle -= 360
    }

    if (angle > 90) {
      angle -= 180
    } else if (angle < -90) {
      angle += 180
    }
    return angle
  }
  export const getArea = (points: Point[]): number => {
    return geometric.polygonArea(points)
  }
  export const getPerimeter = (points: Point[]): number => {
    return geometric.polygonLength(points)
  }
  export const getDistance = (line: [Point, Point]): number => {
    return geometric.lineLength(line)
  }
  export const getMiddle = (line: [Point, Point]): Point => {
    return [(line[1][0] + line[0][0]) / 2, (line[1][1] + line[0][1]) / 2] as Point
  }
  export const isInRoom = (position: Point, room: Room): boolean => {
    return geometric.pointInPolygon(position, room.points)
  }
  export const getRoomAtPosition = (position: Point, rooms: Room[]): Room | undefined => {
    if (!position) {
      return undefined
    }
    const room = rooms.find((room: Room) => {
      return position && geometric.pointInPolygon(position, room.points)
    })
    return room
  }
  export const isRoomValid = (room: Room, rooms: Room[], closed = true): Boolean => {
    const othersRooms = rooms.filter((planRoom: Room) => planRoom._id !== room._id)
    let startPoint, endPoint, line

    for (let i = 0; i < room.points.length; i++) {
      startPoint = room.points[i]
      endPoint = room.points[(i + 1) % room.points.length]
      line = [startPoint, endPoint]

      for (let j = 0; j < othersRooms.length; j++) {
        if (
          geometric.pointInPolygon(startPoint, othersRooms[j].points) &&
          !geometric.pointOnPolygon(startPoint, othersRooms[j].points)
        ) {
          console.log('tmp point start in room')
          return false
        }
      }

      if (i === room.points.length - 1 && !closed) {
        break
      }

      let testedLine
      for (let j = 0; j < othersRooms.length; j++) {
        for (let k = 0; k < othersRooms[j].points.length; k++) {
          testedLine = [
            othersRooms[j].points[k],
            othersRooms[j].points[(k + 1) % othersRooms[j].points.length],
          ]
          // test line by line testing point are on line to handle position magnet to corner/line of others rooms
          if (
            geometric.lineIntersectsLine(line, testedLine) &&
            !geometric.pointOnLine(line[0], testedLine) &&
            !geometric.pointOnLine(line[1], testedLine) &&
            !geometric.pointOnLine(testedLine[0], line) &&
            !geometric.pointOnLine(testedLine[1], line)
          ) {
            console.log('tmp wall cut others room')
            return false
          }
        }
      }

      let secondLine
      // + 2 : do not check adjacent segment
      for (let j = i + 2; j < room.points.length; j++) {
        secondLine = [room.points[j], room.points[(j + 1) % room.points.length]]
        // when we the the closing segment
        if (j === room.points.length - 1) {
          //do not check is room is not closed
          //do not check with the first segment (adjacent segment)
          if (!closed || i === 0) {
            break
          }
        }
        if (geometric.lineIntersectsLine(line, secondLine)) {
          return false
        }
      }
    }
    if (closed) {
      if (room.points.length < 3) {
        return false
      }
      for (let i = 0; i < othersRooms.length; i++) {
        if (geometric.pointInPolygon(othersRooms[i].points[0], room.points)) {
          console.log('tmp room contain another  room')
          return false
        }
      }
    }

    return true
  }
  export const isLocatedMaterialValid = (_: LocatedMaterial): Boolean => {
    return true
  }
  export const removeRoomPoint = (room: Room, pointIndex: number): Point[] => {
    return room.points
      .filter((_, index: number) => index !== pointIndex)
      .map((point: Point) => [...point])
  }
  export const cutRoomLine = (room: Room, lineIndex: number, position: Point): Point[] => {
    return room.points.reduce((points: Point[], point: Point, index: number) => {
      points.push([...point])
      if (index === Number(lineIndex)) {
        points.push([...position])
      }
      return points
    }, [])
  }
  export const editRoomPoint = (room: Room, pointIndex: number, position: Point): Point[] => {
    return room.points.map((point: Point, index: number) => {
      if (index === Number(pointIndex)) {
        return [...position]
      }
      return [...point]
    })
  }
  export const editRoomLine = (
    room: Room,
    lineIndex: number,
    delta: Point,
    angle: number,
  ): Point[] => {
    return room.points.map((point: Point, index: number) => {
      if (index === Number(lineIndex) || index === (Number(lineIndex) + 1) % room.points.length) {
        let tmp = rotatePoint(point, angle)
        tmp = [tmp[0] + delta[0], tmp[1] + delta[1]]
        return rotatePoint(tmp, -angle)
      }
      return [...point]
    })
  }
  export const editRectangleRoom = (room: Room, position: Point, angle: number): Point[] => {
    const firstPoint = rotatePoint(room.points[0], angle)
    const secondPoint = rotatePoint(position, angle)
    return [
      rotatePoint([...firstPoint], -angle),
      rotatePoint([firstPoint[0], secondPoint[1]], -angle),
      rotatePoint([...secondPoint], -angle),
      rotatePoint([secondPoint[0], firstPoint[1]], -angle),
    ]
  }
  export const editLocatedMaterialPosition = (_: LocatedMaterial, position: Point): Point => {
    return [...position]
  }
  export const getClickInfo = (
    position: Point,
    rooms: Room[],
    targetId?: string,
    returnRoomAtPosition = false,
  ) => {
    const res = {
      roomId: '',
      locatedMaterialId: '',
      lineIndex: -1,
      pointIndex: -1,
      magneticPosition: position,
    }
    if (targetId) {
      const [type, ...splittedId] = targetId.split(/\./g)
      if (type === 'room') {
        const targetType = splittedId[splittedId.length - 2]
        const targetIndex = splittedId[splittedId.length - 1]
        const roomId = splittedId.slice(0, -2).join('.')
        const room = rooms.find((planRoom: Room) => planRoom._id === roomId)
        const lineIndex = targetType === 'line' ? targetIndex : null
        const pointIndex = targetType === 'point' ? targetIndex : null
        if (room) {
          res.roomId = roomId
          if (pointIndex && Number(pointIndex) >= 0) {
            res.pointIndex = Number(pointIndex)
          } else if (lineIndex && Number(lineIndex) >= 0) {
            res.lineIndex = Number(lineIndex)
          }
          if (res.pointIndex >= 0) {
            res.magneticPosition = [...room.points[res.pointIndex]]
          } else if (res.lineIndex >= 0) {
            const start = room.points[res.lineIndex]
            const end = room.points[(res.lineIndex + 1) % room.points.length]
            const length = Math.pow(end[0] - start[0], 2) + Math.pow(end[1] - start[1], 2)
            if (length < 0.0001) {
              res.magneticPosition = start
            }
            const coeff =
              ((position[0] - start[0]) * (end[0] - start[0]) +
                (position[1] - start[1]) * (end[1] - start[1])) /
              length
            res.magneticPosition = [
              start[0] + coeff * (end[0] - start[0]),
              start[1] + coeff * (end[1] - start[1]),
            ]
          }
        }
      } else if (type === 'locatedMaterial') {
        res.locatedMaterialId = splittedId.join('.')
      }
    }

    if (!res.magneticPosition) {
      res.magneticPosition = position
    }

    if (returnRoomAtPosition && !res.roomId) {
      res.roomId = getRoomAtPosition(position, rooms)?._id || ''
    }
    return res
  }
}
