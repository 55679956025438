import { Box } from '@mui/material'
import StepperComponent from '../Stepper.common'
import Group from './Group.item'

type StepperProps = {
  progress: number
  title?: string
  error?: string
  children?: JSX.Element | JSX.Element[]
}
const StepperForm: React.FC<StepperProps> = ({ progress, title, children, error }) => {
  return (
    <>
      <Box ml="-50px" width="calc(100% + 100px)" mb="20px">
        <StepperComponent progress={progress} />
      </Box>

      <Group title={title} error={!!error}>
        {children}
      </Group>
    </>
  )
}
export default StepperForm
