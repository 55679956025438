import { ResourcesCatalogTypes } from '../../../models/catalogs.models'
import { Outlet } from 'react-router-dom'
import useCheckRoute from '../../../hooks/useCheckRoute.hooks'
import useRoute from '../../../hooks/useRoute.hooks'
import { useTranslation } from 'react-i18next'
import { Mode, Route } from '../../../models/commons.models'
import PageCatalogList from '../../../components/page/CatalogList.page'
import { useRef } from 'react'
import { sessionService } from '../../../store/session'
import TabsComponent from '../../../components/common/Tabs.common'

const ListCatalogPublicPage = () => {
  useCheckRoute('PublicCatalogList', [Mode.front, Mode.storeFront])
  const { getPath } = useRoute()
  const { t } = useTranslation()

  const showPublicNeeds = useRef(sessionService.showPublicNeeds())

  if (showPublicNeeds.current) {
    return (
      <>
        <TabsComponent
          items={[
            {
              label: t(`global:routes.publicCatalogsResources`),
              path: getPath({
                route: Route.publicCatalogsResources,
              }),
            },
            {
              label: t(`global:routes.publicCatalogsNeeds`),
              secondary: true,
              path: getPath({
                route: Route.publicCatalogsNeeds,
              }),
            },
          ]}
        />
        <Outlet />
      </>
    )
  }

  return <PageCatalogList isPublic types={ResourcesCatalogTypes} />
}
export default ListCatalogPublicPage
