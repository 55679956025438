import { useEffect, useState, useRef, useCallback } from 'react'
import {
  Box,
  InputLabel,
  Paper,
  Autocomplete,
  FormHelperText,
  Typography,
  MenuItem,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { KeyboardArrowDown as KeyboardArrowDownIcon } from '@mui/icons-material'

import { SelectOption, AsyncSelectProps } from '../../../models/props.models'
import useSnackbar from '../../../hooks/useSnackbar.hooks'
import StyledInput from './Styled.input'
import { StringUtils } from '../../../utils/commons.utils'

const AsyncSelectInput: React.FC<AsyncSelectProps> = (props): React.ReactElement => {
  const {
    allowNew,
    ariaLabel,
    disabled,
    error,
    getOptions,
    options: propsOptions,
    onChange,
    label,
    minimumSearchLength,
    noResultComponent,
    placeholder,
    readOnly,
    required,
    searchIfEmpty,
    value = '',
    dataValue,
    dataLabel,
  } = props

  const { t } = useTranslation()
  const show = useSnackbar()

  const [loading, setLoading] = useState(false)

  const [newValues, setNewValues] = useState<SelectOption[]>([])
  const [open, setOpen] = useState(false)
  const [options, setOptions] = useState<SelectOption[]>([])

  const [inputValue, setInputValue] = useState('')
  const timeoutRef = useRef<any>()

  const selectNew = useCallback(() => {
    setNewValues((newValues) => [
      {
        label: inputValue,
        value: inputValue,
        data: { label: inputValue, value: inputValue, isNew: true },
      },
      ...newValues,
    ])
    onChange?.({ label: inputValue, value: inputValue, isNew: true })
    setOpen(false)
  }, [inputValue, onChange])
  const onInputChange = useCallback(
    (newInputValue: string) => {
      setInputValue(newInputValue)
      if (getOptions) {
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current)
        }
        setLoading(true)
        timeoutRef.current = setTimeout(() => {
          if (!minimumSearchLength || newInputValue.length >= minimumSearchLength) {
            ;(async () => {
              try {
                const results = await getOptions?.(newInputValue)
                let newOptions: SelectOption[] = []
                if (!!value)
                  newOptions = [
                    value,
                    ...newValues.filter((option) =>
                      newInputValue
                        ? StringUtils.normalizeIncludes(option.label, newInputValue)
                        : true,
                    ),
                  ]
                if (results) newOptions = [...newOptions, ...results]
                setOptions(newOptions)
                setLoading(false)
              } catch (err: any) {
                show(err)
                setLoading(false)
              }
            })()
          }
        }, 500)
      } else if (propsOptions) {
        setOptions(
          newValues.concat(
            propsOptions.filter((option) =>
              newInputValue ? StringUtils.normalizeIncludes(option.label, newInputValue) : true,
            ),
          ),
        )
      }
    },
    [getOptions, minimumSearchLength, show, propsOptions, value, newValues],
  )

  useEffect(() => {
    setOptions((options) => [...newValues, ...options])
  }, [newValues])
  useEffect(() => {
    if (propsOptions) {
      setOptions(propsOptions)
    }
  }, [propsOptions])
  useEffect(() => {
    if (!propsOptions) {
      if (
        (!searchIfEmpty && inputValue === '') ||
        (!!minimumSearchLength && inputValue.length < minimumSearchLength)
      ) {
        setOptions(value ? [value, ...newValues] : [])
      } else if (searchIfEmpty && inputValue === '') {
        onInputChange('')
      }
    }
  }, [
    value,
    inputValue,
    minimumSearchLength,
    searchIfEmpty,
    propsOptions,
    onInputChange,
    newValues,
  ])

  const PaperComponent = (params: any) => {
    const child0Props = !!(params.children as any)?.[0] ? (params.children as any)[0].props : {}
    const { ownerState, ...other0 } = child0Props
    const child1Props =
      !noResultComponent && !!(params.children as any)?.[1] ? (params.children as any)[1].props : {}
    const { ownerState: ownerState1, ...other1 } = child1Props
    const child2Props = (params.children as any)[2]?.props || {}
    const { ownerState: ownerState2, ...other2 } = child2Props

    return (
      <Paper {...params}>
        {!!(params.children as any)?.[0] && (
          <Box {...other0}>{(params.children as any)[0].props.children}</Box>
        )}
        {!noResultComponent && !!(params.children as any)?.[1] && (
          <Box {...other1}>{(params.children as any)[1].props.children}</Box>
        )}
        <Box
          {...other2}
          className="MuiAutocomplete-listbox"
          onMouseDown={(evt: Event) => evt.preventDefault()}
          p="0">
          {!!(params.children as any)?.[2] && (params.children as any)[2].props.children}
          {noResultComponent}
        </Box>
      </Paper>
    )
  }

  return (
    <Box display="flex" flexDirection="column">
      {!!label && <InputLabel error={!!error}>{label + (required ? '*' : '')}</InputLabel>}
      <Autocomplete
        ListboxProps={{ style: { maxHeight: '50vh', overflow: 'auto' } }}
        getOptionLabel={(option) => {
          return typeof option === 'string' ? option : option.label || ''
        }}
        isOptionEqualToValue={(option: SelectOption, value: SelectOption) => {
          return option.value === value.value
        }}
        filterOptions={(x) => x}
        options={options}
        autoComplete
        includeInputInList
        filterSelectedOptions
        value={
          (dataValue || dataLabel) && value
            ? {
                value: typeof dataValue == 'string' ? (value as any)[dataValue] : value.value,
                label: dataLabel ? (value as any)[dataLabel] : value.label,
              }
            : value || null
        }
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        loading={loading}
        disabled={disabled}
        readOnly={readOnly}
        open={minimumSearchLength && inputValue.length < minimumSearchLength ? false : open}
        onChange={(_: any, newValue: SelectOption | null, reason: any) => {
          if (reason === 'clear') {
            setNewValues([])
          }
          setOptions((opts) => (newValue ? [newValue, ...opts] : opts))
          onChange?.(dataValue ? newValue?.data : newValue || undefined)
        }}
        onInputChange={(_, newInputValue) => {
          onInputChange(newInputValue)
        }}
        PaperComponent={(params) => {
          return <PaperComponent {...params} />
        }}
        noOptionsText={
          <>
            {allowNew && (
              <MenuItem onClick={selectNew}>
                <Typography color="primary">{t('global:inputs.allowNew')}</Typography>
              </MenuItem>
            )}
            {<Box padding="10px 16px">{t('global:inputs.noOptions')}</Box>}
          </>
        }
        loadingText={<Box padding="10px 16px">{t('global:actions.loading')}</Box>}
        popupIcon={<KeyboardArrowDownIcon />}
        renderInput={(params) => (
          <StyledInput
            {...params}
            variant="outlined"
            color="primary"
            focused={readOnly ? false : undefined}
            placeholder={placeholder}
            disabled={disabled}
            required={required}
            error={!!error && !disabled}
            aria-label={ariaLabel}
          />
        )}
      />
      {typeof error === 'string' && <FormHelperText error>{error}</FormHelperText>}
    </Box>
  )
}

export default AsyncSelectInput
