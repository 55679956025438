import { Tooltip } from '@mui/material'
import { useState } from 'react'

import { TooltipProps } from '../../models/props.models'
const OwnTooltip: React.FC<TooltipProps> = ({ title, children }): JSX.Element => {
  const [open, setOpen] = useState(false)

  return (
    <Tooltip title={title} open={open} onOpen={() => setOpen(true)} onClose={() => setOpen(false)}>
      <span
        onClick={(e: any) => {
          e.stopPropagation()
          e.preventDefault()
          setOpen((open) => !open)
        }}>
        {children}
      </span>
    </Tooltip>
  )
}
export default OwnTooltip
