import { useOutletContext } from 'react-router-dom'
import { Organization } from '../../../models/organizations.models'
import PageUserList from '../../../components/page/UserList.page'

const OrganizationUsersTab = () => {
  const { organization } = useOutletContext<{
    organization: Organization
  }>()
  return <PageUserList withTabs organization={organization._id} />
}
export default OrganizationUsersTab
