import { categoriesStore } from './categories.store'
import { ReccursiveCategory } from '../../models/categories.models'
import { logIfDev } from '../../utils/commons.utils'
import { FileUtils } from '../../utils/files.utils'
import { NetworkUtils } from '../../utils/networks.utils'
import { updateRequestStatus } from '@ngneat/elf-requests'
import Constants from '../../constants'
import { CategoriesApi } from '../../api/categories.api'

const getLocalFilePath = () => `/categories`
export class CategoriesService {
  store = categoriesStore

  init = async () => {
    this.store.update(updateRequestStatus('init', 'pending'))

    try {
      const isConnected = await NetworkUtils.isConnected()
      let categoriesList: ReccursiveCategory[] = []
      let fromApi = false

      if (isConnected) {
        try {
          categoriesList = await CategoriesApi.getList()
          fromApi = true
        } catch (err: any) {
          if (Constants.getIsLocal()) {
            categoriesList = await FileUtils.readJSON(getLocalFilePath())
          } else {
            throw err
          }
        }
      } else if (Constants.getIsLocal()) {
        categoriesList = await FileUtils.readJSON(getLocalFilePath())
      } else {
        throw new Error('NOT_CONNECTED')
      }

      this.store.update(
        (state) => ({
          ...state,
          list: categoriesList,
        }),
        updateRequestStatus('init', 'success'),
      )

      if (fromApi && Constants.getIsLocal()) {
        try {
          FileUtils.writeJSON(getLocalFilePath(), categoriesList)
        } catch (err: any) {
          logIfDev(err)
        }
      }
    } catch (err: any) {
      this.store.update(updateRequestStatus('init', 'error', err))
      throw err
    }
  }
}
export const categoriesService = new CategoriesService()
