import { NetworkUtils } from '../../utils/networks.utils'
import {
  Request,
  RequestsPagination,
  RequestStatus,
  ApiRequest,
} from '../../models/requests.models'
import { Mode } from '../../models/commons.models'
import { sessionService } from '../session'
import Constants from '../../constants'
import { RequestsApi } from '../../api/requests.api'

export class RequestsService {
  getRequestById = async (requestId: string): Promise<Request> => {
    await NetworkUtils.checkConnected()
    return await RequestsApi.getById(requestId)
  }

  getRequests = async (filters: RequestsApi.GetListParams): Promise<RequestsPagination> => {
    await NetworkUtils.checkConnected()
    return await RequestsApi.getList({
      ...filters,
      ...(Constants.mode === Mode.admin && { platform: sessionService.getAdminPlatform() }),
    })
  }

  createRequest = async (request: ApiRequest): Promise<Request> => {
    await NetworkUtils.checkConnected()
    return await RequestsApi.create(request)
  }

  updateRequest = async (
    requestId: string,
    updatedRequest: { status: RequestStatus, deniedFor?: string},
  ): Promise<Request> => {
    await NetworkUtils.checkConnected()
    return await RequestsApi.update(requestId, updatedRequest)
  }
}

export const requestsService = new RequestsService()
