import { useTranslation } from 'react-i18next'
import { Outlet } from 'react-router-dom'

import useCheckRoute from '../../hooks/useCheckRoute.hooks'
import useRoute from '../../hooks/useRoute.hooks'
import { Mode, Route } from '../../models/commons.models'

import { AdminHeaderTitle } from '../../components/layout/AdminHeader.layout'
import Tabs from '../../components/common/Tabs.common'
import Constants from '../../constants'

const WorkspacePage = () => {
  useCheckRoute('Workspace', [Mode.front, Mode.admin, Mode.app])
  const { t } = useTranslation()
  const { getPath } = useRoute()

  return (
    <>
      <AdminHeaderTitle title={t('global:routes.workspace')} />
      <Tabs
        items={[
          {
            label: t(`global:routes.workspaceDeconstructions`),
            group:
              Constants.mode !== Mode.app ? t(`catalogs:pages.workspace.resources`) : undefined,
            path: getPath({
              route: Route.workspaceDeconstructions,
            }),
          },
          {
            label: t(`global:routes.workspaceStorages`),
            group:
              Constants.mode !== Mode.app ? t(`catalogs:pages.workspace.resources`) : undefined,
            path: getPath({
              route: Route.workspaceStorages,
            }),
          },
          ...(Constants.mode === Mode.admin
            ? []
            : [
                {
                  label: t(`global:routes.workspaceOrders`),
                  group:
                    Constants.mode !== Mode.app
                      ? t(`catalogs:pages.workspace.resources`)
                      : undefined,
                  path: getPath({
                    route: Route.workspaceOrders,
                  }),
                },
                {
                  label: t(`global:routes.workspaceReporting`),
                  group:
                    Constants.mode !== Mode.app
                      ? t(`catalogs:pages.workspace.resources`)
                      : undefined,
                  path: getPath({
                    route: Route.workspaceReporting,
                  }),
                },
              ]),
          ...(Constants.mode === Mode.app
            ? []
            : [
                {
                  label: t(`global:routes.workspaceConstructions`),
                  group: t(`catalogs:pages.workspace.needs`),
                  secondary: true,
                  path: getPath({
                    route: Route.workspaceConstructions,
                  }),
                },
                {
                  label: t(`global:routes.workspaceNeeds`),
                  group: t(`catalogs:pages.workspace.needs`),
                  secondary: true,
                  path: getPath({
                    route: Route.workspaceNeeds,
                  }),
                },
              ]),
        ]}
      />
      <Outlet />
    </>
  )
}
export default WorkspacePage
