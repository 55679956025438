import React, { useMemo, memo, useCallback, useState } from 'react'
import { Delete as DeleteIcon } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import Table from '../common/Table.common'
import { createOptionsFromEnum } from '../../utils/i18n.utils'

import { ColumnItem, ItemType } from '../../models/props.models'
import {
  ManageMaterial,
  MaterialQuality,
  getMaterialQuantityLabel,
  MaterialQuantity,
  Unit,
  computeResourceQuantities,
  QuantityType,
} from '../../models/materials.models'
import LinkButton from '../common/button/Link.button'
import { Box, Button, Typography, styled } from '@mui/material'
import ModalForm from '../common/ModalForm.common'
const EndAdornmentLabel = styled(Typography)({ fontSize: '0.875rem', fontWeight: 500 })

type UpdatedQuantities = {
  initialQty: number
  currentQty: number
  quality: MaterialQuality
  type: QuantityType
  description?: string
}

interface MaterialTableQuantitiesProps {
  quantities: MaterialQuantity[]
  unit: Unit
  setValue: React.Dispatch<React.SetStateAction<ManageMaterial>>
}
const MaterialTableQuantities: React.FC<MaterialTableQuantitiesProps> = ({
  unit,
  quantities,
  setValue,
}) => {
  const [showDetail, setShowDetail] = useState(false)
  const [modal, setModal] = useState(false)

  const [updatedQuantities, setUpdatedQuantities] = useState<UpdatedQuantities>({
    initialQty: 0,
    currentQty: 0,
    quality: MaterialQuality.slightlyDamaged,
    type: QuantityType.inventory,
    description: undefined,
  })

  const updateValue = useCallback(
    (update: (quantities: MaterialQuantity[]) => MaterialQuantity[]) => {
      setValue((value) => ({
        ...value,
        ...computeResourceQuantities(update(value.quantities)),
      }))
    },
    [setValue],
  )

  const updateQuantities = useCallback(
    (updatedQuantities: UpdatedQuantities) => {
      updateValue((quantities: MaterialQuantity[]) => {
        const { initialQty } = computeResourceQuantities(quantities)
        let res = [...quantities]
        if (updatedQuantities.initialQty !== initialQty) {
          res.push({
            _id: `local${Math.random().toString().split('.')[1]}`,
            type: updatedQuantities.type,
            initial: true,
            quantity: updatedQuantities.initialQty - initialQty,
            quality: updatedQuantities.quality,
            description: updatedQuantities.description,
            linkToRoom: false,
          })
        }

        const { currentQty } = computeResourceQuantities(res)
        if (updatedQuantities.currentQty !== currentQty) {
          res.push({
            _id: `local${Math.random().toString().split('.')[1]}`,
            type: updatedQuantities.type,
            initial: false,
            quantity: updatedQuantities.currentQty - currentQty,
            quality: updatedQuantities.quality,
            description: updatedQuantities.description,
            linkToRoom: false,
          })
        }

        const { quality } = computeResourceQuantities(res)
        // We can not keep separate quantities...
        if (quality !== updatedQuantities.quality) {
          res = res.map((q: MaterialQuantity) => ({ ...q, quality: updatedQuantities.quality }))
        }

        return res
      })

      setUpdatedQuantities({
        initialQty: updatedQuantities.initialQty,
        currentQty: updatedQuantities.currentQty,
        quality: updatedQuantities.quality,
        type: QuantityType.inventory,
        description: undefined,
      })
    },
    [updateValue],
  )

  const addQuantity = useCallback(() => {
    updateValue((quantities) => [
      ...quantities,
      {
        _id: `local${Math.random().toString().split('.')[1]}`,
        type: QuantityType.inventory,
        initial: true,
        quantity: 1,
        quality: MaterialQuality.slightlyDamaged,
        linkToRoom: false,
      },
    ])
  }, [updateValue])
  const valueAction = useCallback(
    (deleted: MaterialQuantity) => {
      if (deleted?.order) {
        return undefined
      }
      return {
        onClick: () => {
          updateValue((quantities) => quantities.filter((quantity) => quantity._id !== deleted._id))
        },
        icon: <DeleteIcon />,
      }
    },
    [updateValue],
  )
  const editQuantity = useCallback(
    (key: string, editted: number, value: any) => {
      updateValue((quantities) =>
        quantities.map((quantity: MaterialQuantity, index: number) =>
          index === editted ? { ...quantity, [key]: value, linkToRoom: false } : quantity,
        ),
      )
    },
    [updateValue],
  )

  const { t } = useTranslation()
  const columns = useMemo<ColumnItem[]>(
    () => [
      (materialQuantity: MaterialQuantity | undefined) => ({
        type: ItemType.checkbox,
        label: t('materials:attributes.quantities.isInitial'),
        key: 'initial',
        formatValue: (initial) => initial !== false,
        onChange: editQuantity.bind(null, 'initial'),
        props: {
          disabled:
            !!materialQuantity?.order || !!materialQuantity?.plan || !!materialQuantity?.room,
        },
      }),
      (materialQuantity: MaterialQuantity | undefined) => ({
        type: ItemType.select,
        required: true,
        label: t('materials:attributes.quantities.type'),
        key: 'type',
        // formatValue: (initial) => initial !== false,
        onChange: editQuantity.bind(null, 'type'),
        props: {
          items: createOptionsFromEnum(QuantityType, 'materials:quantityType'),
          disabled:
            !!materialQuantity?.order || !!materialQuantity?.plan || !!materialQuantity?.room,
        },
      }),
      (materialQuantity: MaterialQuantity | undefined) => ({
        type: ItemType.text,
        label: t('materials:attributes.quantities.name'),
        key: 'name',
        minWidth: '100px',
        onChange: editQuantity.bind(null, 'description'),
        formatValue: (_: any) => {
          return materialQuantity ? getMaterialQuantityLabel(materialQuantity) : ''
        },
        props: {
          disabled:
            !!materialQuantity?.order || !!materialQuantity?.plan || !!materialQuantity?.room,
        },
      }),
      (materialQuantity: MaterialQuantity | undefined) => ({
        type: ItemType.number,
        minWidth: '130px',
        label: `${t('materials:attributes.quantities.variation')} ${
          unit ? t(`materials:unitSymbol.${unit}`) : ''
        }`,
        key: 'quantity',
        onChange: editQuantity.bind(null, 'quantity'),
        props: {
          disabled: !!materialQuantity?.order,
        },
      }),
      (materialQuantity: MaterialQuantity | undefined) => ({
        type: ItemType.select,
        grid: { xs: 12, sm: 4 },
        key: 'quality',
        minWidth: '150px',
        label: t('materials:attributes.quantities.quality'),
        onChange: editQuantity.bind(null, 'quality'),
        props: {
          items: createOptionsFromEnum(MaterialQuality, 'materials:quality'),
          disabled: !!materialQuantity?.order,
        },
      }),
    ],
    [t, editQuantity, unit],
  )

  return (
    <>
      {showDetail && (
        <Box marginBottom="24px" width="100%">
          {t('materials:attributes.quantities.description')}
          <Table
            disableScroll
            valueActions={valueAction}
            values={{
              count: quantities?.length || 0,
              total: quantities?.length || 0,
              data: quantities ?? [],
            }}
            columns={columns}
            paginationValue={{ disablePaginate: true }}
          />

          <LinkButton onClick={addQuantity}>
            {t('materials:attributes.quantities.addButton')}
          </LinkButton>
        </Box>
      )}
      <Box marginBottom="24px">
        <LinkButton onClick={() => setShowDetail((showDetail: boolean) => !showDetail)}>
          {t(`materials:components.tableQuantities.${showDetail ? 'seeLess' : 'seeMore'}`)}
        </LinkButton>
      </Box>
      <Box maxWidth="270px">
        <Button
          fullWidth
          variant="contained"
          color="primary"
          onClick={() => {
            const { initialQty, currentQty, quality } = computeResourceQuantities(quantities)
            setUpdatedQuantities({
              initialQty,
              currentQty,
              quality,
              type: QuantityType.inventory,
              description: undefined,
            })
            setModal(true)
          }}>
          {t('materials:components.tableQuantities.update')}
        </Button>
      </Box>

      {modal && (
        <ModalForm
          title={t('materials:components.tableQuantities.update')}
          maxWidth="lg"
          items={[
            {
              type: ItemType.number,
              grid: { xs: 6, md: 4 },
              key: 'initialQty',
              required: true,
              rules: [(value) => (value.initialQty > 0 ? '' : t('errors:positive'))],
              props: {
                label: t('materials:attributes.quantities.initial'),
                endAdornment: unit ? (
                  <EndAdornmentLabel>{t(`materials:unitSymbol.${unit}`)}</EndAdornmentLabel>
                ) : undefined,
              },
            },
            {
              type: ItemType.number,
              grid: { xs: 6, md: 4 },
              key: 'currentQty',
              required: true,
              rules: [(value) => (value.currentQty >= 0 ? '' : t('errors:positive'))],
              props: {
                label: t('materials:attributes.quantities.current'),
                endAdornment: unit ? (
                  <EndAdornmentLabel>{t(`materials:unitSymbol.${unit}`)}</EndAdornmentLabel>
                ) : undefined,
              },
            },
            {
              type: ItemType.select,
              grid: { xs: 6, sm: 4 },
              key: 'quality',
              required: true,
              props: {
                label: t('materials:attributes.globalQuality'),
                items: createOptionsFromEnum(MaterialQuality, 'materials:quality'),
              },
            },
            {
              type: ItemType.select,
              grid: { xs: 6, sm: 4 },
              key: 'type',
              required: true,
              props: {
                items: createOptionsFromEnum(QuantityType, 'materials:quantityType'),
                label: t('materials:attributes.quantities.type'),
              },
            },
            {
              type: ItemType.text,
              grid: { xs: 12, sm: 8 },
              key: 'description',
              props: {
                label: t('materials:attributes.quantities.name'),
              },
            },
          ]}
          value={updatedQuantities}
          setValue={setUpdatedQuantities}
          onClose={setModal.bind(null, false)}
          onSubmit={updateQuantities}
        />
      )}
    </>
  )
}

export default memo(MaterialTableQuantities)
