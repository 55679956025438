import PageOrderList from '../../../components/page/OrderList.page'
import { useOutletContext } from 'react-router-dom'
import { Catalog } from '../../../models/catalogs.models'

const CatalogOrdersTab = () => {
  const { catalog } = useOutletContext<{
    catalog: Catalog
  }>()

  return <PageOrderList withTabs catalog={catalog._id} />
}
export default CatalogOrdersTab
