import { useTranslation } from 'react-i18next'
import { useReducer, useEffect } from 'react'
import { IconButton, Grid, InputAdornment } from '@mui/material'
import { Delete as DeleteIcon, Preview as PreviewIcon } from '@mui/icons-material'
import {
  LocatedMaterial,
  MaterialQuality,
  Material,
  computeResourceQuantities,
  QuantityType,
  isLinkToRoomDimension,
  getRoomDimension,
} from '../../../models/materials.models'
import { Room } from '../../../models/catalogs.models'
import NumberTextFieldComponent from '../../common/input/Number.input'
import TextFieldComponent from '../../common/input/Text.input'
import SelectComponent from '../../common/input/Select.input'
import { createOptionsFromEnum } from '../../../utils/i18n.utils'
import CheckboxInput from '../../common/input/Checkbox.input'

type DebounceReducer = {
  timeout?: any
  locatedMaterial: LocatedMaterial
  error: string
}
type DebounceReducerAction =
  | { type: 'edit'; update: Partial<LocatedMaterial>; timeout: any; error: string }
  | { type: 'init'; locatedMaterial: LocatedMaterial }
  | { type: 'saved' }

type LocatedMaterialToolbarProps = {
  material: Material
  rooms: Room[]
  planScale: number
  canUpdate: boolean
  locatedMaterial: LocatedMaterial
  onDelete: () => void
  onReveal: () => void
  onEdit: (locatedMaterial: LocatedMaterial) => void
}
const LocatedMaterialToolbar: React.FC<LocatedMaterialToolbarProps> = ({
  rooms,
  planScale,
  material,
  canUpdate,
  locatedMaterial,
  onEdit,
  onReveal,
  onDelete,
}): JSX.Element => {
  const { t } = useTranslation()
  const showLinkToRoom = isLinkToRoomDimension(material)
  const [debounce, dispatch] = useReducer(
    (debounceData: DebounceReducer, action: DebounceReducerAction): DebounceReducer => {
      if (debounceData.timeout) {
        clearTimeout(debounceData.timeout)
      }
      switch (action.type) {
        case 'init':
          return {
            locatedMaterial: action.locatedMaterial,
            timeout: undefined,
            error: '',
          }
        case 'saved':
          return {
            locatedMaterial: debounceData.locatedMaterial,
            timeout: undefined,
            error: '',
          }
        case 'edit':
          let newVal = { ...debounceData.locatedMaterial, ...action.update }
          return {
            locatedMaterial: newVal,
            timeout: action.timeout,
            error: action.error,
          }
      }
    },
    {
      locatedMaterial,
      timeout: undefined,
      error: '',
    },
  )

  useEffect(() => {
    if (debounce.locatedMaterial._id !== locatedMaterial._id) {
      if (debounce.timeout) {
        onEdit({ ...debounce.locatedMaterial, quantity: Number(debounce.locatedMaterial.quantity) })
      }

      dispatch({
        type: 'init',
        locatedMaterial,
      })
    } else if (
      (debounce.locatedMaterial.quantity !== locatedMaterial.quantity ||
        debounce.locatedMaterial.quality !== locatedMaterial.quality) &&
      !debounce.timeout &&
      !debounce.error
    ) {
      // case where locatedMaterial has been change outside this component (undo/redo)
      dispatch({
        type: 'init',
        locatedMaterial,
      })
    }
  }, [onEdit, debounce, locatedMaterial])

  const inputHandler = (
    type: 'quantity' | 'quality' | 'linkToRoom',
    value?: string | number | boolean,
  ) => {
    let newValue: string | number | boolean
    if (type === 'quantity') {
      newValue = Number(value)
    } else if (type === 'quality') {
      newValue = value || ''
    } else {
      newValue = value ?? true
    }
    let error =
      type === 'quantity' && (Number.isNaN(newValue) || Number(newValue) <= 0)
        ? t('errors:positive')
        : ''

    let newRoomQuantity: number | undefined
    if (type === 'linkToRoom' && value !== false && showLinkToRoom) {
      const room = rooms.find((room: Room) => room._id === locatedMaterial.room)
      newRoomQuantity = getRoomDimension(planScale, material, room)
    }

    if ((type === 'quantity' || newRoomQuantity) && !error) {
      let { initialQty, currentQty } = computeResourceQuantities(
        material.quantities.map((quantity) =>
          quantity._id !== locatedMaterial._id
            ? quantity
            : {
                initial: true,
                type: QuantityType.inventory,
                quantity: newRoomQuantity ?? Number(newValue),
                _id: locatedMaterial._id,
              },
        ),
      )

      if (initialQty < 0 || currentQty < 0) {
        error = t('errors:updateLocatedErrorQuantity')
      }
    }

    dispatch({
      type: 'edit',
      update: {
        linkToRoom: type === 'quantity' ? false : debounce.locatedMaterial.linkToRoom,
        quantity: newRoomQuantity ?? debounce.locatedMaterial.quantity,
        [type]: value,
      },
      error,
      timeout: error
        ? undefined
        : setTimeout(() => {
            onEdit({
              ...debounce.locatedMaterial,

              [type]: newValue,
            })
            dispatch({ type: 'saved' })
          }, 500),
    })
  }

  return (
    <Grid container spacing={1}>
      <Grid item xs={3} lg={2}>
        <img
          src={material.mainImageFile.src || material.mainImageFile.path}
          draggable={false}
          height="70px"
          width="70px"
          style={{ objectFit: 'cover' }}
          alt=""
        />
      </Grid>
      <Grid item xs={5} lg={2}>
        <TextFieldComponent
          fullWidth
          value={material.name}
          label={t('materials:attributes.name')}
          readOnly
        />
      </Grid>
      <Grid item xs={4} lg={2}>
        <SelectComponent
          readOnly={!canUpdate}
          label={t('materials:attributes.quantities.quality')}
          placeholder={t('materials:attributes.quantities.quality')}
          onChange={inputHandler.bind(null, 'quality')}
          items={createOptionsFromEnum(MaterialQuality, 'materials:quality')}
          value={debounce.locatedMaterial.quality}
        />
      </Grid>
      <Grid item xs={5} lg={2}>
        <NumberTextFieldComponent
          fullWidth
          readOnly={!canUpdate}
          label={t('materials:attributes.quantities.quantity')}
          placeholder={t('materials:attributes.quantities.quantity')}
          onChange={inputHandler.bind(null, 'quantity')}
          showControl
          endAdornment={
            <InputAdornment position="end">
              {t(`materials:unitSymbol.${material.unit}`)}
            </InputAdornment>
          }
          error={debounce.error}
          value={debounce.locatedMaterial.quantity}
        />
      </Grid>
      <Grid item xs={5} lg={2} display="flex" alignItems="flex-end">
        {showLinkToRoom && (
          <CheckboxInput
            readOnly={!canUpdate}
            value={debounce.locatedMaterial.linkToRoom !== false}
            onChange={inputHandler.bind(null, 'linkToRoom')}
            label={t('materials:attributes.quantities.linkToRoom')}
          />
        )}
      </Grid>
      <Grid item xs={1}>
        <IconButton onClick={onReveal} size="small" sx={{ marginTop: '30px' }}>
          <PreviewIcon />
        </IconButton>
      </Grid>
      {canUpdate && (
        <Grid item xs={1}>
          <IconButton onClick={onDelete} size="small" sx={{ marginTop: '30px' }}>
            <DeleteIcon />
          </IconButton>
        </Grid>
      )}
    </Grid>
  )
}
export default LocatedMaterialToolbar
