import { useTranslation } from 'react-i18next'
import useCheckRoute from '../../hooks/useCheckRoute.hooks'
import { Mode } from '../../models/commons.models'
import { AdminHeaderTitle } from '../../components/layout/AdminHeader.layout'

import PageOrderList from '../../components/page/OrderList.page'
const OrderAdminPage = () => {
  useCheckRoute('OrderAdmin', [Mode.admin])
  const { t } = useTranslation()

  return (
    <>
      <AdminHeaderTitle title={t('global:routes.adminOrders')} />
      <PageOrderList />
    </>
  )
}
export default OrderAdminPage
