import { materialModelsStore } from './materialModels.store'
import { select } from '@ngneat/elf'
import { selectIsRequestPending } from '@ngneat/elf-requests'

export class MaterialModelsQuery {
  store = materialModelsStore

  materialModels = this.store.pipe(select((state) => state.list))
  loading = this.store.pipe(selectIsRequestPending('init'))
}

export const materialModelsQuery = new MaterialModelsQuery()
