import { catalogsStore } from './catalogs.store'
import { select } from '@ngneat/elf'
import { selectIsRequestPending } from '@ngneat/elf-requests'

export class CatalogsQuery {
  store = catalogsStore

  loading = this.store.pipe(selectIsRequestPending('init'))
  detailLoading = this.store.pipe(selectIsRequestPending('initDetail'))
  isPublic = this.store.pipe(select((state) => state.isPublic))

  catalogs = this.store.pipe(select((state) => state.list))
  catalog = this.store.pipe(select((state) => state.detail))

  getCatalog = () => {
    return this.store.getValue().detail
  }
}

export const catalogsQuery = new CatalogsQuery()
