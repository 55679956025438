import { ObjectUtils } from '../utils/commons.utils'
import { DEFAULT_META, Meta as MetaType } from '../constants/meta.constants'
import { DEFAULT_COLORS, Colors as ColorsType } from '../constants/colors.constants'
import { DEFAULT_UI, Ui as UiType } from '../constants/ui.constants'
import { DEFAULT_LANGS, Langs as LangsType, Lang as LangType } from '../constants/langs'
import { createGlobalCss } from '../constants/globalCss.constants'
import {
  DEFAULT_RESOURCES,
  Resources as ResourcesType,
  CategoryName2Key,
} from '../constants/resources.constants'
import { createTheme } from '../constants/theme'
import { createMaps } from '../constants/maps.constants'
import { createTheme as muiCreateTheme, adaptV4Theme } from '@mui/material'
import { Mode } from '../models/commons.models'
import { PlatformReference } from '../models/platforms.models'
import dayjs from '../utils/dayjs.utils'

export type Meta = MetaType
export type Colors = ColorsType
export type Ui = UiType
export type Langs = LangsType
export type Lang = LangType
export type Resources = ResourcesType

export type Subset<K> = {
  [attr in keyof K]?: K[attr] extends object
    ? Subset<K[attr]>
    : K[attr] extends object | null
    ? Subset<K[attr]> | null
    : K[attr] extends object | null | undefined
    ? Subset<K[attr]> | null | undefined
    : K[attr]
}

export type initOptions = {
  appVersion?: number
  platform?: PlatformReference
  specificCountry?: string
  mode?: Mode
  resources?: Subset<Resources>
  colors?: Subset<Colors>
  meta?: Subset<Meta>
  ui?: Subset<Ui>
  langs?: Subset<Langs>
  globalCss?: any
  theme?: any
  maps?: any
  startYearReportFilter?: number
}

class Constants {
  appVersion = 1
  specificCountry: string | undefined = undefined
  mode: Mode = Mode.front
  meta: Meta = DEFAULT_META
  resources: Resources = DEFAULT_RESOURCES
  langs: Langs = DEFAULT_LANGS
  colors: Colors = DEFAULT_COLORS
  ui: Ui = DEFAULT_UI
  globalCss: any = createGlobalCss(this.colors)
  theme: any = createTheme(this.colors, this.ui, this.globalCss)
  maps: any = createMaps(this.colors, this.resources)
  platform: PlatformReference = PlatformReference.rae
  startYearReportFilter = dayjs().year()

  init(override?: initOptions) {
    this.specificCountry = override?.specificCountry
    this.platform = override?.platform || this.platform
    this.mode = override?.mode || this.mode
    if (this.mode === Mode.app && override?.appVersion) {
      this.appVersion = override?.appVersion
    }
    this.meta = ObjectUtils.merge(DEFAULT_META, override?.meta)
    this.resources = ObjectUtils.merge(DEFAULT_RESOURCES, override?.resources)
    this.langs = ObjectUtils.merge(DEFAULT_LANGS, override?.langs)
    this.colors = ObjectUtils.merge(DEFAULT_COLORS, override?.colors)
    this.ui = ObjectUtils.merge(DEFAULT_UI, override?.ui)
    this.globalCss = ObjectUtils.merge(createGlobalCss(this.colors), override?.globalCss)
    this.theme = ObjectUtils.merge(
      createTheme(this.colors, this.ui, this.globalCss),
      override?.theme,
    )
    this.maps = ObjectUtils.merge(createMaps(this.colors, this.resources), override?.maps)
    this.startYearReportFilter = override?.startYearReportFilter || this.startYearReportFilter
  }

  getTheme() {
    return muiCreateTheme(adaptV4Theme(this.theme))
  }

  getCategoryIcon(categoryName: string): any {
    return this.resources[CategoryName2Key[categoryName]] ?? ''
  }

  getIsLocal() {
    return this.mode === Mode.app
  }
}
const constants = new Constants()
export default constants
