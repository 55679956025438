import { AxiosResponse } from 'axios'
import { axiosApi } from './base.api'
import { FileDetails } from '../models/files.models'
export namespace FilesApi {
  export const create = async (file: File): Promise<FileDetails> => {
    const newFormData = new FormData()
    newFormData.append('file', file)
    const response: AxiosResponse<FileDetails> = await axiosApi.post(`/files`, newFormData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
    return response.data
  }
}
