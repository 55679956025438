export enum SavedFiltersTypeEnum {
  ADMIN_REPORTS = 'ADMIN_REPORTS',
  OWN_CATALOGS_REPORTS = 'OWN_CATALOGS_REPORTS',
}

export interface SavedFilterValueItems {
  field: string
  values: unknown[]
}

export interface SavedFilterValue {
  name: string
  items: SavedFilterValueItems[]
}
