import i18n from 'i18next'
import detector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'
import dayjs, { changeLanguage as changeDayJsLanguage } from './dayjs.utils'
import { Lang, Langs } from '../constants'
import { StringUtils } from './commons.utils'
import { setLang } from '../api/base.api'
import { SelectOption } from '../models/props.models'
import { sessionService } from '../store/session'

declare module 'i18next' {
  interface CustomTypeOptions {
    defaultNS: 'fr'
    resources: Lang
    returnNull: false
  }
}

const formatTranslation = (value: any, format?: string, locale?: string) => {
  const formats = format?.split('|')
  let formattedValue = value

  if (format === 'quantities') {
    if (formattedValue?.length) {
      let res = ':\n'
      let i
      for (i = 0; i < Math.min(formattedValue.length, 3); i++) {
        if (formattedValue[i]?.material?.name && formattedValue[i]?.quantity) {
          res += `- ${formattedValue[i]?.material?.name} : ${formattedValue[i]?.quantity}\n`
        }
      }
      if (i < formattedValue.length) {
        res += '...'
      }
      return res
    }
    return ''
  }
  if (format === 'quantity') {
    if (formattedValue?.length && formattedValue[0].quantity) {
      return `: ${formattedValue[0].quantity}`
    }
    return ''
  }

  formats?.forEach((currentFormat) => {
    if (formattedValue instanceof Date && locale && currentFormat === 'date') {
      if (formattedValue.toString() === 'Invalid Date') {
        formattedValue = '-'
      } else {
        formattedValue = dayjs(formattedValue)
          .locale(locale)
          .format(sessionService.getCountryParam('date') as string)
      }
      return
    }

    if (formattedValue instanceof Date && locale && currentFormat === 'time') {
      if (formattedValue.toString() === 'Invalid Date') {
        formattedValue = '-'
      } else {
        formattedValue = dayjs(formattedValue).locale(locale).format('HH:mm')
      }
      return
    }

    if (formattedValue instanceof Date && locale && currentFormat === 'month') {
      if (formattedValue.toString() === 'Invalid Date') {
        formattedValue = '-'
      } else {
        formattedValue = dayjs(formattedValue).locale(locale).format('MMMM YYYY')
      }
      return
    }

    if (typeof formattedValue === 'string' && currentFormat === 'uppercase') {
      formattedValue = formattedValue.toUpperCase()
      return
    }

    if (typeof formattedValue === 'string' && currentFormat === 'capitalize') {
      formattedValue = formattedValue.slice(0, 1).toUpperCase() + formattedValue.slice(1)
      return
    }

    if (typeof formattedValue === 'string' && currentFormat === 'lowercase') {
      formattedValue = formattedValue.toLowerCase()
      return
    }
    if (typeof formattedValue === 'number' && currentFormat === 'number') {
      formattedValue = StringUtils.formatNumber(formattedValue)
      return
    }
  })

  return formattedValue
}

interface Enum {
  [id: string]: string
}
export const createOptionsFromEnum = (values: Enum, basePath: string): SelectOption[] => {
  return Object.keys(values).map((enumKey: string) => ({
    // @ts-ignore
    label: i18n.t(`${basePath}.${values[enumKey]}`),
    value: values[enumKey],
  })) as SelectOption[]
}

let initLanguage = 'fr'
export const init = (langs: Langs): void => {
  i18n
    .use(detector)
    .use(initReactI18next)
    .init({
      returnNull: false,
      resources: langs,
      fallbackLng: 'fr',
      interpolation: {
        escapeValue: false,
        format: formatTranslation,
      },
    })
    .then(() => {
      initLanguage = i18n.language
    })
}

export const changeLanguage = (lang: string) => {
  i18n.changeLanguage(lang)
  changeDayJsLanguage(lang)
  setLang(lang)
}

// use for google script (that will not update lang if page is not reload)
export const getInitLanguage = () => initLanguage
export default i18n
