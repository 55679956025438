import DetailsComponent from '../common/Details.common'
import { useState } from 'react'
import { Organization, LegalForm } from '../../models/organizations.models'
import { ItemType, DetailItem, Actions } from '../../models/props.models'
import { Location } from '../../models/commons.models'
import { useTranslation } from 'react-i18next'
import { organizationsService } from '../../store/organizations'
import useSnackbar from '../../hooks/useSnackbar.hooks'
import LoaderOverlay from '../layout/LoaderOverlay.layout'

type InformationOrganizationProps = {
  title: string
  organization: Organization
  additionals?: DetailItem[]
  onClick?: () => void
  actions?: Actions
}
const InformationOrganization: React.FC<InformationOrganizationProps> = ({
  title,
  organization,
  additionals,
  actions,
  onClick,
}) => {
  const { t } = useTranslation()
  const show = useSnackbar()
  const [loading, setLoading] = useState(false)

  const items: DetailItem[] = [
    {
      label: t('organizations:attributes.name'),
      key: 'name',
    },
    {
      label: t('organizations:attributes.legalForm'),
      key: 'legalForm',
      formatValue: (legalForm: LegalForm) =>
        legalForm ? t(`organizations:legalForms.${legalForm}`) : '',
    },
    { label: t('organizations:attributes.siret'), key: 'siret' },
    {
      label: t('organizations:attributes.location'),
      key: 'location',
      formatValue: (location: Location) => location?.fullAddress?.replace(',', '\n') || '',
    },
    {
      label: t('organizations:attributes.kbis'),
      type: organization.kbis ? ItemType.link : ItemType.typo,
      key: 'kbis',
      props: organization.kbis
        ? {
            children: organization.kbis?.path?.split(/\\|\//)?.pop() ?? '',
            onClick: async () => {
              setLoading(true)
              try {
                if (organization.kbis) {
                  await organizationsService.downloadKbis(organization.kbis)
                }
              } catch (err: any) {
                show(err)
              }
              setLoading(false)
            },
          }
        : undefined,
    },
    ...(additionals || []),
  ]
  return (
    <>
      {loading && <LoaderOverlay />}
      <DetailsComponent
        actions={actions}
        title={title}
        value={organization}
        items={items}
        onClick={onClick}
      />
    </>
  )
}
export default InformationOrganization
