import React from 'react'
import { CssBaseline, ThemeProvider } from '@mui/material'
import { SnackbarProvider, SnackbarKey } from 'notistack'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs/index.js'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { GlobalStyles } from '@mui/material'
import {
  CheckCircle as CheckCircleIcon,
  Warning as WarningIcon,
  Error as ErrorIcon,
  Info as InfoIcon,
} from '@mui/icons-material'
import { BrowserRouter } from 'react-router-dom'

import Constants from '../constants'
import Snackbar, { SnackbarClose } from './layout/Snackbar.layout'

interface AppProps {
  children: JSX.Element
}
const App: React.FC<AppProps> = ({ children }): JSX.Element => {
  return (
    <ThemeProvider theme={Constants.getTheme()}>
      <CssBaseline />
      <GlobalStyles styles={Constants.globalCss} />

      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
        <SnackbarProvider
          maxSnack={3}
          autoHideDuration={4000}
          iconVariant={{
            success: <CheckCircleIcon />,
            warning: <WarningIcon />,
            error: <ErrorIcon />,
            info: <InfoIcon />,
          }}
          Components={{
            success: Snackbar,
            warning: Snackbar,
            error: Snackbar,
            info: Snackbar,
          }}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          action={(snackbarKey: SnackbarKey) => <SnackbarClose snackbarKey={snackbarKey} />}>
          <BrowserRouter>{children}</BrowserRouter>
        </SnackbarProvider>
      </LocalizationProvider>
    </ThemeProvider>
  )
}

export default App
