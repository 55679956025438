import { useState, useCallback, useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'

type UseModal<T extends string> = [modal: string, setModal: (modal: T | '') => void]

const useModal = <T extends string>(): UseModal<T> => {
  const [searchParams, setSearchParams] = useSearchParams()
  const [modal, setModal] = useState<T | ''>((searchParams.get('modal') as T) || '')
  const exportedSetModal = useCallback(
    (modal: T | '') => {
      searchParams.set('modal', modal)
      setSearchParams(searchParams, { replace: true })
      setModal(modal)
    },
    [searchParams, setSearchParams],
  )

  // handle query not by exportedSetModal (case of android back button)
  useEffect(() => {
    if (!searchParams.get('modal')) {
      setModal('')
    }
  }, [searchParams])

  return [modal, exportedSetModal]
}

export default useModal
