import { LangEnum } from '../constants/langs'
import { CatalogLight } from './catalogs.models'
import { Pagination } from './commons.models'
import { Organization } from './organizations.models'
import { User, UserType } from './users.models'

export const hasSenderRights = (user: User | undefined, request: Request): boolean => {
  const platform = request?.receiver?.platform ?? request?.sender?.platform
  const isAdmin =
    user?.type === UserType.admin ||
    (user?.type === UserType.platformAdmin && user?.platformOwned === platform)

  return isAdmin || user?.organization._id === request?.sender?._id
}
export const hasReceiverRights = (user: User | undefined, request: Request): boolean => {
  const platform = request?.receiver?.platform ?? request?.sender?.platform
  const isAdmin =
    user?.type === UserType.admin ||
    (user?.type === UserType.platformAdmin && user?.platformOwned === platform)

  return isAdmin || user?.organization._id === request?.receiver?._id
}

export enum RequestStatus {
  pending = 'pending',
  accepted = 'accepted',
  denied = 'denied',
  canceled = 'canceled',
}

export enum RequestType {
  catalogTransfer = 'catalogTransfer',
  // materialsTransfer = 'materialsTransfer',
  newUser = 'newUser',
}

export interface Request {
  _id: string
  type: RequestType
  status: RequestStatus
  sender?: Organization
  senderUser?: string
  receiver?: Organization
  receiverUser?: string
  customReceiver?: {
    email: string
    lang: LangEnum
    firstname: string
    lastname: string
    organization: string
    organizationId?: string
  }
  catalog: CatalogLight
  user: User
}

export interface ApiRequest
  extends Omit<Request, '_id' | 'status' | 'sender' | 'receiver' | 'catalog' | 'user'> {
  _id?: string
  catalog?: string
  user?: string
  receiver?: string
}

export interface RequestsPagination extends Pagination {
  data: Request[]
}
