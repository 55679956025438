import { useCallback, useMemo } from 'react'
import { useLocation, useNavigate, createSearchParams } from 'react-router-dom'
import { Route } from '../models/commons.models'

export type RoutePath =
  | { route: Route.home }
  | { route: Route.account }
  | { route: Route.cgu }
  | { route: Route.rgpd }
  | { route: Route.favorites }
  | { route: Route.cart }
  | { route: Route.login }
  | { route: Route.signup }
  | { route: Route.users }
  | { route: Route.user; userId: string }
  | { route: Route.news; newsId: string }
  | { route: Route.requests }
  | { route: Route.receivedRequests }
  | { route: Route.sentRequests }
  | { route: Route.organizations }
  | { route: Route.organizationInformations; organizationId: string }
  | { route: Route.organizationUsers; organizationId: string }
  | { route: Route.organization; organizationId: string }
  | { route: Route.materialModels }
  | { route: Route.adminOrders }
  | { route: Route.myOrders }
  | { route: Route.materials }
  | { route: Route.materialsNeeds }
  | { route: Route.materialsResources }
  | { route: Route.workspace }
  | { route: Route.workspaceOrders }
  | { route: Route.workspaceDeconstructions }
  | { route: Route.workspaceConstructions }
  | { route: Route.workspaceStorages }
  | { route: Route.workspaceNeeds }
  | { route: Route.workspaceReporting }
  | { route: Route.workspaceCatalog; catalogId: string }
  | { route: Route.workspaceCatalogInformations; catalogId: string }
  | { route: Route.workspaceCatalogPlans; catalogId: string }
  | { route: Route.workspaceCatalogMaterials; catalogId: string }
  | { route: Route.workspaceCatalogOrders; catalogId: string }
  | { route: Route.workspaceCatalogMatching; catalogId: string }
  | { route: Route.workspaceCatalogReporting; catalogId: string }
  | { route: Route.workspaceCatalogPlan; catalogId: string; planId: string }
  | { route: Route.publicMaterials }
  | { route: Route.publicNeeds }
  | { route: Route.publicResources }
  | { route: Route.publicCatalogs }
  | { route: Route.publicCatalogsNeeds }
  | { route: Route.publicCatalogsResources }
  | { route: Route.publicCatalog; catalogId: string }
  | { route: Route.publicCatalogInformations; catalogId: string }
  | { route: Route.publicCatalogMaterials; catalogId: string }

type UseRoute = {
  goTo: (path: RoutePath, replace?: boolean, query?: Record<string, string>) => void
  getPath: (path: RoutePath) => string
  getRoutePath: (route: Route) => string
  isAtRoute: (route: Route) => boolean
  matchNeeds: boolean
  matchPublic: boolean
};
const useRoute = (): UseRoute => {
  const navigate = useNavigate()
  const location = useLocation()
  const getRoutePath = useCallback((route: Route): string => {
    switch (route) {
      case Route.home:
        return `/`
      case Route.organization:
        return `:organizationId`
      case Route.organizationInformations:
        return `informations`
      case Route.organizationUsers:
        return `users`
      case Route.user:
        return `:userId`
      case Route.materialModels:
        return `material-models`
      case Route.sentRequests:
        return `sent`
      case Route.receivedRequests:
        return `received`
      case Route.news:
        return `news/:newsId`
      case Route.adminOrders:
        return `orders`
      case Route.myOrders:
        return `my-orders`
      case Route.materialsNeeds:
        return `needs`
      case Route.materialsResources:
        return `resources`
      case Route.workspaceOrders:
        return `orders`
      case Route.workspaceDeconstructions:
        return `deconstructions`
      case Route.workspaceConstructions:
        return `constructions`
      case Route.workspaceStorages:
        return `storages`
      case Route.workspaceNeeds:
        return `needs`
      case Route.workspaceReporting:
        return `reporting`
      case Route.workspaceCatalog:
        return `:catalogId`
      case Route.workspaceCatalogInformations:
        return `informations`
      case Route.workspaceCatalogPlans:
        return `plans`
      case Route.workspaceCatalogMaterials:
        return `materials`
      case Route.workspaceCatalogOrders:
        return `orders`
      case Route.workspaceCatalogReporting:
        return `reporting`
      case Route.workspaceCatalogMatching:
        return `matching`
      case Route.workspaceCatalogPlan:
        return `:catalogId/plans/:planId`
      case Route.publicMaterials:
        return `public-materials`
      case Route.publicResources:
        return `resources`
      case Route.publicCatalogs:
        return `public-catalogs`
      case Route.publicCatalogsNeeds:
        return `needs`
      case Route.publicCatalogsResources:
        return `resources`
      case Route.publicNeeds:
        return `needs`
      case Route.publicCatalog:
        return `:catalogId`
      case Route.publicCatalogInformations:
        return `informations`
      case Route.publicCatalogMaterials:
        return `materials`
      case Route.reporting:
        return `reporting`
      default:
        return `/${route}`
    }
  }, [])
  const getPath = useCallback((routePath: RoutePath): string => {
    switch (routePath.route) {
      case Route.home:
        return `/`;
      case Route.organization:
        return `/organizations/${routePath.organizationId}`;
      case Route.organizationInformations:
        return `/organizations/${routePath.organizationId}/informations`;
      case Route.organizationUsers:
        return `/organizations/${routePath.organizationId}/users`;
      case Route.news:
        return `/news/${routePath.newsId}`;
      case Route.sentRequests:
        return `/requests/sent`;
      case Route.receivedRequests:
        return `/requests/received`;
      case Route.user:
        return `/users/${routePath.userId}`;
      case Route.materialModels:
        return `/material-models`;
      case Route.adminOrders:
        return `/orders`;
      case Route.myOrders:
        return `/my-orders`;
      case Route.materialsNeeds:
        return `/materials/needs`;
      case Route.materialsResources:
        return `/materials/resources`;
      case Route.workspaceOrders:
        return `/workspace/orders`;
      case Route.workspaceReporting:
        return `/workspace/reporting`;
      case Route.workspaceDeconstructions:
        return `/workspace/deconstructions`;
      case Route.workspaceConstructions:
        return `/workspace/constructions`;
      case Route.workspaceStorages:
        return `/workspace/storages`;
      case Route.workspaceNeeds:
        return `/workspace/needs`;
      case Route.workspaceCatalog:
      case Route.workspaceCatalogInformations:
        return `/workspace/${routePath.catalogId}/informations`;
      case Route.workspaceCatalogPlans:
        return `/workspace/${routePath.catalogId}/plans`;
      case Route.workspaceCatalogMaterials:
        return `/workspace/${routePath.catalogId}/materials`;
      case Route.workspaceCatalogOrders:
        return `/workspace/${routePath.catalogId}/orders`;
      case Route.workspaceCatalogReporting:
        return `/workspace/${routePath.catalogId}/reporting`;
      case Route.workspaceCatalogMatching:
        return `/workspace/${routePath.catalogId}/matching`;
      case Route.workspaceCatalogPlan:
        return `/workspace/${routePath.catalogId}/plans/${routePath.planId}`;
      case Route.publicMaterials:
        return `/public-materials`;
      case Route.publicNeeds:
        return `/public-materials/needs`;
      case Route.publicResources:
        return `/public-materials/resources`;
      case Route.publicCatalogs:
        return `/public-catalogs`;
      case Route.publicCatalogsNeeds:
        return `/public-catalogs/needs`;
      case Route.publicCatalogsResources:
        return `/public-catalogs/resources`;
      case Route.publicCatalogInformations:
        return `/public-catalogs/${routePath.catalogId}/informations`;
      case Route.publicCatalog:
      case Route.publicCatalogMaterials:
        return `/public-catalogs/${routePath.catalogId}/materials`;
      default:
        return `/${routePath.route}`;
    }
  }, []);
  const goTo = useCallback(
    (routePath: RoutePath, replace: boolean = false, query: Record<string, string> = {}): void => {
      navigate(
        { pathname: getPath(routePath), search: query ? `?${createSearchParams(query)}` : '' },
        { replace },
      )
    },
    [navigate, getPath],
  )
  const isAtRoute = useCallback(
    (route: Route): boolean => {
      switch (route) {
        case Route.home:
          return `/` === location.pathname
        case Route.organization:
          return !!location.pathname.match(/\/organizations\/[a-zA-Z0-9.]*\/.*/)
        case Route.organizationInformations:
          return !!location.pathname.match(/\/organizations\/[a-zA-Z0-9.]*\/informations/)
        case Route.organizationUsers:
          return !!location.pathname.match(/\/organizations\/[a-zA-Z0-9.]*\/users/)
        case Route.news:
          return !!location.pathname.match(/\/news\/[a-zA-Z0-9.]*\/.*/)
        case Route.sentRequests:
          return `/requests/sent` === location.pathname
        case Route.receivedRequests:
          return `/requests/received` === location.pathname
        case Route.user:
          return !!location.pathname.match(/\/users\/[a-zA-Z0-9.]*\/.*/)
        case Route.materialModels:
          return `/material-models` === location.pathname
        case Route.adminOrders:
          return `/orders` === location.pathname
        case Route.myOrders:
          return `/my-orders` === location.pathname
        case Route.materialsNeeds:
          return !!location.pathname.match(/\/materials\/needs/)
        case Route.materialsResources:
          return !!location.pathname.match(/\/materials\/resources/)
        case Route.workspace:
          return `/workspace` === location.pathname
        case Route.workspaceOrders:
          return !!location.pathname.match(/\/workspace\/orders/)
        case Route.workspaceReporting:
          return !!location.pathname.match(/\/workspace\/reporting/)
        case Route.workspaceDeconstructions:
          return !!location.pathname.match(/\/workspace\/deconstructions/)
        case Route.workspaceConstructions:
          return !!location.pathname.match(/\/workspace\/constructions/)
        case Route.workspaceStorages:
          return !!location.pathname.match(/\/workspace\/storages/)
        case Route.workspaceNeeds:
          return !!location.pathname.match(/\/workspace\/needs/)
        case Route.workspaceCatalog:
          return !!location.pathname.match(/\/workspace\/[a-zA-Z0-9.]*\/.*/)
        case Route.workspaceCatalogInformations:
          return !!location.pathname.match(/\/workspace\/[a-zA-Z0-9.]*\/informations/)
        case Route.workspaceCatalogPlans:
          return !!location.pathname.match(/\/workspace\/[a-zA-Z0-9.]*\/plans/)
        case Route.workspaceCatalogMaterials:
          return !!location.pathname.match(/\/workspace\/[a-zA-Z0-9.]*\/materials/)
        case Route.workspaceCatalogOrders:
          return !!location.pathname.match(/\/workspace\/[a-zA-Z0-9.]*\/orders/)
        case Route.workspaceCatalogReporting:
          return !!location.pathname.match(/\/workspace\/[a-zA-Z0-9.]*\/reporting/)
        case Route.workspaceCatalogMatching:
          return !!location.pathname.match(/\/workspace\/[a-zA-Z0-9.]*\/matching/)
        case Route.workspaceCatalogPlan:
          return !!location.pathname.match(/\/workspace\/[a-zA-Z0-9.]*\/plans\/[a-zA-Z0-9.]*/)
        case Route.publicCatalogs:
          return `/public-catalogs` === location.pathname
        case Route.publicCatalogsNeeds:
          return `/public-catalogs/needs` === location.pathname
        case Route.publicCatalogsResources:
          return `/public-catalogs/resources` === location.pathname
        case Route.publicMaterials:
          return `/public-materials` === location.pathname
        case Route.publicNeeds:
          return `/public-materials/needs` === location.pathname
        case Route.publicResources:
          return `/public-materials/resources` === location.pathname
        case Route.publicCatalog:
          return !!location.pathname.match(/\/public-catalogs\/[a-zA-Z0-9.]*\/.*/)
        case Route.publicCatalogInformations:
          return !!location.pathname.match(/\/public-catalogs\/[a-zA-Z0-9.]*\/informations/)
        case Route.publicCatalogMaterials:
          return !!location.pathname.match(/\/public-catalogs\/[a-zA-Z0-9.]*\/materials/)
        default:
          return `/${route}` === location.pathname
      }
    },
    [location],
  )

  const matchNeeds = useMemo(() => !!location.pathname.match(/\/needs/), [location])
  const matchPublic = useMemo(() => {
    return !location.pathname.match(/\/workspace/) && !location.pathname.match(/\/account/)
  }, [location])

  return {
    getPath,
    getRoutePath,
    goTo,
    isAtRoute,
    matchNeeds,
    matchPublic,
  }
}

export default useRoute
