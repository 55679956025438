import Content from '../common/Contents.common'
import { useTranslation } from 'react-i18next'
import constants from '../../constants'
import { PlatformReference } from '../../models/platforms.models'
import TmCgu from './tm/cgu.tm'

const CGUContent: React.FC = (): JSX.Element => {
  const { t } = useTranslation()

  if (constants.platform === PlatformReference.tm) {
    return <TmCgu />
  }

  return (
    <Content
      title={t('sessions:components.cguContent.title')}
      intro={t('sessions:components.cguContent.intro')}
      // @ts-ignore
      paragraphs={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17].map((paragraph) => ({
        // @ts-ignore
        content: t(`sessions:components.cguContent.paragraph${paragraph}.content`),
        //@ts-ignore
        title: t(`sessions:components.cguContent.paragraph${paragraph}.title`),
      }))}
    />
  )
}
export default CGUContent
