import React from 'react'
import { Grid, GridSize, styled } from '@mui/material'
import { useEffect } from 'react'
import { useInView } from 'react-intersection-observer'

const StyleGrid = styled(Grid)({
  flexGrow: 1,
})

interface InfiniteScrollContainerProps {
  listItems: JSX.Element[]
  onNextPage: () => void
  itemsPerPage: number
  xs?: boolean | GridSize
  sm?: boolean | GridSize
  md?: boolean | GridSize
  lg?: boolean | GridSize
}

const InfiniteScroll = (props: InfiniteScrollContainerProps) => {
  const { listItems, onNextPage, itemsPerPage, xs, sm, md, lg } = props
  const [lastRowRef, lastRowInView] = useInView()

  useEffect(() => {
    lastRowInView && onNextPage()
  }, [lastRowInView, onNextPage])

  const Elements = listItems.map((listItem, i) => {
    let ref: (node?: Element | null | undefined) => void = () => {}
    if (i === listItems.length - 1 - Math.floor(itemsPerPage * 0.25)) ref = lastRowRef
    return (
      <StyleGrid key={listItem.key} item xs={xs} sm={sm} md={md} lg={lg} ref={ref}>
        {listItem}
      </StyleGrid>
    )
  })

  return <>{Elements}</>
}
export default InfiniteScroll
