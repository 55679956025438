import { SavedFiltersApi } from '../../api/savedFilters.api'
import { SavedFilterValue, SavedFiltersTypeEnum } from '../../models/savedFilters.models'
import { NetworkUtils } from '../../utils/networks.utils'

export class SavedFiltersService {
  getSavedFilters = async (savedFilterType: SavedFiltersTypeEnum): Promise<SavedFilterValue[]> => {
    await NetworkUtils.checkConnected()
    return await SavedFiltersApi.getSavedFilters(savedFilterType)
  };

  saveFilter = async (type: SavedFiltersTypeEnum, newSavedFilter: SavedFilterValue): Promise<SavedFilterValue[]> => {
    await NetworkUtils.checkConnected()
    return await SavedFiltersApi.saveFilter(type, newSavedFilter)
  }

  deleteFilter = async (type: SavedFiltersTypeEnum, name: string): Promise<SavedFilterValue[]> => {
    await NetworkUtils.checkConnected()
    return await SavedFiltersApi.deleteFilter(type, name)
  }
}

export const savedFiltersService = new SavedFiltersService()
