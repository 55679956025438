import { cartsStore } from './carts.store'
import { updateRequestStatus } from '@ngneat/elf-requests'
import Constants from '../../constants'
import { logIfDev } from '../../utils/commons.utils'
import { ApiProduct } from '../../models/orders.models'

const cartEvent = 'cart-update'

export class CartsService {
  store = cartsStore
  channel = new BroadcastChannel('raedificare-cart')

  constructor() {
    window.addEventListener(cartEvent, this.init.bind(this))
    this.channel.onmessage = async (event: any) => {
      try {
        if (event.isTrusted && event.data === cartEvent) {
          await this.init()
        }
      } catch (err: any) {
        logIfDev(err)
      }
    }
  }

  get cartLocalStorageKey() {
    return `RAEDIFICARE_CART_${Constants.platform}`
  }

  updateCart(cart: ApiProduct[]) {
    localStorage.setItem(this.cartLocalStorageKey, JSON.stringify(cart))
    this.channel.postMessage(cartEvent)
    //  do not understand why it is not reactive if I set the state directly here ... (change elf to redux ??)
    window.dispatchEvent(new Event(cartEvent))
  }

  async init() {
    this.store.update(updateRequestStatus('init', 'pending'))
    try {
      const cart = JSON.parse(localStorage.getItem(this.cartLocalStorageKey) || '[]')
      //  do not understand why it is not reactive (on reloading cart page) if i don't do the settimeout ... (change elf to redux ??)
      setTimeout(() => {
        this.store.update(
          (state) => ({
            ...state,
            cart,
          }),
          updateRequestStatus('init', 'success'),
        )
      }, 100)
    } catch (err: any) {
      this.store.update(updateRequestStatus('init', 'error', err))
      throw err
    }
  }

  addToCart = (materialId: string, quantity: number): ApiProduct[] => {
    const cart = this.store.getValue().cart
    const item = cart.find((item: ApiProduct) => item.material === materialId)
    if (item) {
      item.quantity += quantity
    } else {
      cart.push({ material: materialId, quantity })
    }

    this.updateCart(cart)
    return cart
  }

  updateQuantity = (materialId: string, quantity: number): ApiProduct[] => {
    const cart = this.store.getValue().cart
    if (quantity === 0) {
      const item = cart.find((item: ApiProduct) => item.material === materialId)
      if (!item) return cart
      cart.splice(cart.indexOf(item), 1)
    } else {
      const item = cart.find((item: ApiProduct) => item.material === materialId)
      if (item) {
        item.quantity = quantity
      } else {
        return cart
      }
    }

    this.updateCart(cart)
    return cart
  }

  emptyCart = (): ApiProduct[] => {
    this.updateCart([])
    return []
  }
}

export const cartsService = new CartsService()
