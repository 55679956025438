import { AxiosResponse } from 'axios';
import { axiosApi } from './base.api';
import { SavedFilterValue, SavedFiltersTypeEnum } from '../models/savedFilters.models';

export namespace SavedFiltersApi {
  export const getSavedFilters = async (type: SavedFiltersTypeEnum): Promise<SavedFilterValue[]> => {
    const response: AxiosResponse<SavedFilterValue[]> = await axiosApi.get(`/filters`, {
      params: {
        type,
      },
    })
    return response.data
  }

  export const saveFilter = async (type: SavedFiltersTypeEnum, newSavedFilter: SavedFilterValue): Promise<SavedFilterValue[]> => {
    const response: AxiosResponse<SavedFilterValue[]> = await axiosApi.put(
      `/filters`,
      newSavedFilter,
      {
        params: {
          type,
        },
      }
    )

    return response.data
  }

  export const deleteFilter = async (type: SavedFiltersTypeEnum, name: string): Promise<SavedFilterValue[]> => {
    const response: AxiosResponse<SavedFilterValue[]> = await axiosApi.delete(`/filters`, {
      params: {
        type,
        name,
      },
    })

    return response.data
  }
}
