import {useObservable} from "@ngneat/react-rxjs";
import {categoriesQuery} from "../../store/categories";
import {Category} from "../../models/categories.models";
import {MutableRefObject, useCallback, useEffect, useMemo, useRef, useState} from "react";
import {
  Box,
  FormHelperText,
  InputLabel,
  Backdrop,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Paper,
  IconButton,
  useMediaQuery,
  ListItemIcon,
  Divider,
  styled,
  InputAdornment,
  Checkbox,
  Stack,
} from "@mui/material";
import {useTheme} from "@mui/material/styles/index.js";
import StyledInput from "../common/input/Styled.input";
import {ArrowForwardIos as ArrowForwardIosIcon, ArrowBackIos as ArrowBackIosIcon, Close as CloseIcon} from "@mui/icons-material";
import {createPortal} from "react-dom";
import {useTranslation} from "react-i18next";

const Input = styled(StyledInput)({
  input: {
    textOverflow: "ellipsis",
    direction: "rtl",
    textAlign: "left",
  },
});

interface InputMultiSelectCategoryProps {
  label?: string;
  placeholder?: string;
  disabled?: boolean;
  readOnly?: boolean;
  error?: string | boolean;
  required?: boolean;
  accurateCategory?: boolean;
  selectedCategories?: {primaryCategory?: Category; secondaryCategory?: Category; tertiaryCategory?: Category}[];
  onChange: (value: {primaryCategory?: Category; secondaryCategory?: Category; tertiaryCategory?: Category} | "clear") => void;
}

const paddingTop = 5;
const CategoryMenu = ({
  label,
  onClose,
  inputRef,
  accurateCategory,
  selectedCategories,
  onChange,
}: InputMultiSelectCategoryProps & {onClose: () => void; inputRef: MutableRefObject<any>}) => {
  const theme = useTheme();
  const mediaDownSm = useMediaQuery(theme.breakpoints.down("sm"));
  const [forceSM, setForceSm] = useState<boolean>(false);
  const downSm = useMemo(() => {
    return mediaDownSm || forceSM;
  }, [mediaDownSm, forceSM]);
  const {t} = useTranslation();
  const menuRef = useRef<any>(null);

  const isTouch = "ontouchstart" in document.documentElement;
  const [opened, setOpened] = useState<{primary?: Category; secondary?: Category}>({
    primary: undefined,
    secondary: undefined,
  });
  const [menuPosition, setMenuPosition] = useState<{
    top?: string;
    left?: string;
    bottom?: string;
    right?: string;
  }>({
    top: (inputRef.current?.getBoundingClientRect?.()?.bottom ?? 0) + paddingTop + "px" ?? 0,
    left: (inputRef.current?.getBoundingClientRect?.()?.left ?? 0) + "px" ?? 0,
  });

  // useEffect(() => {
  //   setOpened({
  //     primary: secondaryCategory ? primaryCategory : undefined,
  //     secondary: tertiaryCategory ? secondaryCategory : undefined,
  //   });
  // }, [selectedCategories]);

  useEffect(() => {
    function handleResize() {
      setMenuPosition({
        top: (inputRef.current?.getBoundingClientRect?.()?.bottom ?? 0) + paddingTop + "px",
        left: (inputRef.current?.getBoundingClientRect?.()?.left ?? 0) + "px",
      });
      setTimeout(() => {
        const box = menuRef.current?.getBoundingClientRect?.();
        if (box) {
          if (forceSM && box.height + 2 * paddingTop < window.innerHeight) {
            setForceSm(false);
          } else if (box.height + 2 * paddingTop > window.innerHeight) {
            setForceSm(true);
          } else if (box.bottom > window.innerHeight) {
            setMenuPosition({
              bottom: "5px",
              left: (inputRef.current?.getBoundingClientRect?.()?.left ?? 0) + "px",
            });
          }
        }
      });
    }

    if (!downSm) {
      handleResize();
      window.addEventListener("resize", handleResize);
      window.addEventListener("scroll", handleResize);
    } else {
      setMenuPosition({
        top: "0",
        left: "0",
        bottom: "0",
        right: "0",
      });
    }
    return () => {
      if (!downSm) {
        window.removeEventListener("resize", handleResize);
        window.removeEventListener("scroll", handleResize);
      }
    };
  }, [downSm, inputRef, forceSM]);

  const [primaryCategories] = useObservable(categoriesQuery.primaryCategories);
  const [secondaryCategories] = useObservable(categoriesQuery.secondaryCategories);
  const [tertiaryCategories] = useObservable(categoriesQuery.tertiaryCategories);
  const secondaryCategoryOptions = useMemo(
    () => secondaryCategories.filter((category) => opened.primary?._id === category.parent),
    [secondaryCategories, opened]
  );
  const tertiaryCategoryOptions = useMemo(
    () => tertiaryCategories.filter((category) => opened.secondary?._id === category.parent),
    [tertiaryCategories, opened]
  );

  const select = useCallback(
    (primaryCategory?: Category, secondaryCategory?: Category, tertiaryCategory?: Category) => {
      onChange?.({primaryCategory, secondaryCategory, tertiaryCategory});
    },
    [onChange]
  );
  return createPortal(
    <Backdrop sx={{zIndex: 100000}} open invisible onClick={onClose}>
      <Paper
        ref={menuRef}
        onClick={(e: any) => e.stopPropagation()}
        sx={{
          position: "absolute",
          ...menuPosition,
          display: "flex",
        }}>
        {(!opened.primary || !downSm) && (
          <List sx={{width: downSm ? "100%" : undefined}}>
            {downSm && (
              <>
                <ListItem disablePadding>
                  <ListItemButton onClick={onClose}>
                    <ListItemIcon>
                      <CloseIcon />
                    </ListItemIcon>
                    {label}
                  </ListItemButton>
                </ListItem>
                <Divider />
              </>
            )}
            {primaryCategories?.map((primary: Category) => (
              <ListItem
                disablePadding
                key={primary._id}
                secondaryAction={
                  isTouch && !accurateCategory ? (
                    <IconButton
                      edge="end"
                      onClick={(e: any) => {
                        e.stopPropagation();
                        setOpened(() => ({primary: primary, secondary: undefined}));
                      }}>
                      <ArrowForwardIosIcon />
                    </IconButton>
                  ) : undefined
                }>
                <ListItemButton
                  selected={selectedCategories?.some((x) => x.primaryCategory?._id === primary._id)}
                  onMouseOver={() => {
                    setOpened(() => ({primary: primary, secondary: undefined}));
                  }}>
                  <ListItemText primary={t(`categories:name.${primary.name}` as any)} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        )}
        {opened.primary && (!opened.secondary || !downSm) && (
          <Paper elevation={downSm ? 0 : 1} sx={{width: downSm ? "100%" : undefined}}>
            <List>
              {downSm && (
                <>
                  <ListItem disablePadding>
                    <ListItemButton onClick={() => setOpened(() => ({primary: undefined, secondary: undefined}))}>
                      <ListItemIcon>
                        <ArrowBackIosIcon />
                      </ListItemIcon>
                      {t(`categories:name.${opened.primary.name}` as any)}
                    </ListItemButton>
                  </ListItem>
                  <Divider />
                </>
              )}
              {secondaryCategoryOptions?.map((secondary) => (
                <ListItem
                  disablePadding
                  key={secondary._id}
                  secondaryAction={
                    isTouch && !accurateCategory ? (
                      <IconButton
                        edge="end"
                        onClick={(e: any) => {
                          e.stopPropagation();
                          setOpened((opened: any) => ({
                            ...opened,
                            secondary: secondary,
                          }));
                        }}>
                        <ArrowForwardIosIcon />
                      </IconButton>
                    ) : undefined
                  }>
                  <ListItemButton
                    selected={selectedCategories?.some((x) => x.secondaryCategory?._id === secondary._id)}
                    onMouseOver={() => {
                      setOpened((opened: any) => ({...opened, secondary: secondary}));
                    }}>
                    <ListItemText primary={t(`categories:name.${secondary.name}` as any)} />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </Paper>
        )}
        {opened.secondary && (
          <Paper elevation={downSm ? 0 : 1} sx={{width: downSm ? "100%" : undefined}}>
            <List>
              {downSm && (
                <>
                  <ListItem disablePadding>
                    <ListItemButton onClick={() => setOpened((opened) => ({...opened, secondary: undefined}))}>
                      <ListItemIcon>
                        <ArrowBackIosIcon />
                      </ListItemIcon>
                      {t(`categories:name.${opened.secondary.name}` as any)}
                    </ListItemButton>
                  </ListItem>{" "}
                  <Divider />
                </>
              )}
              {tertiaryCategoryOptions?.map((tertiary) => (
                <ListItem disablePadding key={tertiary._id}>
                  <ListItemButton
                    selected={selectedCategories?.some((x) => x?.tertiaryCategory?._id === tertiary._id)}
                    onClick={() => select(opened.primary, opened.secondary, tertiary)}>
                    <Stack direction="row" spacing={2}>
                      <Checkbox color="primary" checked={selectedCategories?.some((x) => x?.tertiaryCategory?._id === tertiary._id)} />
                      <ListItemText primary={t(`categories:name.${tertiary.name}` as any)} />
                    </Stack>
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </Paper>
        )}
      </Paper>
    </Backdrop>,
    document.getElementById("category-modal") as HTMLElement
  );
};

const InputMultiSelectCategory: React.FC<InputMultiSelectCategoryProps> = (props) => {
  const {selectedCategories, error, label, placeholder, required, disabled, readOnly, onChange} = props;

  const inputRef = useRef<any>(null);
  const [open, setOpen] = useState<boolean>(false);
  const {t} = useTranslation();

  const value = useMemo(() => {
    return selectedCategories?.map((x) => t(`categories:name.${x.tertiaryCategory?.name}` as any)).join(", ") ?? "";
  }, [selectedCategories, t]);

  return (
    <Box display="flex" flexDirection="column">
      {!!label && <InputLabel error={!!error}>{label + (required ? "*" : "")}</InputLabel>}
      <Input
        color="primary"
        variant="outlined"
        ref={inputRef}
        fullWidth
        required={required}
        disabled={disabled}
        InputProps={{
          readOnly: true,
          endAdornment:
            !required && !!selectedCategories?.length ? (
              <InputAdornment position="end">
                <IconButton
                  onClick={(e) => {
                    onChange?.("clear");
                    e.stopPropagation();
                  }}>
                  <CloseIcon />
                </IconButton>
              </InputAdornment>
            ) : undefined,
        }}
        value={value}
        onClick={() => {
          if (!readOnly && !disabled) {
            setOpen(true);
          }
        }}
        placeholder={placeholder}
      />
      {open && <CategoryMenu {...props} onClose={() => setOpen(false)} inputRef={inputRef} />}
      {typeof error === "string" && <FormHelperText error>{error}</FormHelperText>}
    </Box>
  );
};
export default InputMultiSelectCategory;
