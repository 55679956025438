import PageOrderList from '../../components/page/OrderList.page'
import useCheckRoute from '../../hooks/useCheckRoute.hooks'
import { Mode } from '../../models/commons.models'

const MyOrdersPage = () => {
  useCheckRoute('MyOrder', [Mode.front, Mode.storeFront])

  return <PageOrderList isClient />
}
export default MyOrdersPage
