export const createTabs = (colors: any) => ({
  MuiTabs: {
    indicator: {
      backgroundColor: colors.primary,
      height: '4px',
    },
    scroller: {
      borderBottom: `1px solid ${colors.tabsBorder}`,
      position: 'relative',
      overflow: 'auto ! important',
      msOverflowStyle: 'none',
      scrollbarWidth: 'none',
      '&::-webkit-scrollbar': {
        display: 'none',
      },
    },
  },
  MuiTab: {
    root: {
      minWidth: '120px',
      color: colors.blockLabel,
      fontWeight: 500,
      fontSize: '0.875rem',
      textTransform: 'none',
      '&.Mui-selected': {
        color: colors.primary,
      },
      '&[aria-label=secondary].Mui-selected': {
        color: colors.secondary,
      },
      '&[aria-label=light-mode-secondary].Mui-selected': {
        color: colors.secondary,
      },
      '&[aria-label=light-mode]': {
        color: colors.white,
        '&.Mui-selected': {
          color: colors.primary,
        },
      },
      '&[aria-label=light-mode-secondary]': {
        color: colors.white,
        '&.Mui-selected': {
          color: colors.primary,
        },
      },
    },
  },
})
