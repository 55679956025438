import { useCallback, useRef } from 'react'

import Form, { FormProps } from './Form.common'
import Modal from '../layout/Modal.layout'

export interface ModalFormProps extends FormProps {
  title: string
  description?: string
  onClose: () => void
  keepOpen?: boolean
  keepOpenOnDelete?: boolean
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl'
}
const ModalForm: React.FC<ModalFormProps> = (props) => {
  const {
    description,
    title,
    onClose,
    onDelete,
    keepOpen,
    keepOpenOnDelete,
    onSuccess,
    maxWidth,
    ...formProps
  } = props
  const ref = useRef<{ loading: boolean }>({ loading: false })
  const handleDelete = useCallback(async () => {
    await onDelete?.()
    if (!keepOpenOnDelete) {
      onClose()
    }
  }, [onDelete, onClose, keepOpenOnDelete])
  const handleSuccess = useCallback(
    async (formValue: any) => {
      await onSuccess?.(formValue)
      if (!keepOpen) {
        onClose()
      }
    },
    [onSuccess, keepOpen, onClose],
  )
  return (
    <Modal
      loadingRef={ref}
      permanent
      title={title}
      description={description}
      onClose={onClose}
      hideAction
      maxWidth={maxWidth}
      noTitleMargin={!!formProps.tabs}>
      <Form
        ref={ref}
        fullwidth={maxWidth === 'xl' || maxWidth === 'lg'}
        scrollable
        noPadding
        {...formProps}
        onSuccess={handleSuccess}
        onDelete={onDelete ? handleDelete : undefined}
      />
    </Modal>
  )
}
export default ModalForm
