import { AxiosResponse } from 'axios'
import { axiosApi, defaultItemPerPage } from './base.api'
import {
  Order,
  OrderStatus,
  OrdersPagination,
  ApiOrder,
  ApiUpdateOrder,
} from '../models/orders.models'
import { PaginationParams } from '../models/commons.models'
import { PlatformReference } from '../models/platforms.models'

export namespace OrdersApi {
  export interface GetListParams extends PaginationParams {
    platform?: PlatformReference
    search?: string
    owned?: boolean
    catalogOwner?: string
    client?: string
    catalog?: string
    status?: OrderStatus[]
    periodStart?: Date
    periodEnd?: Date
  }
  export const getList = async (params?: GetListParams): Promise<OrdersPagination> => {
    const response: AxiosResponse<OrdersPagination> = await axiosApi.get(`/orders`, {
      params: {
        disablePaginate: false,
        itemsPerPage: defaultItemPerPage,
        page: 0,
        ...params,
        status: params?.status?.length ? params.status : undefined,
        periodStart: params?.periodStart?.toISOString(),
        periodEnd: params?.periodEnd?.toISOString(),
      },
    })
    return response.data
  }
  export const getCsv = async (params?: GetListParams): Promise<Blob> => {
    const response: AxiosResponse<Blob> = await axiosApi.get(`/orders/csv`, {
      params: {
        disablePaginate: false,
        itemsPerPage: defaultItemPerPage,
        page: 0,
        ...params,
        status: params?.status?.length ? params.status : undefined,
        periodStart: params?.periodStart?.toISOString(),
        periodEnd: params?.periodEnd?.toISOString(),
      },
      responseType: 'blob',
    })
    return response.data
  }
  export const getPdf = async (orderId: string): Promise<Blob> => {
    const response: AxiosResponse<Blob> = await axiosApi.get(`/orders/${orderId}/pdf`, {
      responseType: 'blob',
    })
    return response.data
  }
  export const getById = async (orderId: string): Promise<Order> => {
    const response: AxiosResponse<Order> = await axiosApi.get(`/orders/${orderId}`)
    return response.data
  }
  export const update = async (orderId: string, updatedOrder: ApiUpdateOrder): Promise<Order> => {
    const response: AxiosResponse<Order> = await axiosApi.patch(`/orders/${orderId}`, updatedOrder)
    return response.data
  }

  export const create = async (order: ApiOrder): Promise<Order[]> => {
    //TODO PAYMENT {method: "POST", url: "/orders/payment"}
    const response: AxiosResponse<Order[]> = await axiosApi.post('/orders', order)
    return response.data
  }
}
