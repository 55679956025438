import {
  Box,
  InputLabel,
  Typography,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  FormHelperText,
} from '@mui/material'
import Stack from '../Stack.common'
import { GroupProps } from '../../../models/props.models'
import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material'
const GroupForm: React.FC<GroupProps & { children?: JSX.Element | JSX.Element[] }> = ({
  accordion,
  title,
  children,
  description,
  error,
  endTitle,
}) => {
  if (accordion) {
    return (
      <Accordion sx={{ margin: '0 5px !important' }} TransitionProps={{ unmountOnExit: true }}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <InputLabel error={!!error} sx={{ marginBottom: 0 }}>
            <Typography variant="h3">{title}</Typography>
            {typeof error === 'string' && <FormHelperText error>{error}</FormHelperText>}
          </InputLabel>
          {endTitle && (
            <Box margin="0 5px 0 auto">
              <InputLabel sx={{ marginBottom: 0 }}>
                <Typography variant="h3">{endTitle}</Typography>
              </InputLabel>
            </Box>
          )}
        </AccordionSummary>
        <AccordionDetails>
          {description && (
            <Box mb="20px">
              <Typography>{description}</Typography>
            </Box>
          )}
          <Grid container spacing={3}>
            {children}
          </Grid>
        </AccordionDetails>
      </Accordion>
    )
  }
  return (
    <Stack spacing={2} width="100%">
      {title && (
        <Box mb={description ? '0 !important' : '20px'}>
          <InputLabel error={!!error}>
            <Typography variant="h3">{title}</Typography>
          </InputLabel>
          {typeof error === 'string' && <FormHelperText error>{error}</FormHelperText>}
        </Box>
      )}

      {description && (
        <Box mb="20px">
          <Typography>{description}</Typography>
        </Box>
      )}
      <Box width="100%">
        <Grid container spacing={2}>
          {children}
        </Grid>
      </Box>
    </Stack>
  )
}
export default GroupForm
