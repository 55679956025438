import { Catalog, CatalogStatus } from '../../../models/catalogs.models'
import { MaterialType } from '../../../models/materials.models'
import { useOutletContext } from 'react-router-dom'
import PageMaterialList from '../../../components/page/MaterialList.page'
import Stack from '../../../components/common/Stack.common'
import { Box, Button, Typography, styled, useMediaQuery } from '@mui/material'
import { useTheme } from '@mui/material/styles/index.js'
import { useTranslation } from 'react-i18next'
import useModal from '../../../hooks/useModal.hooks'
import useSnackbar from '../../../hooks/useSnackbar.hooks'
import Modal from '../../../components/layout/Modal.layout'
import { catalogsService } from '../../../store/catalogs'

const DescriptionLabel = styled(Typography)({
  fontSize: '0.875rem',
  fontWeight: 400,
  lineHeight: 1.25,
  marginBottom: '30px',
  textAlign: 'center',
  whiteSpace: 'pre-line',
  maxWidth: '500px',
})

const CatalogMatchingTab = () => {
  const { catalog, checkSync } = useOutletContext<{
    catalog: Catalog
    checkSync: (callback: () => Promise<any>) => Promise<void>
  }>()

  const theme = useTheme()
  const { t } = useTranslation()
  const onlyXs = useMediaQuery(theme.breakpoints.only('xs'))
  const [modal, setModal] = useModal<'sendToValidation'>()
  const show = useSnackbar()

  if (catalog && catalog.status !== CatalogStatus.accepted) {
    return (
      <Stack
        justifyContent="center"
        alignItems="center"
        width="100%"
        height="100%"
        marginTop="60px">
        <DescriptionLabel>{t('catalogs:pages.matching.mustBeAccepted')}</DescriptionLabel>

        {catalog.status === CatalogStatus.draft && (
          <Box width={onlyXs ? '90%' : '370px'}>
            <Button
              variant="contained"
              aria-label="success"
              fullWidth
              onClick={setModal.bind(null, 'sendToValidation')}>
              {t('catalogs:actions.sendToValidation.label')}
            </Button>
          </Box>
        )}
        {modal === 'sendToValidation' && (
          <Modal
            description={t(`catalogs:actions.${modal}.description`)}
            title={t(`catalogs:actions.${modal}.label`)}
            keepOpen
            onSuccess={() => {
              show(t(`catalogs:actions.${modal}.success`))
              setModal('')
            }}
            onClose={() => setModal('')}
            onConfirm={async () => {
              try {
                return await checkSync(
                  catalogsService.updateStateCatalog.bind(null, catalog._id, {
                    status: CatalogStatus.pending,
                  }),
                )
              } catch (err: any) {
                show(err)
                throw err
              }
            }}
          />
        )}
      </Stack>
    )
  }

  return <PageMaterialList isPublic type={MaterialType.resource} matching={catalog._id} />
}
export default CatalogMatchingTab
